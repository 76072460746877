import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnDestroy, PLATFORM_ID, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GoogleMap, MapAdvancedMarker, MapInfoWindow } from '@angular/google-maps';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, skip } from 'rxjs/operators';

import { Constants } from '../../classes/constants';
import { GeocodeService } from '../../services/geocode.service';
import { GeolocationService } from '@shared/services/geolocation.service';
import { InPersonRegistrationsSharingService } from '../../services/in-person-registrations-sharing.service';
import { Location } from '../../interfaces/location';
import { WINDOW } from '@shared/services/window.service';

// Icons
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  standalone: false
})
export class MapComponent implements AfterViewInit, OnDestroy {

  @Input() isCamp = false;
  @Input() isSingleLocation = false;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  @ViewChildren(MapAdvancedMarker) markers: QueryList<MapAdvancedMarker>;

  center: google.maps.LatLngLiteral =  { lat: this.constants.MAP_DEFAULT_LAT, lng: this.constants.MAP_DEFAULT_LNG };
  infoLocation: Location = null;
  isMapInitialized = false;
  locations: Location[] = [];
  locationsSelected: Location[] = [];
  shouldCenterBounds = false;
  zoom = this.constants.ZOOM_LEVEL_INITIAL;
  zoomLevelMax = this.constants.ZOOM_LEVEL_MAX;
  zoomLevelMaxCluster = this.constants.ZOOM_LEVEL_MAX_CLUSTER;

  // Icons
  faCircleCheck = faCircleCheck;
  faCircleXmark = faCircleXmark;

  // Google map options
  options: google.maps.MapOptions = {
    cameraControl: false,
    fullscreenControl: true,
    mapTypeControl: false,
    maxZoom: this.zoomLevelMax,
    streetViewControl: false,
    zoomControl: true
  }

  // Subscriptions
  private getAddressSubscription: Subscription = null;
  private getLocationsSubscription: Subscription = null;
  private getLocationsSelectedSubscription: Subscription = null;

  constructor(
    public constants: Constants,
    @Inject(DOCUMENT) private document: Document,
    private geocodeService: GeocodeService,
    private geolocationService: GeolocationService,
    private inPersonRegistrationsSharingService: InPersonRegistrationsSharingService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(WINDOW) private window: Window | null
  ) { }

  ngAfterViewInit() {

    // Watch the locations
    this.getLocationsSubscription = this.inPersonRegistrationsSharingService.getLocations()
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe((locations: Location[]) => {
        this.locations = locations;
        this.updateMarkers();
      });

    // Watch the address (skip first as we'll address that after map init)
    this.getAddressSubscription = this.inPersonRegistrationsSharingService.getAddress()
      .pipe(skip(1))
      .subscribe(address => this.addressToCoordinates(address));

    // Watch the selected location (skip first as we'll address that after map init)
    this.getLocationsSelectedSubscription = this.inPersonRegistrationsSharingService.getLocationsSelected()
      .pipe(skip(1))
      .subscribe((locations: Location[]) => {
        this.locationsSelected = locations;
        this.updateMap2Location();
      });
  }

  ngOnDestroy() {

    if (this.getAddressSubscription) {
      this.getAddressSubscription.unsubscribe();
    }

    if (this.getLocationsSubscription) {
      this.getLocationsSubscription.unsubscribe();
    }

    if (this.getLocationsSelectedSubscription) {
      this.getLocationsSelectedSubscription.unsubscribe();
    }
  }

  // Custom cluster renderer
  clusterRenderer = {
    render: ({ count, position }) => {

      const pin = this.document ? this.document.createElement('div') : new HTMLDivElement();
      pin.className = 'cluster-pin';
      pin.innerText = String(count);

      const clusterOptions = {
        content: pin,
        map: this.map.googleMap,
        position,
        title: String(count) + ' locations',
        zIndex: 1
      };

      return new google.maps.marker.AdvancedMarkerElement(clusterOptions);

      // Custom render logic for clusters
      /*const clusterIcon = new google.maps.Marker({
        position,
        label: {
          text: String(count),
          color: 'white',
          fontSize: '14px',
        },
        icon: {
          url: 'https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/cluster/red.png',
          scaledSize: new google.maps.Size(54, 54),
        },
      });
      return clusterIcon;*/
    }
  };

  // The map has been initialized, get the center location
  mapIsReady() {
    setTimeout(() => {
      this.initMap2Location();
    }, 500);
  }

  openDirections(location: any) {

    if (!this.window) {
      return;
    }

    const destQuery = encodeURIComponent(`
      ${location.Name},
      ${location.AddressLn1},
      ${location.City},
      ${location.State}`
    );

    this.window.open(`https://www.google.com/maps/dir/?api=1&destination=${destQuery}`, '_blank');
  }

  scroll2Registrations() {
    if (isPlatformBrowser(this.platformId)) {
      this.document.getElementById('registrations').scrollIntoView({ behavior: 'smooth' });
    }
  }

  updateMarkers() {

    if (!this.isMapInitialized) {
      return;
    }

    const bounds = this.map.getBounds();
    let isVisibilityChanged = false;

    // Mark the locations that are visible on the map (so other components knows whats visible)
    if (this.locations && bounds && this.isMapInitialized) {
      this.locations.forEach((location: Location) => {
        const isVisibleOrig = location.IsVisible;
        location.IsVisible = bounds.contains({lat: location.Latitude, lng: location.Longitude} as any);
        isVisibilityChanged = isVisibilityChanged || (isVisibleOrig !== location.IsVisible);
      });

      // If visibility has not changed, leave
      if (!isVisibilityChanged) {
        return;
      }

      // Update the visible locations and recenter
      const visibleLocations: Location[] = this.locations.filter((location: Location) => location.IsVisible);
      this.inPersonRegistrationsSharingService.updateLocationsVisible(visibleLocations);
      this.centerBounds4Locations(visibleLocations);

      // If there is a selected location but no info window, show the info window (let markers initialize in the view using timeout)
      /*if (this.isSingleLocation && this.locationsSelected.length && !this.infoLocation) {
        setTimeout(() => {
          this.updateMap2Location();
        }, 0);
      }*/
    }
  }

  updateSelectedLocation(location: any) {

    const index = this.locationsSelected.findIndex(loc => loc.LocalityId === location.LocalityId);
    if (index > -1) {

      // Unselect
      location.IsSelected = false;

      // Hide any info window
      this.switchInfoWindow4Location(null);

      // Remove found location from selected
      this.locationsSelected.splice(index, 1);
    } else {

      // Select
      location.IsSelected = true;

      // Add location to selected
      if (this.isSingleLocation) {

        // Unselect the previously selected location
        if (this.locationsSelected.length) {
          this.locationsSelected[0].IsSelected = false;
        }

        this.locationsSelected = [ location ];
      } else {
        this.locationsSelected.push(location);
      }
    }

    this.inPersonRegistrationsSharingService.updateLocationsSelected(this.locationsSelected);
  }

  // Use the geocode service to convert the address into a location
  private addressToCoordinates(address) {
    if (address) {
      this.geocodeService.geocodeAddress(address)
        .subscribe((geoLoc: any) => {
          this.shouldCenterBounds = true;

          // Zoom in to 1 level past the max clustering zoom, or keep existing zoom if we're more zoomed in
          this.zoom = Math.max(this.map.getZoom(), this.constants.ZOOM_LEVEL_MAX_CLUSTER + 1);
          this.center = { lat: geoLoc.lat, lng: geoLoc.lng };
        }
      );
    }
  }

  private centerBounds4Locations(locations: any[]) {
    if (locations.length && this.shouldCenterBounds) {
      this.shouldCenterBounds = false;
      const bound = new google.maps.LatLngBounds();
      locations.forEach(visibleLocation => {
        bound.extend({lat: visibleLocation.Latitude, lng: visibleLocation.Longitude});
      });
      const boundCenter = bound.getCenter();
      this.center = { lat: boundCenter.lat(), lng: boundCenter.lng() };
    }
  }

  private fitBounds2Locations(locations: Location[]) {
    if (locations.length) {
      const bounds = new google.maps.LatLngBounds();
      locations.forEach(location => {
        bounds.extend({lat: location.Latitude, lng: location.Longitude});
      });
      this.map.fitBounds(bounds);
    }
  }

  // If possible, init map to address, final fallback will be the user's geolocation
  private initMap2Address() {
    this.inPersonRegistrationsSharingService.getAddress()
      .pipe(first())
      .subscribe(address => address ? this.addressToCoordinates(address) : this.initMap2Geolocation());
  }

  // Init map to the user's geolocation if it fails, default to center of USA
  private initMap2Geolocation() {
    this.geolocationService.getObservable()
      .subscribe(
        (value) => {

          // If latitude and longitude are not set, use defaults
          if (!value.latitude && !value.longitude) {
            value.latitude = this.constants.MAP_DEFAULT_LAT;
            value.longitude = this.constants.MAP_DEFAULT_LNG;
          }
          // Center map on users geolocation
          this.zoom = this.constants.ZOOM_LEVEL_INITIAL;
          this.center = { lat: value.latitude, lng: value.longitude };
        },
        (error) => {

          // Center map on center of USA
          this.zoom = this.constants.ZOOM_LEVEL_INITIAL;
          this.center = { lat: this.constants.MAP_DEFAULT_LAT, lng: this.constants.MAP_DEFAULT_LNG };
        }
    );
  }

  // If possible, init map to location, next check will be address
  private initMap2Location() {

    this.inPersonRegistrationsSharingService.getLocationsSelected()
      .pipe(first())
      .subscribe((locations: Location[]) => {
        if (this.isSingleLocation && locations && locations.length) {
          this.locationsSelected = locations;
          this.updateMap2Location();
        } else if (!this.isSingleLocation && locations && locations.length) {
          this.fitBounds2Locations(locations);
        } else {
          this.initMap2Address();
        }
        this.locationsSelected = locations;
        this.isMapInitialized = true;
      });
  }

  // Show / hide info window for a location
  private switchInfoWindow4Location(location: Location) {

    // If location is null, hide the info window
    if (!location) {

      // Unset the info window location and hide the info window
      this.infoLocation = null;
      if (this.infoWindow) {
        this.infoWindow.close();
      }
    } else if (this.markers && this.markers.length) {

      // Find the marker based on title of the location
      const info = this.markers.toArray().find(marker => marker.getAnchor().title === location.Name);
      if (info) {

        // Set the info window location and show it
        this.infoLocation = location;
        this.infoWindow.open(info);
      }
    }
  }

  // The selected locations have changed, update the map
  private updateMap2Location() {
    if (this.isSingleLocation && this.locationsSelected.length && this.map) {

      // Set zoom to 1 level past the max clustering zoom, or keep existing zoom if we're more zoomed in
      this.zoom = Math.max(this.map.getZoom(), this.constants.ZOOM_LEVEL_MAX_CLUSTER + 1);

      // Pan to a school geolocation (little bit southern to make free place for info window)
      const panShift = 64 / (1 << this.zoom);
      this.map.panTo({ lat: this.locationsSelected[0].Latitude + panShift, lng: this.locationsSelected[0].Longitude });

      this.switchInfoWindow4Location(this.locationsSelected[0]);
    }
  }
}
