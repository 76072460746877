<google-map (boundsChanged)="updateMarkers()" [center]="center" mapId="cwk-map-id" (mapInitialized)="mapIsReady()" [options]="options" [zoom]="zoom" height="100%" width="100%">
  <map-marker-clusterer [renderer]="clusterRenderer">
    <map-advanced-marker [content]="location.IsSelected ? location.PinImageLinkSelected4Map : location.PinImageLink4Map" [position]="{ lat: location.Latitude, lng: location.Longitude }" *ngFor="let location of locations" [title]="location.Name" (mapClick)="location.IsComingSoon || updateSelectedLocation(location)">
    </map-advanced-marker>
    <map-info-window>

        <!-- Location name -->
        <div class="program-title">
          {{ infoLocation?.Name }}
        </div>

        <div class="text-center mb-2" *ngIf="isCamp">
          <span class="camp-day-flag me-2" ngbPopover="{{ infoLocation?.IsHalfDay ? 'Offers' : 'Doesn\'t offer' }} half-day camps" triggers="mouseenter:mouseleave">
            <span [ngClass]="{'offered': infoLocation?.IsHalfDay, 'not-offered': !infoLocation?.IsHalfDay}"><fa-icon [icon]="infoLocation?.IsHalfDay ? faCircleCheck : faCircleXmark"></fa-icon></span>
            HALF-DAY
          </span>
          <span class="camp-day-flag me-2" ngbPopover="{{ infoLocation?.IsFullDay ? 'Offers' : 'Doesn\'t offer' }} full-day camps" triggers="mouseenter:mouseleave">
            <span [ngClass]="{'offered': infoLocation?.IsFullDay, 'not-offered': !infoLocation?.IsFullDay}"><fa-icon [icon]="infoLocation?.IsFullDay ? faCircleCheck : faCircleXmark"></fa-icon></span>
            FULL-DAY
          </span>
        </div>

        <!-- Address -->
        <div class="text-center mb-2">
          <div>{{ infoLocation?.AddressLn1 }}</div>
          <div>{{ infoLocation?.City }}<span [hidden]="!infoLocation?.State">,</span> {{ infoLocation?.State }} {{ infoLocation?.ZIP }}</div>
        </div>

        <!-- Coming soon -->
        <div *ngIf="infoLocation?.IsComingSoon" class="coming-soon">
          {{ isCamp ? 'Camps' : 'Classes' }} coming soon!
        </div>

        <!-- Directions | Registrations -->
        <div *ngIf="!infoLocation?.IsComingSoon" class="d-flex justify-content-center">
          <div class="cwk-link cwk-blue-link cwk-pointer" (click)="openDirections(infoLocation)">Get directions</div>
          <span class="mx-2">|</span>
          <div class="cwk-link cwk-blue-link cwk-pointer" (click)="scroll2Registrations()">Registrations</div>
        </div>
    </map-info-window>
  </map-marker-clusterer>
</google-map>
