<!-- Level selector -->
<home-coders-ladder-level-selector (selectedClassChange)="onClassChange($event)" [isPrivateClasses]="isPrivateClasses"></home-coders-ladder-level-selector>

<div *ngIf="isReading && !errorReading" class="loading">
  <fa-icon class="me-2" [icon]="faSpinner" animation="spin"></fa-icon>Reading classes, please wait...
</div>
<div #top *ngIf="!isReading && selectedClass">

  <!-- Filters & calendar header -->
  <div #header class="mb-3 header" [ngClass]="{'sticky-cl': isHeaderSticky}">

    <div class="step-label cwk-orange-bg cwk-white px-3 py-2"><b>Step 3:</b> Select a day and time <sub class="d-none d-lg-inline-block">(all times in {{ timezoneAbbr }})</sub></div>

    <!-- Filters -->
    <div class="pt-2 d-flex justify-content-between align-items-end flex-wrap">

      <!-- Classes starting within 4 weeks -->
      <div class="d-flex align-items-center">
        <img class="img-fluid me-2" width="25px" alt="Lit light bulb" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/in-person-camps/lit-light.png" loading="lazy" style="aspect-ratio: auto 339 / 360"/><b>Classes accept new students every 4 weeks</b>
        <!--div id="classes-two-weeks-selected" class="cwk-pointer d-flex align-items-center" (click)="setFilterIsStartingWithinFourWeeks()">
          <fa-icon class="me-2" [icon]="filters.isStartingWithinFourWeeks ? faCheckSquare : faSquareO" size="lg"></fa-icon>
          <span class="filter-label">Only show classes starting within 4 weeks</span>
        </div-->
      </div>

      <!-- Instructor filter (private classes only) -->
      <!--div *ngIf="isPrivateClasses" class="mt-3 mt-sm-0">
        <b class="mb-1">Select&nbsp;Instructor</b>
        <select id="instructor-selected" class="form-control form-select form-control-sm" name="instructor" [(ngModel)]="filters.staffId" (ngModelChange)="leaderFilterChanged()">
          <option [ngValue]="null">No preference</option>
          <option *ngFor="let leader of leaders" [ngValue]="leader.StaffId">{{ leader.FullName }}</option>
        </select>
      </div-->

      <!-- Experienced classes (group classes only) -->
      <div *ngIf="!isPrivateClasses" class="mt-2 mt-sm-0">
        <div class="mb-1 cwk-pointer" [ngbPopover]="experiencedMessagePop" [autoClose]="'outside'">
          <div class="d-flex align-items-center">
            <div class="me-2 d-flex justify-content-center" style="width: 25px;">
              <fa-icon [icon]="faFireAlt" class="cwk-red" size="lg"></fa-icon>
            </div>
            Indicates experienced classes
            <fa-icon [icon]="faInfoCircle" class="ms-2"></fa-icon>
          </div>
        </div>
        <div id="advanced-only-selected" class="cwk-pointer d-flex align-items-center" (click)="setFilterIsNotEperiencedOnly()">
          <div class="me-2 d-flex justify-content-center" style="width: 25px;">
            <fa-icon [icon]="filters.isNotExperiencedOnly ? faCheckSquare : faSquareO" size="lg"></fa-icon>
          </div>
          <span class="filter-label">Hide experienced classes</span>
        </div>
      </div>
    </div>

    <!-- Weekdays -->
    <div class="fix-row">
      <div class="col d-none d-lg-block desktop-header">
        <div class="d-flex justify-content-between">
          <div *ngFor="let weekday of weekdays" class="week-header">
            {{ weekday }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngFor="let _class of classes2Show" class="mb-5" [@slideInOut]>

    <!-- CENTER (desktop) -->
    <div class="d-none d-lg-block">

      <div *ngIf="errorReadingSpotsLeft" class="alert alert-danger" role="alert">
        <p><strong>An error has occurred:</strong> {{ errorReadingSpotsLeft }}</p>
        Please refresh the page or try again later. If you continue to have issues please <a [routerLink]="['/contact']" class="cwk-blue-link cwk-link">contact us</a>
      </div>

      <!-- Horizontal divider (1x week) -->
      <div class="content-filled-hr fixed-height {{ isPrivateClasses ? 'mb-3' : 'mb-2' }}"><b>{{ _class.ClassName }}</b>&nbsp;classes meeting&nbsp;<b>once a week</b></div>

      <!-- No suitable time message -->
      <ng-container *ngTemplateOutlet="noSuitableTime"></ng-container>

      <div class="d-flex flex-row justify-content-between sessions-container">
        <div *ngFor="let sessions of _class.SessionsByDay" class="d-flex flex-column w-100 mx-1">
          <div *ngFor="let session of sessions; first as isFirst" class="d-flex flex-column item-session m-2" [ngbPopover]="session.Discounts.length ? popDiscounts : null" container="body" triggers="mouseenter:mouseleave">

            <!-- Stack effect -->
            <div class="stack-container">
              <div *ngIf="session.NumberStacked" class="relative-container">
                <img alt="Stacked classes" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/registrations/session-stack-icon{{ session.NumberOccupied ? '-popular' : '' }}.png" class="img-fluid" loading="lazy" width="150" height="20" />
                <div *ngIf="session.NumberOccupied" class="stacked-text">
                  <span class="inner">POPULAR</span>
                </div>
              </div>
            </div>

            <!-- Tile content -->
            <div id="{{ 'session-' + session.LessonId }}" class="d-flex flex-column align-items-center justify-content-between session-content" (click)="goSignup(session, _class)" [ngClass]="{'popular' : session.NumberOccupied, 'full' : session.Occupied || session.HasEnded}">

              <!-- Times -->
              <div class="time item">
                {{ session.StartUTC | date:'h:mm' }} - {{ session.EndUTC | date:'h:mm aa' }}
              </div>

              <!-- Price -->
              <div class="price item">
                <span *ngIf="!session.Discounts.length">
                  {{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }} / {{ session.Price4ClassSessions }} wks
                </span>
                <span *ngIf="session.Discounts.length">
                  <span class="non-discounted">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</span>
                  &#32;{{ (session.FinalPrice | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}
                </span>
              </div>

              <!-- Spots left -->
              <div class="item">
                <ng-container *ngTemplateOutlet="spotsLeftTemplate; context: { session: session }"></ng-container>
              </div>

              <!-- Waitlist or Register text -->
              <div class="register item">
                <div class="d-flex justify-content-center">
                  <fa-icon *ngIf="session.IsExperienced" [icon]="faFireAlt" class="cwk-red me-2" [ngbPopover]="experiencedMessagePop" triggers="mouseenter:mouseleave"></fa-icon>
                  {{ session.Occupied ? 'Waitlist' : 'Starts ' + (session.StartUTC | date:'L/d') }}
                </div>
              </div>
            </div>

            <!-- Popover for discounts -->
            <ng-template #popDiscounts>
              <div *ngFor="let discount of session.Discounts">
                {{ discount.Description }}{{ discount.ExpiresOn ? ' expires on ' + (discount.ExpiresOn | date:'shortDate') + ' at ' + (discount.ExpiresOn | date:'shortTime') + ' (' + timezoneAbbr + ')' : '' }}
              </div>
            </ng-template>
          </div>

          <!-- No session box. Show when there are no sessions for this week/day, we are in UPCOMING status and not looking at a past week -->
          <div *ngIf="!sessions.length" class="m-2">
            <div class="d-flex flex-column align-items-center justify-content-between more-coming-soon">
              <div class="top-bar">&nbsp;</div>
              <div class="item">NOT</div>
              <div class="item">CURRENTLY</div>
              <div class="item">OPEN</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Sessions taking place more times a week -->
      <div *ngIf="_class.ShowMoreDaysSessions">

        <!-- Horizontal divider (2x week) -->
        <div class="content-filled-hr mt-4 mb-2"><b>{{ _class.ClassName }}</b>&nbsp;classes meeting&nbsp;<b>twice a week</b></div>

        <!-- Accelerated sessions -->
        <div class="d-flex flex-row justify-content-between sessions-container">
          <div *ngFor="let sessions of _class.SessionsByMoreDays; index as weekday" class="d-flex flex-column w-100 mx-1">
            <div *ngFor="let session of sessions; first as isFirst" class="d-flex flex-column item-session m-2" (mouseenter)="highlightSessionDays(session, true)" (mouseleave)="highlightSessionDays(session, false)" [ngClass]="{'more-weekdays': session.isHover}" [ngbPopover]="session.Discounts.length ? popDiscounts : null" container="body" triggers="mouseenter:mouseleave">

              <!-- Stack effect -->
              <div class="stack-container">
                <!--div *ngIf="session.NumberStacked" class="relative-container">
                  <img alt="Stacked classes" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/registrations/session-stack-icon{{ session.NumberOccupied ? '-popular' : '' }}.png" class="img-fluid" loading="lazy"/>
                  <div *ngIf="session.NumberOccupied" class="stacked-text">
                    <span class="inner">POPULAR</span>
                  </div>
                </div-->
              </div>

              <!-- Tile content -->
              <div id="{{ 'session-' + session.LessonId }}" tm-destination="/checkout" class="d-flex flex-column align-items-center justify-content-between session-content" (click)="goSignup(session, _class)" [ngClass]="{'popular' : session.NumberOccupied, 'full' : session.Occupied || session.HasEnded}">

                <!-- Times -->
                <div class="time item">
                  {{ getSessionStart(session, weekday) | date:'h:mm' }}-{{ getSessionEnd(session, weekday) | date:'h:mm aa' }}
                </div>

                <!-- Price -->
                <div class="price item">
                  <span *ngIf="!session.Discounts.length">
                    {{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }} / {{ session.Price4ClassSessions }} classes
                  </span>
                  <span *ngIf="session.Discounts.length">
                    <span class="non-discounted">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</span>
                    &#32;{{ (session.FinalPrice | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}
                  </span>
                </div>

                <!-- Spots left -->
                <div class="item">
                  <ng-container *ngTemplateOutlet="spotsLeftTemplate; context: { session: session }"></ng-container>
                </div>

                <!-- Waitlist or Register text -->
                <div class="register item">
                  <div class="d-flex justify-content-center">
                    <fa-icon *ngIf="session.IsExperienced" [icon]="faFireAlt" class="cwk-red me-2" [ngbPopover]="experiencedMessagePop" triggers="mouseenter:mouseleave"></fa-icon>
                    {{ session.Occupied ? 'Waitlist' : 'Starts ' + (session.StartUTC | date:'L/d') }}
                  </div>
                </div>
              </div>

              <!-- Popover for discounts -->
              <ng-template #popDiscounts>
                <div *ngFor="let discount of session.Discounts">
                  {{ discount.Description }}{{ discount.ExpiresOn ? ' expires on ' + (discount.ExpiresOn | date:'shortDate') + ' at ' + (discount.ExpiresOn | date:'shortTime') + ' (' + timezoneAbbr + ')' : '' }}
                </div>
              </ng-template>
            </div>

            <!-- No session box. Show when there are no sessions for this week/day, we are in UPCOMING status and not looking at a past week -->
            <div *ngIf="!sessions.length" class="m-2">
              <div class="d-flex flex-column align-items-center justify-content-between more-coming-soon">
                <div class="top-bar">&nbsp;</div>
                <div class="item">NOT</div>
                <div class="item">CURRENTLY</div>
                <div class="item">OPEN</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CENTER sessions (mobile) -->
    <div *ngIf="_class.isExpanded" class="d-lg-none" [@slideInOut]>

      <!-- Horizontal divider (1x week) -->
      <div class="content-filled-hr {{ isPrivateClasses ? 'my-3' : 'mt-3 mb-2' }}">{{ _class.ClassName }}&nbsp;<b>once a week</b></div>

      <!-- No suitable time message -->
      <ng-container *ngTemplateOutlet="noSuitableTime"></ng-container>

      <div *ngFor="let sessions of _class.SessionsByDay; index as i" class="sessions-container">
        <div *ngFor="let session of sessions; first as isFirst">
          <div *ngIf="isFirst" class="mobile-weekday-header d-flex justify-content-between align-items-center">
            <span>{{ weekdays[i] }} Classes:</span>
            <small>All times in {{ timezoneAbbr }}</small>
          </div>

          <div class="cwk-pointer d-flex flex-row justify-content-between align-items-center border-bottom item-session p-2 flex-wrap" (click)="goSignup(session, _class)">

            <!-- Price and spots -->
            <div class="text-center">
              <div class="price">{{ session.Price4ClassSessions }} weeks</div>
              <div class="price">
                <div *ngIf="!session.Discounts.length">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</div>
                <div *ngIf="session.Discounts.length">
                  <div class="non-discounted">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</div>
                  &#32;{{ (session.FinalPrice | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}
                </div>
              </div>
            </div>

            <!-- Date and time -->
            <div class="text-center" style="width: 100px;">
              <div>{{ session.StartUTC | date:'h:mm' }} - {{ session.EndUTC | date:'h:mm aa' }}</div>
              <div *ngIf="session.NumberOccupied" class="number-occupied-mobile">POPULAR TIME</div>
            </div>

            <!-- Waitlist/register & spots left -->
            <div class="d-flex align-items-center">
              <div class="text-center" style="width: 95px;">
                <div class="weeks">
                  <div class="d-flex">
                    <fa-icon *ngIf="session.IsExperienced" [icon]="faFireAlt" class="cwk-red me-2"></fa-icon>
                    {{ session.Occupied ? 'Waitlist' : 'Starts ' + (session.StartUTC | date:'L/d') }}
                  </div>
                </div>
                <div class="d-flex">
                  <ng-container *ngTemplateOutlet="spotsLeftTemplate; context: { session: session }"></ng-container>
                </div>
              </div>

              <!-- Arrow -->
              <fa-icon class="ms-2" [icon]="faChevronRight" size="lg"></fa-icon>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="_class.ShowMoreDaysSessions">

        <!-- Horizontal divider (2x week) -->
        <div class="content-filled-hr my-3">{{ _class.ClassName }}&nbsp;<b>twice a week</b></div>

        <!-- Accelerated sessions -->
        <div *ngFor="let sessions of _class.SessionsByMoreDays; index as i" class="sessions-container">
          <div *ngFor="let session of sessions; first as isFirst">
            <div *ngIf="isFirst" class="mobile-weekday-header d-flex justify-content-between align-items-center">
              <span>{{ weekdays[i] }} Classes:</span>
              <small>All times in {{ timezoneAbbr }}</small>
            </div>

            <div class="cwk-pointer d-flex flex-row justify-content-between align-items-center border-bottom item-session p-2 flex-wrap" (click)="goSignup(session, _class)">

              <!-- Price and spots -->
              <div class="text-center">
                <div class="price">{{ session.Price4ClassSessions }} lessons</div>
                <div class="price">
                  <div *ngIf="!session.Discounts.length">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</div>
                  <div *ngIf="session.Discounts.length">
                    <div class="non-discounted">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</div>
                    &#32;{{ (session.FinalPrice | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}
                  </div>
                </div>
              </div>

              <!-- Date and time -->
              <div class="text-center" style="width: 100px;">
                <div>{{ getSessionStart(session, i) | date:'h:mm' }} - {{ getSessionEnd(session, i) | date:'h:mm aa' }}</div>
                <div *ngIf="session.NumberOccupied" class="number-occupied-mobile">POPULAR TIME</div>
              </div>

              <!-- Waitlist/register & spots left -->
              <div class="d-flex align-items-center">
                <div class="text-center" style="width: 95px;">
                  <div class="weeks">
                    <div class="d-flex">
                      <fa-icon *ngIf="session.IsExperienced" [icon]="faFireAlt" class="cwk-red me-2"></fa-icon>
                      {{ session.Occupied ? 'Waitlist' : 'Starts ' + (session.StartUTC | date:'L/d') }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <ng-container *ngTemplateOutlet="spotsLeftTemplate; context: { session: session }"></ng-container>
                  </div>
                </div>

                <!-- Arrow -->
                <fa-icon class="ms-2" [icon]="faChevronRight" size="lg"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- No classes to show -->
  <div *ngIf="!classes2Show.length" class="mt-4 alert alert-warning text-center" role="alert">
    <fa-icon class="d-block mx-auto mb-3" [icon]="faFrown" size="2x"></fa-icon>
    <h3>Could not find any <strong>{{ selectedClass.ClassName }}</strong> registrations.</h3>
  </div>
</div>

<!-- Experienced message popover template -->
<ng-template #experiencedMessagePop>
  <div [innerHTML]="experiencedMessage"></div>
</ng-template>

<ng-template #noSuitableTime>
  <div *ngIf="isPrivateClasses" class="d-flex flex-column align-items-center my-3 p-3 cwk-extralightgrey2-bg">
    <a class="cwk-link cwk-blue-link" [routerLink]="['/contact']" [queryParams]="{ des:'Dear Coding with Kids,<br><br>I would like to request a private class for my child:<br><br><p><b>Name of student:</b></p><p><b>Requested class:</b></p><p><b>Days and times:</b></p><br><br>', s: 'Request for a private class', ft: 'Request private class' }" queryParamsHandling="merge">
      <div class="orange-btn">Request your own schedule</div>
    </a>
    <div class="mt-2">You can directly register for any of the available classes below. However, we do not publish all available possibilities. Feel free to use the button above to request a specific schedule for your child!</div>
  </div>
  <!--h4 *ngIf="isPrivateClasses" class="text-center p-3 mx-3 mb-3 my-0 border-bottom">
    <div class="d-flex flex-wrap align-items-center justify-content-center">
      <img class="img-fluid me-2" width="25px" alt="Lit light bulb" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/in-person-camps/lit-light.png" loading="lazy"/>
      <b>Classes full or not finding a suitable time?</b>
      <img class="d-none d-md-block img-fluid ms-2" width="25px" alt="Lit light bulb" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/in-person-camps/lit-light.png" loading="lazy"/>
    </div>
    We will work with you to setup time that works for your schedule. Reach out to us <a class="cwk-link cwk-blue-link" [routerLink]="['/contact']" [queryParams]="{ s: 'Private Classes - Schedule' }" queryParamsHandling="preserve"><b>HERE</b></a>.
  </h4-->
</ng-template>

<!-- Spots left template -->
<ng-template #spotsLeftTemplate let-session="session">
  <ng-container *ngIf="isReadingSpotsLeft && !errorReadingSpotsLeft">
    <fa-icon [icon]="faSpinner" animation="spin"></fa-icon>
  </ng-container>
  <div *ngIf="!isReadingSpotsLeft" [ngbPopover]="(isPrivateClasses && session.Instructors?.length) ? popInstructor : null" container="body" triggers="mouseenter:mouseleave">
    <div *ngIf="session.SpotsLeft <= 0" class="spots-left">FULL</div>
    <div *ngIf="(session.SpotsLeft <= 3 && session.SpotsLeft > 0) || (isPrivateClasses && session.SpotsLeft > 0)" class="spots-left">
      <span *ngIf="!isPrivateClasses">{{ session.SpotsLeft }} spot{{ session.SpotsLeft === 1 ? '' : 's' }} left!</span>
      <span *ngIf="isPrivateClasses">{{ session.SpotsLeft }} instructor{{ session.SpotsLeft === 1 ? '' : 's' }}</span>
    </div>
    <div *ngIf="errorReadingSpotsLeft" class="cwk-red">ERROR</div>

    <ng-template #popInstructor>
      <div *ngFor="let instructor of session.Instructors">
        {{ instructor.FullName }}
      </div>
    </ng-template>
  </div>
</ng-template>

<!-- Error -->
<cwk-error-alert *ngIf="errorReading" [error]="errorReading"></cwk-error-alert>
