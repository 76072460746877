
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable()
export class StudentDataService {

  private urlStudent = environment.urlWebServices + '/api/students';

  constructor(private http: HttpClient) { }

  // Save the student
  // The payload contains customer's id and the student's info
  save(data: any): Observable<any> {

    // Fix before sending to MySQL
    const payload = this.fixToMySQL(data);

    // Prepare student id
    const studentId = payload.Student.StudentId || null;

    return this.http.put(`${this.urlStudent}/${studentId}`, payload)
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  searchStudents(searchString: string): Observable<any[]> {

    // Pass data in params
    const params = new HttpParams()
      .set('searchString', searchString);

    // Call web service
    return this.http.get<any[]>(`${this.urlStudent}/search`, { params: params })
      .pipe(catchError(error => observableThrowError(error.message || error)));
  }

  private fixToMySQL(record) {

    // Clone the record (dive into subobjects)
    const result = { ...record };
    result.Student = { ...record.Student };

    // Fix birth date
    result.Student.BirthDate = result.Student.BirthDate &&
      new Date(result.Student.BirthDate.getTime() - (result.Student.BirthDate.getTimezoneOffset() * 60000))
      .toISOString().substring(0, 10);

    // Fix student's is-active flag
    result.Student.IsActive = +result.Student.IsActive;

    return result;
  }
}
