import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild  } from '@angular/core';
import { NgForm } from '@angular/forms';

import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { AuthHttpService } from '../../modules/auth/services/auth-http.service';
import { AuthService } from '../../modules/auth/services/auth.service';
import { Countries } from '../../classes/countries';
import { Credentials } from '../../modules/auth/interfaces/credentials';
import { GeolocationService } from '../../services/geolocation.service';
import { GoogleSignInService } from '../../modules/auth/services/google-sign-in.service';
import { SignInResponse } from '../../modules/auth/interfaces/sign-in-response';
import { TelemetryService } from '../../services/telemetry.service';
import { TrackingPixelService } from '../../services/tracking-pixel.service';
import { USStates } from '../../classes/usstates';
import { WINDOW } from '../../services/window.service';

import { environment } from 'environments/environment';

// Icons
import { faQuestionCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cwk-create-account-form',
  templateUrl: './create-account-form.component.html',
  styleUrls: ['./create-account-form.component.scss'],
  standalone: false
})
export class CreateAccountFormComponent implements AfterViewInit, OnDestroy, OnInit {

  @Input() url: string;
  @Output() accountCreatedEvent = new EventEmitter<boolean>();
  @ViewChild('googleSignInButton') private googleSignInButton!: ElementRef;
  @ViewChild('signupForm') public form: NgForm;

  // Convert associated array of countries to array for easier processing in typeahead
  accountCreated = false; // Did we successfully create an account?
  confirmPassword = '';
  countries = Object.keys(Countries.countries).map(k => {
    return {
      Code: k,
      Name: Countries.countries[k].Name
    };
  });
  environment = environment;
  geolocation = null;
  isInvalidSubmitted = false;
  selectedCountry = null;
  signupInfo: Credentials = ({} as Credentials);
  states = USStates.states;

  // Progress flags
  errorResendingVerification = '';
  errorSaving = '';
  isResendingVerification = false;
  isSaving = false;
  isVerificationResent = false;

  // Icons
  faQuestionCircle = faQuestionCircle;
  faSpinner = faSpinner;

  readonly cwkDomainPattern = this.environment.project === 'cr' ? '(?!.*@coderace\\.).*' : '(?!.*@codingwithkids\\.).*';

  // Subscriptions
  private authProviderActionSubscription: Subscription; // External auth provider actions - MERGE-ACCOUNTS or SIGN-UP
  private geolocationSubscription: Subscription;

  constructor(
    private authHttpService: AuthHttpService,
    private authService: AuthService,
    private geolocationService: GeolocationService,
    private googleSignInService: GoogleSignInService,
    private telemetryService: TelemetryService,
    private trackingPixelService: TrackingPixelService,
    @Inject(WINDOW) private window: Window | null
  ) { }

  ngOnDestroy() {

    // Unsubscribe
    if (this.geolocationSubscription) {
      this.geolocationSubscription.unsubscribe();
    }
  }

  ngOnInit() {

    // Fire the Google and FB tracking
    this.trackingPixelService.registerActivated();

    // Get current geolocation info
    this.geolocationSubscription = this.geolocationService.getObservable()
      .subscribe(
        (value) => {

          // Save the geolocation
          this.geolocation = value;

          const country = this.countries.find(c => c.Code === this.geolocation.country_code);
          if (country) {

            // The user's country is in our set of countries
            this.signupInfo.Country = country.Code;
            this.selectedCountry = country;

            // For US countries, set the state
            if (this.signupInfo.Country === 'US') {
               this.signupInfo.State = value.region_code;
            }
          }
        }
      );

    // Set the auth provider to CwK by default
    this.signupInfo.AuthProvider = 'CWK';

    // We don't ask for the last name by default
    this.signupInfo.LastName = '';

    // When creating account from form, do not activate the user until their email address is verified
    this.signupInfo.IsActive = false;

    // Set the URL to continue with from email verification page
    this.signupInfo.URL = this.url || (this.window && this.window.location && this.window.location.href);

    // Observe any auth provider actions
    this.authProviderActionSubscription = this.authService.getAuthProviderActionObservable()
      .subscribe(
        (value) => this.onAuthProviderActionChange(value)
      );
  }

  ngAfterViewInit() {

    // Render the Google Sign-in button
    this.renderGoogleSignInButton();

    // Add telemetry
    this.setTelemetry();
  }

  cancel() {

    // Emit output that account creation has been canceled
    this.accountCreatedEvent.emit(false);
  }

  checkCountry(event) {
    if (typeof event !== 'object') {
      const matchedCountry = this.countries.find(c => c.Name.toLowerCase() === event.toLowerCase());
      if (matchedCountry) {
        this.setCountry({ item: matchedCountry });
      } else {
        this.signupInfo.Country = null;
        this.signupInfo.State = null;
      }
    }
  }

  confirmPasswordValid(): Boolean {
    return this.signupInfo.Password && this.confirmPassword &&
      this.signupInfo.Password.toLowerCase().trim() === this.confirmPassword.toLowerCase().trim();
  }

  formatCountry = (result: any) => result.Name;

  // Search country
  searchCountry = (text: Observable<string>) =>
    text
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(term => this.countries.filter(v =>
            v.Code.toLowerCase().indexOf(term.toLowerCase()) > -1
          ).concat(
            this.countries.filter(v =>
                v.Code.toLowerCase().indexOf(term.toLowerCase()) === -1 &&
                v.Name.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
          )
        )
      )

  // User selected a country
  setCountry(event) {

    // Store the selected country code
    this.signupInfo.Country = event.item.Code;

    // If country is not US, states don't make sense
    this.signupInfo.State = (this.signupInfo.Country !== 'US') ? '-' : null;
  }

  register(mode: string) {

    // Immediately leave if we're already saving
    if (!this.form.valid || this.isSaving || !this.confirmPasswordValid()) {

      // Show required fields
      this.isInvalidSubmitted = true;
      return;
    }

    // Show spinner
    this.isSaving = true;

    // Reset the status
    this.errorSaving = '';
    this.errorResendingVerification = '';
    this.isVerificationResent = false;

    // Select the right handler
    const observable = mode === 'MERGE-ACCOUNTS' ? this.authHttpService.linkCredentials(this.signupInfo) : this.authHttpService.createAccount(this.signupInfo);

    // Create account
    observable
      .subscribe(
        (value) => {

          // Process value
          this.onRegisterSuccess(value);

          // Hide spinner
          this.isSaving = false;
        },
        (error) => {

          // Set the error
          this.errorSaving = error.error;

          // Hide spinner
          this.isSaving = false;
        }
      );
  }

  resendVerificationEmail() {

    // Immediately leave if we're already resending
    if (this.isResendingVerification) {
      return;
    }

    // Show spinner
    this.isResendingVerification = true;

    // Reset the status
    this.errorResendingVerification = '';
    this.isVerificationResent = false;

    // Call the web service
    this.authHttpService.resendVerificationEmail(this.signupInfo.Email)
      .subscribe(
        () => {

          // Show message
          this.isVerificationResent = true;

          // Hide spinner
          this.isResendingVerification = false;
        },
        (error) => {

          // Set the error
          this.errorResendingVerification = error.error;

          // Hide spinner
          this.isResendingVerification = false;
        }
      );
  }

  private onAuthProviderActionChange(response: SignInResponse) {

    // Set the credentials
    this.signupInfo.AuthProvider = response.AuthProvider;
    this.signupInfo.Email = response.Email;
    this.signupInfo.FirstName = response.FirstName;
    this.signupInfo.LastName = response.LastName;
    this.signupInfo.Token = response.Token;

    // If timezone is resolvable, pass it with credentials
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone) {
      this.signupInfo.Timezone = timezone;
    }

    this.register(response.Status);
  }

  private onRegisterSuccess(sessionInfo: any) {

    // Log in the user by creating a session
    // No more, since we want new users to verify their account first
    //this.authService.createSession(sessionInfo);

    // Fire the Google and FB tracking
    this.trackingPixelService.registerNewCustomer();

    // Emit output that account has been successfully created
    this.accountCreatedEvent.emit(true);
  }

  // Render the Google Sign-in button
  private renderGoogleSignInButton() {

    this.googleSignInService.renderButton(this.googleSignInButton, {
      Size: 'large',
      Text: 'signup_with'
    });
  }

  private setTelemetry() {
    setTimeout(() => this.telemetryService.inject('CLICK', 'create-account-form-cancel', null, true));
    setTimeout(() => this.telemetryService.inject('CLICK', 'create-account-form-submit', null, true));
  }
}
