<form #waitlistForm="ngForm">
  <div class="modal-header">
    <h2 i18n>Join Class Waitlist</h2>
  </div>

  <!-- BODY -->
  <div class="modal-body">

    <!-- NOT SAVED, SHOW THE FORM -->
    <div *ngIf="!isSaved">
      <h4 class="cwk-grey">Fill out your information and get notified if a spot opens up!</h4>
      <div class="fix-row">
        <div class="col form-group mt-2">

          <!-- EMAIL -->
          <label for="email" i18n>Parent Email:</label>
          <input type="email" class="form-control" name="email" [ngClass]="{'cwk-form-control-email': waitlistInfo.Email && email.errors && (email.dirty || email.touched) && email.errors['email'], 'cwk-form-control-required': !waitlistInfo.Email}" [(ngModel)]="waitlistInfo.Email" placeholder="Email..." #email="ngModel" email required>
        </div>
      </div>
      <div class="fix-row">
        <div class="col-12 col-sm-6 form-group mt-2">

          <!-- FIRST NAME -->
          <label for="firstName" i18n>Parent First Name:</label>
          <input type="text" class="form-control" name="firstName" [ngClass]="{'cwk-form-control-required': !waitlistInfo.FirstName}" [(ngModel)]="waitlistInfo.FirstName" placeholder="First name..." required>
        </div>
        <div class="col-12 col-sm-6 form-group mt-2">

          <!-- LAST NAME -->
          <label for="firstName" i18n>Parent Last Name:</label>
          <input type="text" class="form-control" name="firstName" [ngClass]="{'cwk-form-control-required': !waitlistInfo.LastName}" [(ngModel)]="waitlistInfo.LastName" placeholder="Last name..." required>
        </div>
      </div>

      <div class="fix-row">
        <div class="col">

          <!-- NAME OF STUDENTS -->
          <label for="students" i18n>Name(s) of student(s) to put on waitlist:</label>
          <input type="text" class="form-control" name="students" [ngClass]="{'cwk-form-control-required': !waitlistInfo.Notes}" [(ngModel)]="waitlistInfo.Notes" placeholder="Name(s) of student(s) to put on waitlist..." required>
        </div>
      </div>

      <div class="mt-4 alert alert-secondary" role="alert">
        <fa-icon class="me-2" [icon]="faInfoCircle"></fa-icon> Joining the waitlist does not guarantee a spot in the class. If a spot becomes available, the members of the waitlist will be notified via email. However, registration for that spot is open publicly on the website and may be claimed by anyone.
      </div>
    </div>

    <!-- ERROR SAVING -->
    <ngb-alert *ngIf="errorSaving" class="mt-3" type="danger" (close)="errorSaving = null">
      <b><fa-icon [icon]="faExclamationTriangle"></fa-icon>&nbsp;Error occurred while saving:</b>&nbsp;{{errorSaving}}
    </ngb-alert>

    <!-- SAVED, SHOW SUCCESS MESSAGE -->
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      You have been added to the waiting list! If a spot opens up we will contact you at {{ waitlistInfo.Email }}.
    </div>
  </div>

  <!-- FOOTER -->
  <div class="modal-footer">
    <button *ngIf="!isSaved" type="button" class="btn btn-warning btn-large mr-auto" (click)="cancel()" i18n>Cancel</button>
    <button *ngIf="!isSaved" type="button" class="btn btn-warning btn-large" [disabled]="isSaving || !waitlistForm.valid" (click)="submit()" i18n>
      <span *ngIf="isSaving; else join"><fa-icon [icon]="faSpinner" animation="spin"></fa-icon>&nbsp;Joining waitlist...</span>
      <ng-template #join>Join Waiting List</ng-template>
    </button>
    <div *ngIf="isSaved">
      <button type="button" class="btn btn-warning btn-large pull-right" (click)="close()">Close</button>
    </div>
  </div>
</form>
