import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LogoInfo } from './interfaces/logo-info';

// Icons
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-school-logos',
  templateUrl: './school-logos.component.html',
  styleUrls: ['./school-logos.component.scss'],
  standalone: false
})

export class SchoolLogosComponent implements OnInit {

  @Input() mode: string; // AFTER-SCHOOL-ENRICHMENT or INTEGRATED-CODING-PROGRAMS

  @ViewChild('carousel', {static: false}) carouselElement: ElementRef;

  isMouseDown = false;
  logos: LogoInfo[] = null;
  scrollLeft: any;
  showLeftArrow = false;
  showRightArrow = true;
  startX = 0;

  // Icons
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  readonly CAROUSEL_SCROLL_WIDTH = 300;
  readonly prefixAS = 'https://d3t4xfu733v2tb.cloudfront.net/web/home/after-school-enrichment/district-logos';
  readonly prefixICP = 'https://d3t4xfu733v2tb.cloudfront.net/web/home/integrated-coding-programs/school-logos';

  // TODO: This should be read from the DB if continuous updates are necessary
  readonly afterschoolEnrichmentLogos: LogoInfo[] = [
    // { ImageURL: `${this.prefixAS}/Lake Washington School District.png`, Title: 'Partner since 2014' },
    { ImageURL: `${this.prefixAS}/Seattle Public Schools.png`, Title: 'Partner since 2014' },
    { ImageURL: `${this.prefixAS}/Northshore School District.png`, Title: 'Partner since 2014' },
    { ImageURL: `${this.prefixAS}/Bellevue School District.png`, Title: 'Partner since 2014' },
    { ImageURL: `${this.prefixAS}/Beaverton School District.png`, Title: 'Partner since 2016' },
    { ImageURL: `${this.prefixAS}/Austin ISD.png`, Title: 'Partner since 2017' },
    { ImageURL: `${this.prefixAS}/Denver Public Schools-alt.png`, Title: 'Partner since 2016' },
    { ImageURL: `${this.prefixAS}/Westminster Public Schools (Adams).png`, Title: 'Partner since 2016' },
    { ImageURL: `${this.prefixAS}/Fairfax County Public Schools.png`, Title: 'Partner since 2017' },
    { ImageURL: `${this.prefixAS}/Issaquah School DIstrict.png`, Title: 'Partner since 2017' },
    { ImageURL: `${this.prefixAS}/JeffCo Schools.png`, Title: 'Partner since 2017' },
    { ImageURL: `${this.prefixAS}/Lake Oswego School District-alt.png`, Title: 'Partner since 2017' },
    { ImageURL: `${this.prefixAS}/Portland Public Schools-alt.png`, Title: 'Partner since 2017' },
    { ImageURL: `${this.prefixAS}/Cherry Creek Schools.png`, Title: 'Partner since 2018' },
    { ImageURL: `${this.prefixAS}/Dripping Springs ISD.png`, Title: 'Partner since 2018' },
    { ImageURL: `${this.prefixAS}/Eanes ISD.png`, Title: 'Partner since 2018' },
    { ImageURL: `${this.prefixAS}/Round Rock ISD.png`, Title: 'Partner since 2018' },
    { ImageURL: `${this.prefixAS}/Hillsboro School District.png`, Title: 'Partner since 2019' },
    { ImageURL: `${this.prefixAS}/Hillsborough County Public Schools.png`, Title: 'Partner since 2019' }
  ];
  readonly integratedCodingProgramLogos: LogoInfo[] = [
    // { ImageURL: `${this.prefixICP}/Lake Washington School District.png`, Title: null },
    { ImageURL: `${this.prefixICP}/The Bear Creek School Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Seattle Country Day School.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Good Hope Country Day School Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/St. George Parish School Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Cordoba Academy Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Westside Middle School Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/St. John's Episcopal School Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Father Allouez Catholic School Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Dominican High School Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Forcey Christian School Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Har-Bur Middle School-alt.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Laurel Mountain Elementary.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Marian Middle School Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Mount Pleasant Christian School Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Otsego Junior High Logo-alt.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Our Lady of the Snows Catholic School Logo.png`, Title: null },
    { ImageURL: `${this.prefixICP}/Purpose of Life Academy Logo.png`, Title: null }
  ];

  constructor() { }

  ngOnInit() {

    // Set the logos based on the mode
    switch (this.mode) {
      case 'AFTER-SCHOOL-ENRICHMENT':
        this.logos = this.afterschoolEnrichmentLogos;
        break;
      case 'INTEGRATED-CODING-PROGRAMS':
        this.logos = this.integratedCodingProgramLogos;
        break;
    }
  }

  mouseDown(event: MouseEvent, carousel: HTMLElement) {
    this.isMouseDown = true;
    this.startX = event.pageX - carousel.offsetLeft;
    this.scrollLeft = carousel.scrollLeft;
  }

  mouseMoved(event: MouseEvent, carousel: HTMLElement) {
    event.preventDefault();
    if (!this.isMouseDown) {
      return;
    }

    const x = event.pageX - carousel.offsetLeft;
    const scroll = x - this.startX;
    carousel.scrollLeft = this.scrollLeft - scroll;
  }

  mouseOff() {
    this.isMouseDown = false;
  }

  scrollCarousel(direction: string, carousel: HTMLElement) {
    carousel.scrollTo({ left: Math.min(Math.max(0, (carousel.scrollLeft + (direction === 'RIGHT' ? this.CAROUSEL_SCROLL_WIDTH :
        -this.CAROUSEL_SCROLL_WIDTH))), carousel.scrollWidth - carousel.clientWidth)});
  }

  updateArrows(carousel: HTMLElement) {
    this.showLeftArrow = carousel.scrollLeft > 0;
    this.showRightArrow = (carousel.scrollLeft + carousel.clientWidth) < carousel.scrollWidth;
  }
}
