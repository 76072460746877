import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  private storage: Storage | null = null;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    if (isPlatformBrowser(this.platformId)) {
      this.storage = sessionStorage;
    }
  }

  // We use environment.localStoragePrefix because the session storage is temporary only and
  // keys never collide anyway in both local and session storages

  getItem(key) {
    return this.storage && this.storage.getItem(environment.localStoragePrefix + key);
  }

  // Session storage items should never be removed explicitly
  // Browser removes them automatically when the session is over (tab or browser is closed)
  removeItem(key) {
    return this.storage && this.storage.removeItem(environment.localStoragePrefix + key);
  }

  setItem(key, value) {
    return this.storage && this.storage.setItem(environment.localStoragePrefix + key, value);
  }
}
