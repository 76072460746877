import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnlineCodersLadderRegistrationListDataService {

 constructor(
    private http: HttpClient
  ) { }

  private urlOnlineCodersLadder = environment.urlWebServices + '/cwk/onlineCodersLadder';

  readRegistrations(classId: number, isPrivate: number, currency: string, discountCode: string): Observable<any> {

    // Pass data in params
    const params = new HttpParams()
      .set('discountCode', discountCode);

    const getURL = `${this.urlOnlineCodersLadder}/readRegistrations/${classId}/${isPrivate}/${currency}`;
    return this.http.get<any>(getURL, { params: params })
      .pipe(
        map(this.fixRegistrationsFromMySQL),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  readSpotsLeft(classId: number, isPrivate: number, currency: string): Observable<any> {

    const getURL = `${this.urlOnlineCodersLadder}/readSpotsLeft/${classId}/${isPrivate}/${currency}`;
    return this.http.get<any>(getURL)
      .pipe(
        tap(response => {
          response.forEach(this.fixSpotsLeftFromMySQL);
        }),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  private fixRegistrationsFromMySQL(response) {

    const result = structuredClone(response);

    result.forEach(data => {

      // Parse sessions
      data.Sessions = data.Sessions ? JSON.parse(data.Sessions) : [];
      data.Sessions.forEach(session => {

        // Parse discounts
        session.Discounts = session.Discounts ? JSON.parse(session.Discounts) : [];
        session.Discounts.forEach(discount => {

          // Booleans
          discount.IsExclusive = !!discount.IsExclusive;

          // Dates
          discount.ExpiresOn = discount.ExpiresOn && new Date(discount.ExpiresOn.replace(/ /g, 'T') + 'Z');
        });

        // Parse leaders
        session.Leaders = session.Leaders ? JSON.parse(session.Leaders) : [];

        // Fix booleans
        session.HasEnded = !!session.HasEnded;
        session.IsExperienced = !!session.IsExperienced;
        session.IsMultiday = !!session.IsMultiday;
        session.IsStartingWithinTwoWeeks = !!session.IsStartingWithinTwoWeeks;

        // Fix dates and times
        session.EndUTC = session.EndUTC && new Date(session.EndUTC.replace(/ /g, 'T') + 'Z');
        session.StartUTC = session.StartUTC && new Date(session.StartUTC.replace(/ /g, 'T') + 'Z');

        // Fix session's weekday end
        if (session.WeekDayEndsUTC) {
          session.WeekDayEndsUTC = session.WeekDayEndsUTC
            .split(',')
            .map(timestamp => new Date(timestamp.replace(/ /g, 'T') + 'Z'));
        }

        // Fix session's weekday starts
        if (session.WeekDayStartsUTC) {
          session.WeekDayStartsUTC = session.WeekDayStartsUTC
            .split(',')
            .map(timestamp => new Date(timestamp.replace(/ /g, 'T') + 'Z'));
        }
      });
    });

    return result;
  }

  private fixSpotsLeftFromMySQL(data) {

    if (data.IsParsed) {
      return;
    }

    // Parse sessions
    data.Sessions = data.Sessions ? JSON.parse(data.Sessions) : [];

    data.IsParsed = true;
  }
}
