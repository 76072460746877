import { Component, OnInit } from '@angular/core';
import { TelemetryService } from '@shared/services/telemetry.service';

@Component({
  selector: 'home-coders-pathway-section',
  templateUrl: './coders-pathway-section.component.html',
  styleUrls: ['./coders-pathway-section.component.scss'],
  standalone: false
})
export class CodersPathwaySectionComponent implements OnInit {

  constructor(
    private telemetryService: TelemetryService
  ) { }

  ngOnInit() {
    this.telemetryService.inject('CLICK', 'explore-coders-pathways', null, true);
  }

}
