<div class="container p-4">

  <!-- Pathway image -->
  <img class="float-start pathway-image me-4" src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathways/{{ pathway.IconFile }}" alt="{{ pathway.Name }} icon" loading="lazy" />

  <!-- Name and close button -->
  <div class="d-flex justify-content-between">
    <h3 class="fw-bold">{{ pathway.Name }} Pathway</h3>
    <fa-icon class="cwk-pointer" [icon]="faTimesCircle" size="lg" [fixedWidth]="true" (click)="close()"></fa-icon>
  </div>

  <!-- Descriptions -->
  <div *ngFor="let description of pathway.Descriptions">
    <h4 class="mt-2 text-muted" *ngIf="!showGrades">Ages {{ description.AgeMin }}<span [hidden]="description.AgeMax">+</span><span [hidden]="!description.AgeMax"> - {{ description.AgeMax }}</span></h4>
    <h4 class="mt-2 text-muted" *ngIf="showGrades">Grades {{ description.AgeMin <= 5 ? 'K' : description.AgeMin - 5 }}<span [hidden]="description.AgeMax">+</span><span [hidden]="!description.AgeMax"> - {{ description.AgeMax - 5 }}</span></h4>
    <div *ngIf="description.Description" [innerHTML]="description.Description" class="text-justify my-3"></div>
  </div>

  <div *ngIf="!pathway.Descriptions.length" class="my-3">Description coming soon...</div>

  <!-- Video -->
  <div *ngIf="pathway.VideoLink" class="mt-3">
    <home-embed-video [videoSrc]="pathway.VideoLink" videoTitle="Coding with Kids Coder's Pathway"></home-embed-video>
  </div>
</div>
