<div class="p-4">
  <div class="d-flex flex-row mb-4">

    <!-- Class image -->
    <div class="d-flex flex-column">
      <img class="shadow class-image" alt="{{ _class.ClassName }} image" src="https://d3t4xfu733v2tb.cloudfront.net/classes/{{ _class.ImagePath }}" loading="lazy" />

      <!-- "New" image -->
      <div *ngIf="_class.IsNew" class="new-class-container">
        <img class="new-class-img" alt="New camp!" src="https://d3t4xfu733v2tb.cloudfront.net/classes/new-corner-orange.png" loading="lazy" />
      </div>
    </div>
    <div class="d-flex flex-column justify-content-between w-100">
      <div class="ms-3">

        <!-- Class name -->
        <div class="d-flex justify-content-between">
          <h3 class="fw-bold mb-0"><span class="new" *ngIf="_class.IsNew">*NEW* </span>{{ _class.ClassName }}</h3>
          <fa-icon class="cwk-pointer" [icon]="faTimesCircle" size="lg" [fixedWidth]="true" (click)="close()"></fa-icon>
        </div>

        <!-- Ages -->
        <div [hidden]="!_class.AgeMin">
          <ng-container *ngIf="!showGrades"><b>Ages:</b>&nbsp;{{ _class.AgeMin + (_class.AgeMax ? ('-' + _class.AgeMax) : '+') }}</ng-container>
          <ng-container *ngIf="showGrades"><b>Grades:</b>&nbsp;{{ gradeRange }}</ng-container>
        </div>
      </div>
      <div class="ms-3">

        <!-- Tools (programming languages) -->
        <div *ngIf="_class.Tools" class="mb-1">
          <b>Languages:</b>&nbsp;<span [innerHTML]="_class.Tools"></span>
        </div>

        <!-- Coder's ladder experience -->
        <div *ngIf="_class.Experience" class="mb-1">
          <b>Coder's Ladder Experience:</b>&nbsp;<span [innerHTML]="_class.Experience"></span>
        </div>
      </div>
    </div>
  </div>

  <!-- Pathways -->
  <div *ngIf="_class.Pathways">
    <b>Pathway{{ _class.Pathways.length > 1 ? 's' : '' }}:</b>
    <ul>
      <li *ngFor="let pathway of _class.Pathways; let isLast = last" class="me-1">
        <div class="d-flex align-items-center">
          {{ pathway.Name }}<img src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathways/{{ pathway.IconFile }}" alt="{{ pathway.Name }} icon" loading="lazy" class="ms-2" style="width: 25px; height: 25px;" [ngClass]="{ 'me-2' : !isLast }"/>
        </div>
      </li>
    </ul>
  </div>

  <!-- Prerequisites & graduation requirements -->
  <div *ngIf="_class.Prerequisity" class="mb-3"><b>Prerequisites:</b><br/><span [innerHTML]="_class.Prerequisity"></span></div>
  <div *ngIf="_class.GraduationCriteria" class="mb-3"><b>Graduation criteria:</b><br/><span [innerHTML]="_class.GraduationCriteria"></span></div>

  <!-- Description & online message -->
  <div class="fw-bold">Class description:</div>
  <div *ngIf="_class.Description" [innerHTML]="_class.Description" class="text-justify mb-3"></div>
  <div *ngIf="_class.OnlineMessage" class="alert alert-warning" role="alert">
    <fa-icon class="me-2" [icon]="faInfoCircle"></fa-icon>{{ _class.OnlineMessage }}
  </div>
</div>
