import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InPersonRegistrationsSharingService } from '../../services/in-person-registrations-sharing.service';
import { Location } from '../../interfaces/location';
import { skip } from 'rxjs/operators';

// Icons
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.scss'],
  standalone: false
})
export class LocationFilterComponent implements OnInit {

  @Input() isCamp = false;

  address: string = '';
  isFullDay = true;
  isHalfDay = true;
  locationsSelected: Location[] = [];

  // Icons
  faSearch = faSearch;

  constructor(
    private activatedRoute: ActivatedRoute,
    private inPersonRegistrationsSharingService: InPersonRegistrationsSharingService,
    private router: Router
  ) { }

  ngOnInit() {

    // Get address from URL (if any)
    if (this.activatedRoute.snapshot.queryParams['a']) {
      this.address = atob(this.activatedRoute.snapshot.queryParams['a']);
      this.inPersonRegistrationsSharingService.updateAddress(this.address);
    }

    // Get full-day switch
    if (this.activatedRoute.snapshot.queryParams['f']) {
      this.isFullDay = false;
    }

    // Get half-day switch
    if (this.activatedRoute.snapshot.queryParams['h']) {
      this.isHalfDay = false;
    }

    // If one of full- or half-day flags is not default (true), fire change
    if (!this.isFullDay || !this.isHalfDay) {
      this.inPersonRegistrationsSharingService.updateCampDay(this.isHalfDay, this.isFullDay);
    }

    // Get location from URL (if any) and generate fake locations to keep URL untouched
    if (this.activatedRoute.snapshot.queryParams['l']) {
      this.locationsSelected = this.activatedRoute.snapshot.queryParams['l'].split('_').map(id => { return { IsSelected: true, IsVisible: true, LocalityId: id }; });
    }

    // Update query params according to current values
    this.updateQueryParams();

    // Watch the selected location
    this.inPersonRegistrationsSharingService.getLocationsSelected()
      .pipe(skip(1))
      .subscribe((locations) => this.locationsChanged(locations));
  }

  addressChanged() {
    this.inPersonRegistrationsSharingService.updateLocationsSelected([]);
    this.inPersonRegistrationsSharingService.updateAddress(this.address);
    this.updateQueryParams();
  }

  isFullDayChanged() {
    this.isFullDay = !this.isFullDay;
    this.isHalfDay = true;
    this.inPersonRegistrationsSharingService.updateCampDay(this.isHalfDay, this.isFullDay);
    this.updateQueryParams();
  }

  isHalfDayChanged() {
    this.isHalfDay = !this.isHalfDay;
    this.isFullDay = true;
    this.inPersonRegistrationsSharingService.updateCampDay(this.isHalfDay, this.isFullDay);
    this.updateQueryParams();
  }

  locationsChanged(locations: Location[]): void {
    this.locationsSelected = locations;
    this.updateQueryParams();
  }

  updateQueryParams() {
    const queryParams = {
      a: btoa(this.address) || null,
      f: this.isFullDay ? null : 0,
      h: this.isHalfDay ? null : 0,
      l: this.locationsSelected.map(location => location.LocalityId).join('_') || null
    };

    // Update the query params
    this.router.navigate(this.activatedRoute.snapshot.url, { queryParams: queryParams, queryParamsHandling: 'merge', replaceUrl: true });
  }
}
