<div class="d-none d-lg-block">
  <div class="cwk-lightgrey-bg top-container d-flex flex-column justify-content-center" style="height: 50px;">
    <div class="px-3 d-flex justify-content-between align-items-center">
      <h4 class="my-0 fw-bold">{{ classType === 'CAMP' ? 'Camp locations' : 'Schools' }}</h4>
      <small class="text-center"><i>{{ classType === 'CAMP' ? 'Locations' : 'Schools' }} automatically<br/>sync with map</i></small>
    </div>
  </div>
</div>

<div *ngFor="let location of locations" [id]="'l' + location.LocalityId">
  <div *ngIf="location.IsVisible" class="border-bottom">
    <div class="d-flex align-items-center p-3" [ngClass]="{'location-hover' : !location.IsComingSoon, 'location-selected' : location.IsSelected}" (click)="location.IsComingSoon || updateSelectedLocation(location)">

      <div class="flex-grow-1">

        <!-- Location name -->
        <div class="d-flex justify-content-between">
          <div>
            <h5 class="mb-0 fw-bold">{{ location.Name }}</h5>
            <div class="location-secondary" *ngIf="classType !== 'CAMP'">{{ location.AddressLn1 }} {{ location.City }}<span [hidden]="!location.State">,</span> {{ location.State }} {{ location.ZIP }}</div>
            <div class="mt-1" *ngIf="classType === 'CAMP'">
              <span class="camp-day-flag me-2" ngbPopover="{{ location.IsHalfDay ? 'Offers' : 'Doesn\'t offer' }} half-day camps" triggers="mouseenter:mouseleave">
                <span [ngClass]="{'offered': location.IsHalfDay, 'not-offered': !location.IsHalfDay}"><fa-icon [icon]="location.IsHalfDay ? faCircleCheck : faCircleXmark"></fa-icon></span>
                HALF-DAY
              </span>
              <span class="camp-day-flag me-2" ngbPopover="{{ location.IsFullDay ? 'Offers' : 'Doesn\'t offer' }} full-day camps" triggers="mouseenter:mouseleave">
                <span [ngClass]="{'offered': location.IsFullDay, 'not-offered': !location.IsFullDay}"><fa-icon [icon]="location.IsFullDay ? faCircleCheck : faCircleXmark"></fa-icon></span>
                FULL-DAY
              </span>
            </div>
          </div>
          <img class="d-block d-sm-none" [src]="!location.IsSelected ? (classType === 'CAMP' || location.IsOfferingSession ? constants.PIN_RED : constants.PIN_BLUE) : constants.PIN_YELLOW" alt="Map pin" loading="lazy" width="25px" height="30px">
        </div>

        <!-- Coming soon / Pathways -->
        <div *ngIf="classType === 'CAMP'" class="d-flex mt-2 w-100 justify-content-between">
          <div *ngIf="location.IsComingSoon; else pathways" class="coming-soon">
            Camps Coming Soon!
          </div>
          <ng-template #pathways>
            <div *ngFor="let pathway of location.Pathways" class="pathway-col" [ngbPopover]="(!pathway.IsAM && !pathway.IsPM ? 'Does not offer ' : 'Offers ') + (pathway.IsAM ? 'AM ' : '') + (pathway.IsAM && pathway.IsPM ? '& ' : '') + (pathway.IsPM ? 'PM ' : '') + pathway.Name + ' camps'" triggers="mouseenter:mouseleave">
              <img class="img-fluid" style="width: 30px; height: 30px;" src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathways/{{pathway.IconFileDisplay}}" loading="lazy" class="img-fluid"/>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="ms-4 ms-xxl-5 d-none d-sm-block">
        <img [src]="!location.IsSelected ? (classType === 'CAMP' || location.IsOfferingSession ? constants.PIN_RED : constants.PIN_BLUE) : constants.PIN_YELLOW" alt="Map pin" loading="lazy" width="30px" height="36px">
      </div>
    </div>
  </div>
</div>
