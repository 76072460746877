<div class="modal-body">
  <div class="py-2 mb-3 cwk-extralightgrey2-bg">
    <h3 class="text-center fw-bold"><span *ngIf="_class.Code !== 'CAMP'">{{ sessions[0].StartUTC | date:'EEEE' }}s - </span>{{ _class.ClassName }}</h3>
    <h4 class="cwk-orange text-center" *ngIf="_class.Code !== 'CAMP'">{{ sessions.length }} Upcoming Class{{ sessions.length > 1 ? 'es' : '' }}</h4>
    <h4 class="cwk-orange text-center" *ngIf="_class.Code === 'CAMP'">{{ sessions.length }} Upcoming Camp{{ sessions.length > 1 ? 's' : '' }}</h4>
  </div>
  <table class="mb-2 table table-hover table-borderless" [ngClass]="{ 'fs-smaller': !isOnline }">
    <thead>
      <tr class="border-bottom">
        <!--th *ngIf="showProgramFormat" scope="col">Camp Format</th-->
        <th scope="col" *ngIf="!isOnline">Location</th>
        <th scope="col">Date</th>
        <th scope="col">Time ({{ timezoneAbbr }})</th>
        <th scope="col">Price</th>
        <th scope="col">&nbsp;</th>
        <th scope="col">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let session of sessions; last as isLast" class="cwk-pointer sessions-container" [ngClass]="{ 'border-bottom' : !isLast }" (click)="goSignup(session)">
        <!--td *ngIf="showProgramFormat">
          {{ session.IsOnline ? 'Hybrid+' : 'Classic' }}
        </td-->
        <td class="location" *ngIf="!isOnline">{{ session.Location }}</td>
        <td>
          {{ session.SessionStartUTC | date:'MMMM d, yyyy' }} - {{ session.SessionEndUTC | date:'MMMM d, yyyy' }}
        </td>
        <td>
          {{ session.StartUTC | date:'h:mm' }} - {{ session.EndUTC | date:'h:mm aa' }}
        </td>
        <td>
          <div class="price">
            <span *ngIf="!session.Discounts.length">
              {{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}<sub> / {{ session.Price4ClassSessions + ' ' + session.Period + (session.Price4ClassSessions > 1 ? 's' : '') }}</sub>
            </span>
            <span *ngIf="session.Discounts.length">
              <span class="non-discounted">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</span>
              &#32;{{ (session.FinalPrice | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }} / {{ session.Price4ClassSessions + ' ' + session.Period + (session.Price4ClassSessions > 1 ? 's' : '') }}
            </span>
          </div>
        </td>
        <td>
          <div *ngIf="session.SpotsLeft <= 0" class="px-1 spots-left">FULL</div>
          <div *ngIf="session.SpotsLeft <= 3 && session.SpotsLeft > 0" class="px-1 spots-left">{{ session.SpotsLeft }} spot{{ session.SpotsLeft === 1 ? '' : 's' }} left!</div>
          <div *ngIf="session.SpotsLeft > 3">&nbsp;</div>
        </td>
        <td class="text-end">
          <button class="btn btn-light cwk-button" [disabled]="session.HasEnded" [ngClass]="{ 'fs-smaller': !isOnline }">
            {{ session.HasEnded ? 'CLOSED' : (session.Occupied ? 'WAITLIST' : 'REGISTER') }}
            <fa-icon *ngIf="session.IsExternalRegistration" [icon]="faExternalLinkAlt" class="ms-2" ngbPopover="Registration handled externally" container="body" triggers="mouseenter:mouseleave"></fa-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light cwk-button me-auto" (click)="close()">Close</button>
</div>
