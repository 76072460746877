<form class="mb-3" #signupForm="ngForm">

  <!-- FIRST NAME -->
  <div class="form-floating mb-3">
    <input type="text" class="cwk-form-control form-control" name="firstName" id="firstName" [ngClass]="{'cwk-form-control-invalid': firstName.errors && firstName.errors['pattern'], 'cwk-form-control-missing': !signupInfo.FirstName && (firstName.dirty || firstName.touched || isInvalidSubmitted)}" [(ngModel)]="signupInfo.FirstName" maxlength="128" #firstName="ngModel" pattern="^[^@\(\)&]+$" placeholder="First Name" required>
    <label for="firstName" i18n>First Name</label>
    <small class="cwk-red ms-3" *ngIf="signupInfo.FirstName && firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors['pattern']">Special characters are not allowed</small>
    <small class="cwk-red ms-3" *ngIf="!signupInfo.FirstName && (firstName.dirty || firstName.touched)">This field is required</small>
  </div>

  <!-- EMAIL -->
  <div class="form-floating mb-3">
    <input type="email" class="cwk-form-control form-control" name="email" id="email" [ngClass]="{'cwk-form-control-invalid': signupInfo.Email && email.errors && (email.dirty || email.touched) && (email.errors['email'] || email.errors['pattern']), 'cwk-form-control-missing': !signupInfo.Email && (email.dirty || email.touched || isInvalidSubmitted)}" [(ngModel)]="signupInfo.Email" [pattern]="cwkDomainPattern" placeholder="Email" #email="ngModel" email maxlength="128" required>
    <label for="email" i18n>Email</label>
    <small class="cwk-red ms-3" *ngIf="signupInfo.Email && email.invalid && (email.dirty || email.touched) && !email.errors['pattern']">Must be valid email address</small>
    <small class="cwk-red ms-3" *ngIf="signupInfo.Email && email.invalid && (email.dirty || email.touched) && email.errors['pattern']">Must not be {{ environment.domainName }} domain email address</small>
    <small class="cwk-red ms-3" *ngIf="!signupInfo.Email && (email.dirty || email.touched)">This field is required</small>
  </div>

  <ng-container *ngIf="geolocation">

    <!-- COUNTRY
      NOTE 1: Set autocomplete to some non-understandable value to remove the user's auto-fill functionality
      NOTE 2: Use hidden input for country code validation
    -->
    <div class="form-floating mb-3">
      <!--input type="text" class="form-control" id="countryCode" name="countryCode" [hidden]="true" [(ngModel)]="signupInfo.Country" required/-->
      <input class="cwk-form-control form-control" #country="ngModel" name="country" [ngClass]="{'cwk-form-control-missing' : !signupInfo.Country && (country.dirty || country.touched || isInvalidSubmitted)}" [inputFormatter]="formatCountry" [(ngModel)]="selectedCountry" [ngbTypeahead]="searchCountry" placeholder="Country" [resultFormatter]="formatCountry" (ngModelChange)="checkCountry($event)" (selectItem)="setCountry($event)" type="text" autocomplete="custom-country-typeahead" />
      <label for="country" i18n>Country</label>
      <small class="cwk-red ms-3" *ngIf="!signupInfo.Country && (country.dirty || country.touched)">This field is required</small>
    </div>

    <!-- STATE -->
    <div class="form-floating mb-3" *ngIf="signupInfo.Country === 'US'">
      <select class="cwk-form-control form-control form-select" name="state" [ngClass]="{'cwk-form-control-missing': !signupInfo.State && signupInfo.Country === 'US' && (state.dirty || state.touched || isInvalidSubmitted)}" [(ngModel)]="signupInfo.State" [disabled]="signupInfo.Country !== 'US'" required #state="ngModel">
        <option [ngValue]="null" disabled>Choose State</option>
        <option [disabled]="(state.Code === '-') && (signupInfo.Country === 'US')" *ngFor="let state of states" [ngValue]="state.Code">{{ state.Name }}</option>
      </select>
      <label for="state" i18n>State</label>
      <small class="cwk-red ms-3" *ngIf="!signupInfo.State && signupInfo.Country === 'US' && (state.dirty || state.touched)">This field is required</small>
    </div>
  </ng-container>

  <!-- PASSWORD -->
  <div class="form-floating mb-3">
    <input class="cwk-form-control form-control" maxlength="128" name="password" [ngClass]="{'cwk-form-control-missing': !signupInfo.Password && (password.dirty || password.touched || isInvalidSubmitted)}" [(ngModel)]="signupInfo.Password" placeholder="Password" type="password" autocomplete="off" #password="ngModel" required/>
    <label for="password" i18n>Password</label>
    <small class="cwk-red ms-3" *ngIf="!signupInfo.Password && (password.dirty || password.touched)">This field is required</small>
  </div>

  <!-- CONFIRM PASSWORD -->
  <div class="form-floating mb-3">
    <input class="cwk-form-control form-control" maxlength="128" name="password2" [ngClass]="{'cwk-form-control-invalid': confirmPassword && !confirmPasswordValid() && (password2.dirty || password2.touched), 'cwk-form-control-missing': !confirmPassword && (password2.dirty || password2.touched || isInvalidSubmitted)}" [(ngModel)]="confirmPassword" placeholder="Confirm password" type="password" autocomplete="off" #password2="ngModel" required/>
    <label for="password2" i18n>Confirm Password</label>
    <small class="cwk-red ms-3" *ngIf="!confirmPassword && (password2.dirty || password2.touched)">This field is required</small>
    <small class="cwk-red ms-3" *ngIf="confirmPassword && !confirmPasswordValid() && (password2.dirty || password2.touched)">Passwords don't match</small>
  </div>

  <!-- CREATE BUTTON -->
  <div class="mt-4 d-flex justify-content-center">

    <!-- SOCIAL LOGIN - TEMPORARILY HIDDEN -->
    <div class="me-auto">
      <div #googleSignInButton [hidden]="true"></div>
    </div>

    <!-- CANCEL -->
    <button id="create-account-form-cancel" class="btn btn-warning me-5" (click)="cancel()" type="button">Cancel</button>

    <!-- CREATE ACCOUNT BUTTON -->
    <button id="create-account-form-submit" type="submit" class="btn btn-warning" (click)="register('SIGN-UP')" [disabled]="isSaving || isResendingVerification">
      <span *ngIf="isSaving; else createButton">
        <fa-icon class="me-1" [icon]="faSpinner" animation="spin"></fa-icon>Creating...
      </span>
      <ng-template #createButton>Create Account</ng-template>
    </button>
  </div>
  <div class="cwk-red mt-1 text-end" *ngIf="isInvalidSubmitted && !isSaving">
    <small>Required fields missing or invalid</small>
  </div>
</form>

<!-- ERROR CREATING ACCOUNT -->
<div class="alert alert-danger" *ngIf="errorSaving === 'DUPLICATE'">
  The account with the same email address already exists. Please <a class="cwk-link" routerLink="/reset">reset your password</a>.
</div>
<ng-container *ngIf="errorSaving === 'UNVERIFIED'">
  <div class="alert alert-danger" *ngIf="!errorResendingVerification && !isVerificationResent">
    The account with the same email address already exists but is not verified yet.
    <span *ngIf="!isResendingVerification"><a class="cwk-link" (click)="resendVerificationEmail()">Resend the verification email</a>.</span>
    <span class="cwk-blue-link"*ngIf="isResendingVerification"><fa-icon class="me-1" [icon]="faSpinner" animation="spin"></fa-icon>Resending the verification email...</span>
  </div>
  <div class="alert alert-success" *ngIf="!errorResendingVerification && isVerificationResent">
    The email with the verification link has been sent to your email address. If you don't see the email, check your Spam folder.
  </div>
</ng-container>
<cwk-error-alert *ngIf="errorSaving && !['DUPLICATE', 'UNVERIFIED'].includes(errorSaving)" [error]="errorSaving"></cwk-error-alert>
<cwk-error-alert *ngIf="errorResendingVerification" [error]="errorResendingVerification"></cwk-error-alert>
