import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DialogConfirmComponent } from '@shared/components/dialog-confirm/dialog-confirm.component';
import { DialogJoinWaitlistComponent } from '@shared/components/dialog-join-waitlist/dialog-join-waitlist.component';
import { WINDOW } from '@shared/services/window.service';

// Icons
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-dialog-future-sessions',
  templateUrl: './dialog-future-sessions.component.html',
  styleUrls: ['./dialog-future-sessions.component.scss', '../../../shared/styles/registration-lists.scss'],
  standalone: false
})
export class DialogFutureSessionsComponent {

  @Input() _class: any;
  @Input() discountCode: string;
  @Input() geolocation: any;
  @Input() isOnline = true;
  @Input() sessions: any[];
  @Input() showProgramFormat = false;

  timezoneAbbr = new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];

  // Icons
  faExternalLinkAlt = faExternalLinkAlt;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private router: Router,
    @Inject (WINDOW) private window: Window | null
  ) { }

  close() {
    this.activeModal.dismiss();
  }

  goSignup(session: any) {
    if (session.HasEnded || !this.window) {
      return;
    }

    if (session.Occupied) {
      const modalRef = this.modalService.open(DialogJoinWaitlistComponent, { size: 'lg', centered: true });
      modalRef.componentInstance.sessionId = session.LessonId;
      return;
    }

    if (session.IsExternalRegistration) {

      const modalRef = this.modalService.open(DialogConfirmComponent, { centered: true });
      if (!session.ExternalURL) {

        // Session is marked for external registrations but no external URL exists yet
        modalRef.componentInstance.buttonCancel = 'Close';
        modalRef.componentInstance.buttonSubmit = null;
        modalRef.componentInstance.header = 'Error!';
        modalRef.componentInstance.text = 'The registration page failed to open. Please contact us so we can fix it for you.';
      } else {
        modalRef.componentInstance.buttonSubmit = 'Continue';
        modalRef.componentInstance.header = 'Register at your school';
        // tslint:disable-next-line
        modalRef.componentInstance.text = 'Registration for this class is handled through the school. Continuing will take you to their registration page.';

        // Process backend response once the dialog is closed with success
        modalRef.result
          .then(() => this.window.open(session.ExternalURL))
          .catch(() => {});
      }
    } else {
      this.window.location.href = `/checkout?s=${session.LessonId}&c=${this.geolocation.currency}` + (this.discountCode ? `&d=${this.discountCode}` : '');
    }
  }
}
