import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';
import { faExclamationTriangle, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Countries } from '../../classes/countries';
import { DialogJoinWaitlistDataService } from './dialog-join-waitlist-data.service';

@Component({
  selector: 'cwk-dialog-join-waitlist',
  templateUrl: './dialog-join-waitlist.component.html',
  styleUrls: ['./dialog-join-waitlist.component.scss'],
  standalone: false
})
export class DialogJoinWaitlistComponent {

  @Input() sessionId: number;

  // Icons
  faExclamationTriangle = faExclamationTriangle;
  faInfoCircle = faInfoCircle;
  faSpinner = faSpinner;

  errorSaving: string = null;
  isSaved = false;
  isSaving = false;
  waitlistInfo = {
    Email: '',
    FirstName: null,
    LastName: null,
    Notes: null
  }

  constructor(
    private activeModal: NgbActiveModal,
    private dialogJoinWaitlistDataService: DialogJoinWaitlistDataService
  ) { }

  cancel() {
    this.activeModal.dismiss();
  }

  close() {
    this.activeModal.close();
  }

  submit() {

    // Clear error
    this.errorSaving = '';

    // Show spinner
    this.isSaving = true;

    // Execute the handler
    this.dialogJoinWaitlistDataService.joinWaitingList(this.sessionId, this.waitlistInfo)
      .subscribe(
        () => {
          this.isSaved = true;
          this.isSaving = false;
        },
        error => {
          this.errorSaving = ((error.text && error.text()) || error);
          this.isSaving = false;
        }
      );
  }
}
