import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GeolocationService } from '@shared/services/geolocation.service';
import { SpecialPageSharingService } from '../../../shared/services/special-page-sharing.service';
import { TelemetryService } from '@shared/services/telemetry.service';

@Component({
  selector: 'home-program-links',
  templateUrl: './program-links.component.html',
  styleUrls: ['./program-links.component.scss'],
  standalone: false
})
export class ProgramLinksComponent implements OnInit {

  @Input() hideTiles: string[];
  @Input() isInPerson = false;

  isRenderingOneTileOnly: boolean = null;
  geolocation = null;

  readonly totalTiles = 3;

  constructor(
    private geolocationService: GeolocationService,
    private router: Router,
    private specialPageSharingService: SpecialPageSharingService,
    private telemetryService: TelemetryService
  ) { }

  ngOnInit() {

    // Get current geolocation info
    this.geolocationService.getObservable()
      .subscribe((value) => this.geolocation = value);

    this.isRenderingOneTileOnly = this.totalTiles - this.hideTiles.length < 2;

    // Set telemetry
    this.telemetryService.inject('CLICK', 'program-links-camps', this.router.url, true);
    this.telemetryService.inject('CLICK', 'program-links-coders-ladder', this.router.url, true);
    this.telemetryService.inject('CLICK', 'program-links-enrichment', this.router.url, true);
  }

  staticPrice4ClassType4Currency(classType: string): [number, number] {
    const currency = this.geolocation ? this.geolocation.currency : 'USD';
    return this.specialPageSharingService.staticPrice4ClassType4Currency(classType, currency, null);
  }
}

