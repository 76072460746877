import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OnlineEnrichmentRegistrationsListDataService {

  constructor(
    private http: HttpClient
  ) { }

  private urlOnlineEnrichment = environment.urlWebServices + '/cwk/online-enrichment';
  private urlPathway = environment.urlWebServices + '/api/pathway';

  readPathways(currency: string): Observable<any> {
    return this.http.get<any>(`${this.urlPathway}/readAll/${currency}`)
      .pipe(
        map(this.fixPathwaysFromMySQL),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  readRegistrations(partnerId: number, status: string, currency: string, discountCode: string, isAdultClasses: boolean, limitLocalityId: string): Observable<any> {
    const getURL = `${this.urlOnlineEnrichment}/readRegistrations/${partnerId}/${status}/${currency}`;

    // Pass optional data into params
    const params = new HttpParams()
      .set('discountCode', discountCode)
      .set('isAdultClasses', isAdultClasses ? '1' : '0')
      .set('limitLocalityId', limitLocalityId);

    // Call web service
    return this.http.get<any>(getURL, { params: params })
      .pipe(
        map(this.fixRegistrationsFromMySQL),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  private fixPathwaysFromMySQL(response) {

    const result = structuredClone(response);

    result.forEach(data => {

      // Fix booleans
      data.HasASRegistrations = !!data.HasASRegistrations;

      // Parse classes
      data.Classes = data.Classes ? JSON.parse(data.Classes) : [];
      data.Classes.forEach(_class => {
        _class.IsComingNext = !_class.Description;
        _class.IsOfferingEnrichment = !! _class.IsOfferingEnrichment;
      });

      // Parse descriptions
      data.Descriptions = data.Descriptions ? JSON.parse(data.Descriptions) : [];
    });

    return result;
  }

  private fixRegistrationsFromMySQL(response) {

    const data = structuredClone(response);

    data.Configuration.forEach(configuration => {
      switch (configuration.KeyName) {
        case 'ONLINE-ENRICHMENT-LAUNCH-TIMESTAMP':
          configuration.Value = configuration.Value && (typeof configuration.Value === 'string') && new Date(configuration.Value.replace(/ /g, 'T') + 'Z');
          break;

        // Fix other configuration items as needed...
      }
    });

    data.Classes.forEach(_class => {

      // Parse pathways
      _class.Pathways = _class.Pathways ? JSON.parse(_class.Pathways) : [];

      // Parse sessions
      _class.Sessions = _class.Sessions ? JSON.parse(_class.Sessions) : [];
      _class.Sessions.forEach(session => {

        // Parse discounts
        session.Discounts = session.Discounts ? JSON.parse(session.Discounts) : [];
        session.Discounts.forEach(discount => {

          // Booleans
          discount.IsExclusive = !!discount.IsExclusive;

          // Dates
          discount.ExpiresOn = discount.ExpiresOn && new Date(discount.ExpiresOn.replace(/ /g, 'T') + 'Z');
        });

        // Fix booleans
        session.HasEnded = !!session.HasEnded;
        session.IsExternalRegistration = !!session.IsExternalRegistration;
        session.IsOnline = !!session.IsOnline;
        session.IsPast = !!session.IsPast;

        // Fix dates and times
        session.EndUTC = session.EndUTC && new Date(session.EndUTC.replace(/ /g, 'T') + 'Z');
        session.StartUTC = session.StartUTC && new Date(session.StartUTC.replace(/ /g, 'T') + 'Z');
        session.SessionStartUTC = session.SessionStartUTC && new Date(session.SessionStartUTC.replace(/ /g, 'T') + 'Z');
        session.SessionEndUTC = session.SessionEndUTC && new Date(session.SessionEndUTC.replace(/ /g, 'T') + 'Z');
      });
    });

    return data;
  }
}
