import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

// Icons
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-customer-testimonial-item',
  templateUrl: './customer-testimonial-item.component.html',
  styleUrls: ['./customer-testimonial-item.component.scss'],
  standalone: false
})
export class CustomerTestimonialItemComponent implements AfterViewInit, OnInit {

  @Input() author: string;
  @Input() date: Date;
  @Input() limitLines: number; // When not set, no limit will be applied
  @Input() review: string;
  @Input() stars: number;

  @ViewChild('reviewBody', {static: true}) reviewRef: ElementRef;

  isReviewClamped = false;
  showMoreReview = false;

  faQuoteLeft = faQuoteLeft;
  faQuoteRight = faQuoteRight;

  constructor() { }

  ngOnInit() {

    // Fix the stars. Default to 5 stars if not set
    this.stars = (this.stars || 5) * 2;
  }

  ngAfterViewInit() {

    // Wait one cycle to prevent ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(_ => this.setIsClamped(), 0);
  }

  switchShowMoreReview() {
    this.showMoreReview = !this.showMoreReview;
  }

  setIsClamped() {

    // Check to see if the review is clamped
    const el: HTMLElement = this.reviewRef.nativeElement;
    this.isReviewClamped = el.scrollHeight > el.clientHeight;
  }
}
