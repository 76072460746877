<div class="d-flex flex-row justify-content-center">
  <div class="d-flex flex-column align-items-center justify-content-center search-group w-100">
    <div class="input-group">
      <input class="form-control form-control-sm py-2 border-end-0 border shadow-none" name="address" type="text" placeholder="Search by address, city, or ZIP..." [(ngModel)]="address" (keydown.enter)="addressChanged()">
      <button class="btn btn-md yellow-button shadow-none" type="button" (click)="addressChanged()">
        <fa-icon [icon]="faSearch"></fa-icon>
      </button>
    </div>
  </div>
  <!--div class="d-flex flex-row justify-content-between ms-5" aria-label="Show/hide half- and full-day camps locations" *ngIf="isCamp">
    <button class="btn btn-sm camp-btn py-2 shadow-none" (click)="isHalfDayChanged()" [ngClass]="{'yellow-button': isHalfDay, 'grey-button': !isHalfDay}" type="button">HALF-DAY</button>
    <button class="btn btn-sm camp-btn ms-3 py-2 shadow-none" (click)="isFullDayChanged()" [ngClass]="{'yellow-button': isFullDay, 'grey-button': !isFullDay}" type="button">FULL-DAY</button>
  </div-->
</div>
