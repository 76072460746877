<div *ngIf="isReading && !errorReading" class="my-4 cwk-loading">
  <fa-icon class="me-2" [icon]="faSpinner" animation="spin"></fa-icon>Loading, please wait...
</div>
<div #top [hidden]="isReading" (click)="clearClassBorderHighlights()">

  <div *ngIf="isLaunchMessageShown" class="launch-message-banner">
    <fa-icon [icon]="faCalendarPlus" class="me-3"></fa-icon>Additional classes will be available for registration on {{ launchDate | date:'L/d' }} at {{ launchDate | date:'shortTime' }} ({{ timezoneAbbr }})
  </div>

  <div #header id="header" class="header sticky-top">

    <div [hidden]="isFiltersHidden">
      <div class="cwk-orange-bg cwk-white px-3 py-2" *ngIf="!limitLocality">
        <div class="d-block d-lg-none step-label">Select Age &amp; Pathway</div>
        <div class="d-none d-lg-block step-label"><b>Step 1:</b> Select Age &amp; Pathway</div>
      </div>
      <div class="cwk-orange-bg cwk-white px-3 py-2" *ngIf="limitLocality">
        <div class="d-block d-lg-none step-label">Select Grade &amp; Pathway</div>
        <div class="d-none d-lg-block step-label"><b>Step 1:</b> Select Grade &amp; Pathway</div>
      </div>

      <!-- Age filter -->
      <div class="fix-row py-3" *ngIf="!limitLocality">
        <div *ngFor="let pathwayAge of pathwayAges" class="col">
          <div id="{{ 'age-switched-' + pathwayAge.ageMin + (pathwayAge.ageMax ? ('-' + pathwayAge.ageMax) : '+') }}" class="age-btn" [ngClass]="{'selected' : pathwayFilterModel?.ageUid === pathwayAge.uid}" (click)="switchAgeFilter(pathwayAge)">
            {{ pathwayAge.ageMin }}{{ pathwayAge.ageMax ? ('-' + pathwayAge.ageMax) : '+' }}
          </div>
        </div>
      </div>

      <!-- Grade filter -->
      <div class="fix-row py-3" *ngIf="limitLocality">
        <div *ngFor="let pathwayAge of pathwayAges" class="col">
          <div id="{{ 'age-switched-' + pathwayAge.ageMin + (pathwayAge.ageMax ? ('-' + pathwayAge.ageMax) : '+') }}" class="age-btn" [ngClass]="{'selected' : pathwayFilterModel?.ageUid === pathwayAge.uid}" (click)="switchAgeFilter(pathwayAge)">
            {{ pathwayAge.gradeMin }}{{ pathwayAge.gradeMax ? ('-' + pathwayAge.gradeMax) : '+' }}
          </div>
        </div>
      </div>

      <!-- Pathway filter -->
      <div class="fix-row mb-2 mb-lg-0 g-0">
        <div id="{{ 'pathway-switched-' + pathway.Name }}" class="col-2 col-md text-center cwk-pointer" *ngFor="let pathway of pathways" [ngClass]="{'not-allowed' : pathway.IsDisabled}" (click)="switchPathwayFilter(pathway)">
          <div class="pathway-icon">
            <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathways/{{ pathway.IsDisabled ? pathway.IconFileDisabled : (pathwayFilterModel?.pathwayId === pathway.PathwayId ? pathway.IconFileSelected : pathway.IconFile)}}" alt="{{ pathway.Name }} icon" loading="lazy" class="img-fluid"/>
          </div>
          <div class="pathway-name">{{ pathway.Name }}</div>
        </div>
      </div>

      <!-- Only open classes filter (only for DESKTOP!) -->
      <div class="d-none d-lg-block my-3 cwk-orange-bg cwk-white px-3 py-2">
        <div class="mb-2 d-flex justify-content-between align-items-baseline">
          <div class="step-label"><b>Step 2:</b> Pick Your Course</div>
          <div class="d-flex">
            <div (click)="setOpenClassesOnly(true)" class="cwk-pointer d-flex align-items-center me-3">
              <fa-icon class="me-2" [icon]="isOpenClassesOnly ? faDotCircle : faCircleO" size="lg"></fa-icon>
              <span>Open Classes Only</span>
            </div>
            <div (click)="setOpenClassesOnly(false)" class="cwk-pointer d-flex align-items-center">
              <fa-icon class="me-2" [icon]="isOpenClassesOnly ? faCircleO : faDotCircle" size="lg"></fa-icon>
              <span>All Classes</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- School year xxxx-xxxx for in-person -->
    <div *ngIf="limitLocality && schoolYear" class="fix-row">
      <div class="col-md-12 col-lg-4 d-none d-lg-block"></div>
      <div class="col-lg-8 d-none d-lg-block">
        <h4 class="cwk-orange text-center fw-bold mt-3 mb-2">School Year {{ schoolYear }}</h4>
      </div>
    </div>

    <div class="fix-row">
      <div class="col-md-12 col-lg-4 d-none d-lg-block registrations-bg-dark-grey">
        <div class="h-100 d-flex flex-column justify-content-center align-items-center">

          <!-- Location name for single locations -->
          <div *ngIf="limitLocality?.Name; else filterDisplay" class="text-center" style="font-size: 21px;"><b>{{ limitLocality?.Name }}</b></div>

          <!-- Age / grade and pathways for online -->
          <ng-template #filterDisplay>
            <div id="pathway-details" class="text-center" style="font-size: 18px;" (click)="openPathwayDetails()">
              <b [ngClass]="{'cwk-blue-link underline' : pathwayFilterModel.pathwayId}">{{ pathwayFilterModel.displayText }}</b>
              <fa-icon *ngIf="pathwayFilterModel.pathwayId" class="ms-2" style="width: 27px; height: 27px;" [icon]="faInfoCircle" [fixedWidth]="true"></fa-icon>
            </div>
          </ng-template>
          <div class="mt-2 cwk-orange">All times in <b>{{ timezoneAbbr }}</b></div>
        </div>
      </div>
      <div class="col-lg-8 d-none d-lg-block">
        <div class="calendar-heading disable-text-selection h-100 registrations-bg-dark-grey">
          <div class="d-flex flex-row justify-content-between align-items-center p-2">

            <!-- Previous week button -->
            <div id="calendar-reverse" class="d-flex align-items-center w-25 cwk-pointer" [ngClass]="{ 'disabled' : !weekReverseActive() }" (click)="!weekReverseActive() || moveWeek('REVERSE')">
              <fa-icon [icon]="faChevronLeft" class="arrow left me-2" size="2x"></fa-icon>
              <div class="fw-bold">Previous classes</div>
            </div>

            <!-- Calendar title -->
            <div class="unit-title" *ngIf="weeks.length">
              <div class="classes-starting">Classes starting</div>
              <div>Week of {{ weeks[selectedWeek].monday | date:'M/dd' }}</div>
            </div>

            <!-- Next week button -->
            <div id="calendar-forward" class="d-flex align-items-center justify-content-end w-25 cwk-pointer" [ngClass]="{ 'disabled' : !weekForwardActive() }" (click)="!weekForwardActive() || moveWeek('FORWARD')">
              <div class="fw-bold">Next classes</div>
              <fa-icon [icon]="faChevronRight" class="arrow right ms-2" size="2x"></fa-icon>
            </div>
          </div>

          <!-- Weekday names -->
          <div class="d-flex justify-content-between">
            <div *ngFor="let weekday of weekdays" class="week-header">
              {{ weekday }}s
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngFor="let _class of classes2Show; first as isFirst" class="fix-row" [ngClass]="{'my-0 my-lg-3' : isFirst, 'my-3' : !isFirst}" [@slideInOut]>

    <!-- Left side of table -->
    <div class="col-lg-4">

      <!-- Class info (DESKTOP)  -->
      <div class="d-none d-lg-block" [ngClass]="{'highlight-class':_class.BorderHighlight}">
        <div id="{{ 'open-class-details-' + _class.LevelCode }}" class="d-flex flex-row class-container" (click)="openClassDetails(_class)">

          <!-- Class image -->
          <div class="d-flex flex-column">
            <img class="ROW-HEIGHT-IMG" alt="{{ _class.ClassName }} image" src="https://d3t4xfu733v2tb.cloudfront.net/classes/{{ _class.ImagePath }}" loading="lazy" />
          </div>

          <!-- Class details -->
          <div class="d-flex flex-column justify-content-between w-100 p-1">
            <div class="ms-2 me-2">
              <div class="fw-bold"><span class="new" *ngIf="_class.IsNew">*NEW* </span>{{ _class.ClassName }}</div>
              <div [hidden]="isAdultClasses" class="ages">
                <ng-container *ngIf="!limitLocality">{{ 'Ages ' + _class.AgeMin + (_class.AgeMax ? ('-' + _class.AgeMax) : '+') }}</ng-container>
                <ng-container *ngIf="limitLocality">{{ 'Grades ' + _class.GradeRange }}</ng-container>
              </div>
            </div>

            <!-- Lower half, pathway skill. If the class belongs to multiple pathways it is assumed they belong to the same skill -->
            <div [hidden]="isAdultClasses" class="lower-half ms-1 mb-1">
              <div *ngFor="let pathway of _class.Pathways | slice:0:1" class="skill-container BACKGROUND-{{ pathway.Skill }}">
                {{ pathway.Skill }}
              </div>
            </div>
          </div>

          <div class="d-flex flex-column justify-content-between align-items-end text-center mx-1 my-1">
            <fa-icon style="width: 27px; height: 27px;" [icon]="faInfoCircle" [fixedWidth]="true"></fa-icon>
            <div class="d-flex">
              <div *ngFor="let pathway of _class.Pathways; last as isLast" style="width: 27px; height: 27px;" [ngClass]="{ 'me-2' : !isLast }" ngbPopover="{{ pathway.Name }}" triggers="mouseenter:mouseleave">
                <img src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathways/{{ pathway.IconFile }}" alt="{{ pathway.Name }} icon" loading="lazy" class="img-fluid"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Class info (MOBILE) -->
      <div class="d-lg-none">
        <div class="d-flex flex-row class-container" (click)="classClicked(_class)">

          <!-- Class image -->
          <div class="d-flex flex-column">
            <img class="ROW-HEIGHT-IMG" alt="{{ _class.ClassName }} image" src="https://d3t4xfu733v2tb.cloudfront.net/classes/{{ _class.ImagePath }}" loading="lazy" />
          </div>

          <!-- Class details -->
          <div class="d-flex flex-column justify-content-between w-100 p-1">
            <div class="ms-2 me-2">
              <div class="fw-bold">{{ _class.ClassName }}</div>
              <div [hidden]="isAdultClasses" class="ages">
                <ng-container *ngIf="!limitLocality">{{ 'Ages ' + _class.AgeMin + (_class.AgeMax ? ('-' + _class.AgeMax) : '+') }}</ng-container>
                <ng-container *ngIf="limitLocality">{{ 'Grades ' + _class.GradeMin + (_class.GradeMax ? ('-' + _class.GradeMax) : '+') }}</ng-container>
              </div>
            </div>

            <!-- Lower half, pathway skill. If the class belongs to multiple pathways it is assumed they belong to the same skill -->
            <div [hidden]="isAdultClasses" class="lower-half ms-1 mb-1">
              <div *ngFor="let pathway of _class.Pathways | slice:0:1" class="skill-container BACKGROUND-{{ pathway.Skill }}">
                {{ pathway.Skill }}
              </div>
            </div>
          </div>

          <div class="d-flex flex-column justify-content-between align-items-end text-center mx-1 my-1">
            <div>
              <div *ngFor="let pathway of _class.Pathways; last as isLast" style="width: 20px; height: 20px;" [ngClass]="{ 'me-2' : !isLast }" ngbPopover="{{ pathway.Name }}" triggers="mouseenter:mouseleave">
                <img src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathways/{{ pathway.IconFile }}" alt="{{ pathway.Name }} icon" loading="lazy" class="img-fluid"/>
              </div>
            </div>
            <!--fa-icon style="width: 20px; height: 20px;" [icon]="faInfoCircle" [fixedWidth]="true"></fa-icon-->
            <fa-icon class="d-lg-none" [icon]="_class.isExpanded ? faMinusCircle : faPlusCircle" size="lg"></fa-icon>
          </div>
        </div>

        <!-- When expanded show class description, experience and prerequisites -->
        <div *ngIf="_class.isExpanded" class="d-lg-none" [@slideInOut]>

          <div class="border-bottom">
            <div class="d-flex justify-content-center my-2" (click)="_class.isDetailsOpened = !_class.isDetailsOpened">
              Course description
              <fa-icon class="ms-2" [icon]="_class.isDetailsOpened ? faMinusCircle : faPlusCircle"></fa-icon>
            </div>
          </div>

          <div *ngIf="_class.isDetailsOpened" class="p-2" [@slideInOut]>
            <div *ngIf="_class.Tools" class="mb-1"><b>Languages / tools:</b>&nbsp;<span [innerHTML]="_class.Tools"></span></div>
            <div *ngIf="_class.Experience" class="mb-1"><b>Coder's Ladder Experience:</b>&nbsp;<span [innerHTML]="_class.Experience"></span></div>
            <div><b>Prerequisites:</b>&nbsp;<span *ngIf="!_class.Prerequisity">None</span></div>
            <span *ngIf="_class.Prerequisity" [innerHTML]="_class.Prerequisity"></span>
            <div class="mini-orange-bar mx-auto my-4"></div>
            <div *ngIf="_class.Description" class="text-justify mt-3" [innerHTML]="_class.Description"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right side of table -->
    <div class="col-lg-8">

      <!-- AS CLASSES (desktop) -->
      <div class="d-none d-lg-block">
        <div class="d-flex flex-row justify-content-between sessions-container">
          <div *ngFor="let sessions of _class.sessionsByWeek[selectedWeek]; let weekDayIndex = index" class="d-flex flex-column w-100 mx-1" [@slideInOut]>
            <div *ngFor="let session of sessions; first as isFirst" class="d-flex flex-column item-session" [ngClass]="{'mt-2' : !isFirst}">

              <!-- Stack effect -->
              <div class="stack-container">
                <div *ngIf="session.NumberStacked" class="relative-container">
                  <img alt="Stacked classes" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/registrations/session-stack-icon{{ session.NumberOccupied ? '-popular' : '' }}.png" class="img-fluid" loading="lazy"/>
                  <div *ngIf="session.NumberOccupied" class="stacked-text">
                    <span class="inner">POPULAR</span>
                  </div>
                </div>
              </div>

              <!-- Tile content -->
              <div id="{{ 'session-' + session.LessonId }}" tm-destination="/checkout" class="d-flex flex-column align-items-center justify-content-between session-content" (click)="goSignup(session)" [ngClass]="{'popular' : session.NumberOccupied, 'full' : session.Occupied, 'ended' : session.HasEnded}" [ngbPopover]="session.Discounts.length ? popDiscounts : null" container="body" triggers="mouseenter:mouseleave">

                <!-- Times -->
                <div class="time item">
                  {{ session.StartUTC | date:'h:mm' }}-{{ session.EndUTC | date:'h:mm aa' }}
                </div>

                <!-- Price -->
                <div class="price item">
                  <span *ngIf="!session.Discounts.length">
                    {{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}<sub>/{{ session.Price4ClassSessions }}wks</sub>
                  </span>
                  <span *ngIf="session.Discounts.length">
                    <span class="non-discounted">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</span>
                    &#32;{{ (session.FinalPrice | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}/{{ session.Price4ClassSessions }}wks
                  </span>
                </div>

                <!-- Occupied / last spots -->
                <div class="item">
                  <ng-container *ngTemplateOutlet="spotsLeftTemplate; context: { session: session }"></ng-container>
                </div>

                <!-- Waitlist or Register text -->
                <div class="register item">
                  <div class="d-flex justify-content-center">
                    {{ session.IsPast ? 'ENDED' : (session.HasEnded ? 'IN-PROGRESS' : (session.Occupied ? 'Waitlist' : 'Register')) }}

                    <!-- If the session is still available for registration show the online/external icons -->
                    <div [hidden]="session.HasEnded">
                      <span *ngIf="limitLocality && session.IsOnline" class="online-icon" ngbPopover="This class is taught online" container="body" triggers="mouseenter:mouseleave">O</span>
                      <fa-icon *ngIf="session.IsExternalRegistration" [icon]="faExternalLinkAlt" class="ms-2" ngbPopover="Registration handled externally" container="body" triggers="mouseenter:mouseleave"></fa-icon>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Popover for discounts -->
              <ng-template #popDiscounts>
                <div *ngFor="let discount of session.Discounts">
                  {{ discount.Description }}{{ discount.ExpiresOn ? ' expires on ' + (discount.ExpiresOn | date:'shortDate') + ' at ' + (discount.ExpiresOn | date:'shortTime') + ' (' + timezoneAbbr + ')' : '' }}
                </div>
              </ng-template>
            </div>

            <!-- No classes for this selected week. Show 'not currently open' or when the next future class is -->
            <div *ngIf="!sessions.length">

              <!-- No current class, no future class -->
              <ng-container *ngIf="!_class.sessionsByWeekFuture[selectedWeek][weekDayIndex].length; else futureClass">
                <div class="d-flex flex-column align-items-center justify-content-between more-coming-soon disabled">
                  <div class="top-bar">&nbsp;</div>
                  <div class="item">NOT</div>
                  <div class="item">CURRENTLY</div>
                  <div class="item">OPEN</div>
                </div>
              </ng-container>

              <!-- There is a future class, show it! -->
              <ng-template #futureClass>
                <div id="{{ 'next-classes-' + _class.LevelCode }}" class="d-flex flex-column align-items-center justify-content-between more-coming-soon session-content" (click)="openFutureSessionsDialog(_class, _class.sessionsByWeekFuture[selectedWeek][weekDayIndex])">
                  <div class="top-bar strong">UPCOMING</div>
                  <div class="item strong">Next Class</div>
                  <div class="item strong">Starts {{ _class.sessionsByWeekFuture[selectedWeek][weekDayIndex][0].SessionStartUTC | date:'M/d' }}</div>
                  <div class="item strong" style="color: black;"><b>View all</b></div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <!-- AS CLASSES sessions (mobile) -->
      <div *ngIf="_class.isExpanded" class="d-lg-none" [@slideInOut]>
        <div *ngFor="let sessions of _class.SessionsByDay; index as i" class="sessions-container">
          <div *ngFor="let session of sessions; first as isFirst" (click)="goSignup(session)">
            <div *ngIf="isFirst" class="mobile-weekday-header d-flex justify-content-between align-items-center">
              <span>{{ weekdays[i] }} Classes:</span>
              <small>All times in {{ timezoneAbbr }}</small>
            </div>

            <div class="cwk-pointer d-flex flex-row justify-content-between align-items-center border-bottom item-session p-2 flex-wrap">

              <!-- Price and spots -->
              <div class="text-center">
                <div class="price">{{ session.Price4ClassSessions }} weeks</div>
                <div class="price">
                  <div *ngIf="!session.Discounts.length">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</div>
                  <div *ngIf="session.Discounts.length">
                    <div class="non-discounted">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</div>
                    &#32;{{ (session.FinalPrice | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}
                  </div>
                </div>
              </div>

              <!-- Date and time -->
              <div class="text-center" style="width: 150px;">
                <div>{{ session.StartUTC | date:'h:mm' }} - {{ session.EndUTC | date:'h:mm aa' }}</div>
                <div>Starts week of {{ session.StartUTC | date:'M/d' }}</div>
                <div *ngIf="session.NumberOccupied" class="number-occupied-mobile">POPULAR TIME</div>
              </div>

              <!-- Waitlist/register & spots left -->
              <div class="d-flex align-items-center">
                <div class="text-center" style="width: 65px;">
                  <div class="weeks">
                    {{ session.Occupied ? 'WAITLIST' : 'REGISTER' }}
                    <fa-icon *ngIf="session.IsExternalRegistration" [icon]="faExternalLinkAlt" class="ms-2" ngbPopover="Registration handled externally" container="body" triggers="mouseenter:mouseleave"></fa-icon>
                  </div>
                  <ng-container *ngTemplateOutlet="spotsLeftTemplate; context: { session: session }"></ng-container>
                </div>

                <!-- Arrow -->
                <fa-icon class="ms-2" [icon]="faChevronRight" size="lg"></fa-icon>
              </div>
            </div>
          </div>
        </div>

        <!-- No sessions for any weekday -->
        <div *ngIf="!_class.HasAnySessionsMobile" class="more-coming-soon-mobile">
          <div class="item fw-bold">NO REGISTATIONS CURRENTLY AVAILABLE</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Disclaimer message for in-person -->
  <div *ngIf="limitLocality && classes2Show.length" class="text-center">All classes will have one instructor in the classroom and may have another instructor who would join either in the classroom or online through a videoconference.</div>

  <!-- No classes to show (online variation) -->
  <div *ngIf="!classes2Show.length && status && !limitLocality" class="mt-3 text-center alert alert-warning" role="alert">
    <h3 class="my-0">Could not find any classes for the current selection</h3>
    <h3 class="my-0">Adjust filters to view other options</h3>
  </div>

  <!-- No classes to show (location variation) -->
  <div *ngIf="!classes2Show.length && status && limitLocality" class="mt-3 text-center alert alert-warning" role="alert">
    <h3 class="my-0"><b>No classes are currently running at {{ limitLocality.Name }}</b></h3>
    <h3 class="my-0">Check out our online alternatives below!</h3>
  </div>
</div>

<!-- Spots left template -->
<ng-template #spotsLeftTemplate let-session="session">
  <div *ngIf="session.SpotsLeft <= 0" class="spots-left">FULL</div>
  <div *ngIf="session.NumberOccupied && session.SpotsLeft > 3" class="spots-left">POPULAR</div>
  <div *ngIf="session.SpotsLeft <= 3 && session.SpotsLeft > 0" class="spots-left">{{ session.SpotsLeft }} spot{{ session.SpotsLeft === 1 ? '' : 's' }} left!</div>
</ng-template>

<!-- Error -->
<cwk-error-alert *ngIf="errorReading" [error]="errorReading"></cwk-error-alert>
