
<!-- Loading -->
<div *ngIf="isReading" class="subscription-container">
  <div class="loading mt-3 mb-4">
    <fa-icon class="me-2" [icon]="faSpinner" animation="spin"></fa-icon>Loading...
  </div>
</div>

<div *ngIf="!isReading" class="d-flex flex-column h-100 justify-content-between">

  <!-- RECOMMENDED bar -->
  <div *ngIf="isRecommended" class="recommended-on">RECOMMENDED</div>
  <div *ngIf="!isRecommended" class="recommended-off">&emsp;</div>

  <div class="subscription-container">

    <!-- Once we're done reading with no error, show the Subscription details -->
    <div *ngIf="!isReading && !errorReading" class="d-flex flex-column h-100 justify-content-between" [@slideInOut]>
      <div>

        <!-- Name -->
        <h3 class="cwk-orange">{{ subscription.Name }}</h3>
        <div class="my-3 mini-orange-bar"></div>

        <!-- Subscription parameter display -->
        <div class="mb-3">
          <div *ngFor="let subscriptionParameter of subscription.SubscriptionParameters" class="mb-2">
            <span *ngIf="subscriptionParameter.DisplayText" [innerHTML]="subscriptionParameter.DisplayText"></span>
          </div>
          <!--li *ngFor="let discount of subscription.Discounts">
            <span *ngIf="discount.Code === 'SIBLING'">
               {{ discount.Discount }}{{ discount.Unit === 'PERCENTAGE' ? '%' : '' }} Sibling Discount on subscriptions for additional students
            </span>
            <span *ngIf="discount.Code !== 'SIBLING'">
              {{ discount.Description }} available
            </span>
          </li-->
        </div>
      </div>

      <!-- Button to purchase subscription -->
      <div class="mt-5">

        <!-- Price -->
        <div class="price">
          {{ subscription.Price | currency: currency : 'symbol' : '1.0-0' }}&nbsp;
          <sub class="reg-price">(Reg. {{ subscription.RegularPrice | currency: currency : 'symbol' : '1.0-0' }})</sub>
        </div>

        <!-- Alert if specified -->
        <div class="alert alert-danger" [innerHtml]="subscription.Message" *ngIf="subscription.Message"></div>

        <div class="text-center">
          <a class="w-100 cwk-link link-btn" [href]="'/checkout?c=' + currency + '&u=' + subscription.SubscriptionId">Buy Now</a>
        </div>
      </div>
    </div>

    <!-- Error -->
    <cwk-error-alert *ngIf="errorReading" [error]="errorReading"></cwk-error-alert>
  </div>
</div>
