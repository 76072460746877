import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Constants } from '../../classes/constants';
import { InPersonRegistrationsSharingService } from '../../services/in-person-registrations-sharing.service';
import { Location } from '../../interfaces/location';

// Icons
import { faCheck, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

// Animations
import { slideInOut } from '@shared/animations/slide-in-out';

@Component({
  animations: [slideInOut],
  selector: 'home-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss'],
  standalone: false
})
export class LocationsListComponent implements OnInit {

  @Input() classType: string;
  @Input() isSingleLocation = false;

  locations: Location[] = [];
  locationsSelected: Location[] = [];

  // Icons
  faCheck = faCheck;
  faCircleCheck = faCircleCheck;
  faCircleXmark = faCircleXmark;

  constructor(
    public constants: Constants,
    @Inject(DOCUMENT) private document: Document,
    private inPersonRegistrationsSharingService: InPersonRegistrationsSharingService,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngOnInit() {

    // Watch the selected locations
    this.inPersonRegistrationsSharingService.getLocationsSelected()
      .subscribe((locations: Location[]) => {
        this.locationsSelected = locations;

        // Scroll the locations into view
        if (this.isSingleLocation && this.locationsSelected) {
          this.scrollLocationsIntoView();
        }
      });

    // Watch the locations
    this.inPersonRegistrationsSharingService.getLocationsVisible()
      .subscribe((locations: Location[]) => {
        this.locations = locations;

        // Unselect invisible locations
        this.unselectInvisible();
      });
  }

  private scrollLocationsIntoView() {
    if (this.isSingleLocation && this.locationsSelected.length && isPlatformBrowser(this.platformId)) {
      const location2Scroll = this.document.getElementById('l' + this.locationsSelected[0].LocalityId);
      if (location2Scroll) {
        location2Scroll.scrollIntoView({ behavior: 'smooth', block: 'nearest'});
      }
    }
  }

  updateSelectedLocation(location: Location) {

    const index = this.locationsSelected.findIndex(loc => loc.LocalityId === location.LocalityId);
    if (index > -1) {

      // Unselect
      location.IsSelected = false;

      // Remove found location from selected
      this.locationsSelected.splice(index, 1);
    } else {

      // Select
      location.IsSelected = true;

      // Add location to selected
      if (this.isSingleLocation) {

        // Unselect the previously selected location
        if (this.locationsSelected.length) {
          this.locationsSelected[0].IsSelected = false;
        }

        this.locationsSelected = [ location ];
      } else {
        this.locationsSelected.push(location);
      }
    }

    this.inPersonRegistrationsSharingService.updateLocationsSelected(this.locationsSelected);
  }

  private unselectInvisible() {

    // Unselect invisible
    this.locationsSelected.filter(location => !location.IsVisible).forEach(location => location.IsSelected = false);

    // Update selected locations
    const locationsSelectedNew = this.locationsSelected.filter(location => location.IsSelected);

    // Update only if the selection changed
    if (this.locationsSelected.length > locationsSelectedNew.length) {
      this.locationsSelected = locationsSelectedNew;
      this.inPersonRegistrationsSharingService.updateLocationsSelected(this.locationsSelected);
    }
  }
}
