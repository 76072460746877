import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CodersLadderDataService {

  constructor(
    private http: HttpClient
  ) { }

  private urlCodersLadder = environment.urlWebServices + '/api/codersLadder';

  read(currency: string, isPrivateClasses: number): Observable<any> {
    return this.http.get<any>(`${this.urlCodersLadder}/classes/${currency}/${isPrivateClasses}`)
      .pipe(
        tap(response => {
          response.forEach(this.fixFromMySQL);
        }),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  private fixFromMySQL(_class) {

    // Fix booleans
    _class.IsSignupAvailable = !!_class.IsSignupAvailable;
  }
}
