import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ClassInterest } from './interfaces/class-interest';
import { ClassInterestFormDataService } from './services/class-interest-form-data.service';
import { Countries } from '@shared/classes/countries';
import { GeolocationService } from '@shared/services/geolocation.service';
import { Observable, Subscription } from 'rxjs';
import { USStates } from '@shared/classes/usstates';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

// Icons
import { faCheck, faCheckSquare, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faSquare as faSquareO } from '@fortawesome/free-regular-svg-icons';

// Animations
import { slideInOut } from '@shared/animations/slide-in-out';

@Component({
  animations: [slideInOut],
  selector: 'home-class-interest-form',
  templateUrl: './class-interest-form.component.html',
  styleUrls: ['./class-interest-form.component.scss'],
  standalone: false
})
export class ClassInterestFormComponent implements OnDestroy, OnInit {

  @Input() preCheckInterestedIn: string[] = [];

  additonalInfoSubmitted = false;
  classInterest: ClassInterest = null;

  // Convert associated array of countries to array for easier processing in typeahead
  countries = Object.keys(Countries.countries).map(k => {
    return {
      Code: k,
      Name: Countries.countries[k].Name
    };
  });
  errorSaving = '';
  interestedInSwitches: any[] = [];
  isSaving = false;
  selectedCountry = null;
  states = USStates.states;

  // Icons
  faCheck = faCheck;
  faCheckSquare = faCheckSquare;
  faSpinner = faSpinner;
  faSquareO = faSquareO;

  // Subscriptions
  private geolocationSubscription: Subscription;

  constructor(
    private classInterestFormDataService: ClassInterestFormDataService,
    private geolocationService: GeolocationService
  ) { }

  ngOnDestroy() {

    // Unsubscribe
    if (this.geolocationSubscription) {
      this.geolocationSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.classInterest = this.initClassInterest();

    this.interestedInSwitches.push({
      code: 'AFTER-SCHOOL',
      display: 'After-school',
      isSelected: this.preCheckInterestedIn.includes('AFTER-SCHOOL')
    });

    this.interestedInSwitches.push({
      code: 'DURING-SCHOOL',
      display: 'During-school',
      isSelected: this.preCheckInterestedIn.includes('DURING-SCHOOL')
    });

    // Get current geolocation info
    this.geolocationSubscription = this.geolocationService.getObservable()
      .subscribe(
        (value) => this.processGeolocation(value));
  }

  private initClassInterest(): ClassInterest {
    const classInterest: ClassInterest = {
      City: null,
      ClassInterestId: null,
      Comment: null,
      Country: null,
      Email: null,
      FirstName: null,
      Grades: null,
      InterestedIn: null,
      LastName: null,
      PotentialStart: null,
      SchoolName: null,
      State: null,
      ZIP: null
    };
    return classInterest;
  }

  formatCountry = (result: any) => result.Name;

  // Search country
  searchCountry = (text: Observable<string>) =>
    text
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(term => this.countries.filter(v =>
            v.Code.toLowerCase().indexOf(term.toLowerCase()) > -1
          ).concat(
            this.countries.filter(v =>
                v.Code.toLowerCase().indexOf(term.toLowerCase()) === -1 &&
                v.Name.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
          )
        )
      )

  // User selected a country
  setCountry(event) {

    // Store the selected country code
    this.classInterest.Country = event.item.Code;

    // If country is not US, states don't make sense
    this.classInterest.State = (this.classInterest.Country !== 'US') ? '-' : null;
  }

  create() {

    // Show spinner
    this.isSaving = true;

    // Reset the status
    this.errorSaving = '';

    // Prepare InterestedIn set
    const selectedInterestedIn = this.interestedInSwitches.filter(s => s.isSelected).map(s => s.code);
    this.classInterest.InterestedIn = selectedInterestedIn.length ? selectedInterestedIn.join() : null;

    // Create account
    this.classInterestFormDataService.create(this.classInterest)
      .subscribe(
        (value) => {

            if (this.classInterest.ClassInterestId) {
              this.additonalInfoSubmitted = true;
            } else {

              // Set the ClassInterestId
              this.classInterest.ClassInterestId = value.ClassInterestId;
            }

            // Hide spinner
            this.isSaving = false;
          },
        (error) => {

            // Set the error
            this.errorSaving = error;

            // Hide spinner
            this.isSaving = false;
        }
      );
  }

  private processGeolocation(geolocation: any) {

    // See if the user's country is in our list, if so, set it (and State if US)
    const country = this.countries.find(c => c.Code === geolocation.country_code);
    if (country) {

      // The user's country is in our set of countries
      this.classInterest.Country = country.Code;
      this.selectedCountry = country;

      // For US countries, set the state
      if (this.classInterest.Country === 'US') {
         this.classInterest.State = geolocation.region_code;
      }
    }

    // Set City
    this.classInterest.City = geolocation.city;

    // Set ZIP
    this.classInterest.ZIP = geolocation.zip_code;
  }
}
