import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { CookieConsentCompliance } from './enums/cookie-consent-compliance';
import { CookieConsentData } from './interfaces/cookie-consent-data';
import { CookieConsentManagerService } from './services/cookie-consent-manager.service';
import { DialogCookieConsentComponent } from './components/dialog-cookie-consent/dialog-cookie-consent.component';
import { slideInOut } from '@shared/animations/slide-in-out';

// Icons
import { faCookieBite } from '@fortawesome/free-solid-svg-icons';

@Component({
  animations: [slideInOut],
  selector: 'shared-cookie-consent-manager',
  templateUrl: './cookie-consent-manager.component.html',
  styleUrls: ['./cookie-consent-manager.component.scss'],
  standalone: false
})
export class CookieConsentManagerComponent implements OnDestroy, OnInit {

  // Icons
  faCookieBite = faCookieBite;

  // Model
  consentCompliance: CookieConsentCompliance = null;
  consents: CookieConsentData = null;
  isBarShown = false;

  // Subscriptions
  private serviceInitializationSubscription: Subscription;
  private serviceManageCookiesSubscription: Subscription;

  constructor(
    private cookieConsentManagerService: CookieConsentManagerService,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnDestroy() {

    // Clean up the subscriptions
    if (this.serviceInitializationSubscription) {
      this.serviceInitializationSubscription.unsubscribe();
    }
    if (this.serviceManageCookiesSubscription) {
      this.serviceManageCookiesSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {

    // Initialize the manager
    this.initialize();
  }

  onClickAcceptAll() {

    // Hide the bar
    this.isBarShown = false;

    // Update consents
    this.consents.AdPersonalization = true;
    this.consents.AdStorage = true;
    this.consents.AdUserData = true;
    this.consents.AnalyticsStorage = true;
    this.consents.FunctionalityStorage = true;
    this.consents.PersonalizationStorage = true;
    this.consents.SecurityStorage = true;

    // Store the consnnts
    this.cookieConsentManagerService.update(this.consents);
  }

  onClickOpenDialog() {

    // Hide the bar
    this.isBarShown = false;

    // Open the dialog
    const modalRef = this.modalService.open(DialogCookieConsentComponent, { backdrop: 'static', centered: true });
    modalRef.componentInstance.consents = this.consents;
    modalRef.result
      .then(() => this.cookieConsentManagerService.update(this.consents))
      .catch(() => {});
  }

  private initialize() {

    // Default consents
    this.consents = {
      AdPersonalization: false,
      AdStorage: false,
      AdUserData: false,
      AnalyticsStorage: false,
      FunctionalityStorage: true,
      PersonalizationStorage: false,
      SecurityStorage: false
    };

    // Observe service initialization
    this.serviceInitializationSubscription = this.cookieConsentManagerService.getInitializationObservable()
      .subscribe((value: CookieConsentCompliance) => this.showBar(value));

    // Observe service requests to open cookies management dialog
    this.serviceManageCookiesSubscription = this.cookieConsentManagerService.getManageCookiesObservable()
      .subscribe(() => this.onClickOpenDialog());
  }

  private showBar(compliance: CookieConsentCompliance) {

    // If the compliance is unknown, do nothing
    if (!compliance || !isPlatformBrowser(this.platformId)) {
      return;
    }

    // console.log('COMPLIANCe: ' + compliance);
    // Store the current consents
    this.consents.AdPersonalization = this.cookieConsentManagerService.hasConsented('AdPersonalization');
    this.consents.AdStorage = this.cookieConsentManagerService.hasConsented('AdStorage');
    this.consents.AdUserData = this.cookieConsentManagerService.hasConsented('AdUserData');
    this.consents.AnalyticsStorage = this.cookieConsentManagerService.hasConsented('AnalyticsStorage');
    this.consents.FunctionalityStorage = true;
    this.consents.PersonalizationStorage = this.cookieConsentManagerService.hasConsented('PersonalizationStorage');
    this.consents.SecurityStorage = this.cookieConsentManagerService.hasConsented('SecurityStorage');

    // Store compliance
    this.consentCompliance = compliance;

    // Show the cookie consent bar if the compliance requires it
    switch (compliance) {
      case 'HIDDEN':

        // We don't show bar, the compliance is HIDDEN, set all consents to true
        // console.log('EVERYTHING IS ALLOWED');
        this.consents.AdPersonalization = true;
        this.consents.AdStorage = true;
        this.consents.AdUserData = true;
        this.consents.AnalyticsStorage = true;
        this.consents.FunctionalityStorage = true;
        this.consents.PersonalizationStorage = true;
        this.consents.SecurityStorage = true;
        this.cookieConsentManagerService.update(this.consents);
        break;
      case 'OPT-IN':
      case 'OPT-OUT':
        // console.log('SHOW BAR');
        this.isBarShown = true;
        break;
      case 'RESTORED':

        // The consents were already given
        // console.log('UPDATE CONSENTS');
        this.cookieConsentManagerService.update(this.consents);
        break;
    }
  }
}
