// Resources:
// https://developers.google.com/tag-manager/devguide#events
// https://developers.facebook.com/docs/facebook-pixel/reference
// https://www.analyticsmania.com/post/single-page-web-app-with-google-tag-manager/#variables
// https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/

import { Router } from '@angular/router';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Subscription } from 'rxjs';

import { AuthService } from '../modules/auth/services/auth.service';
import { CookieConsentManagerService } from '../components/cookie-consent-manager/services/cookie-consent-manager.service';
import { CookieService } from './cookie.service';
import { FacebookConversionInfo } from '../interfaces/facebook-conversion-info';
import { GeolocationService } from './geolocation.service';
// import { HubSpotDealInfo } from '../interfaces/hub-spot-deal-info';
// import { HubSpotSubscriberInfo } from '../interfaces/hub-spot-subscriber-info';
import { SessionStorageService } from './session-storage.service';
import { SubscriberInfo } from '../interfaces/subscriber-info';
import { TrackingInfo } from '../interfaces/tracking-info';
import { TrackingPixelDataService } from './tracking-pixel-data.service';
import { UtmParamsService } from './utm-params.service';
import { WINDOW } from './window.service';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingPixelService implements OnDestroy {

  private storagePageKey = 'PAGE_URL';

  // Subscriptions
  private cookieConsentSubscription: Subscription;
  private geolocationSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private cookieConsentManagerService: CookieConsentManagerService,
    private cookieService: CookieService,
    private geolocationService: GeolocationService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private title: Title,
    private trackingPixelDataService: TrackingPixelDataService,
    private utmParamsService: UtmParamsService,
    @Inject(WINDOW) private window: Window | null
  ) {
    this.initialize();
  }

  ngOnDestroy() {

    // Clean up the subscriptions
    if (this.cookieConsentSubscription) {
      this.cookieConsentSubscription.unsubscribe();
    }
    if (this.geolocationSubscription) {
      this.geolocationSubscription.unsubscribe();
    }
  }

  // The checkout is started
  checkoutInit(trackingInfo: TrackingInfo) {

    // For optimized FB tracking, let's send a standard event
    const facebookConversionInfo: FacebookConversionInfo = {
      BillingId: null,
      ClassType: trackingInfo.ClassType,
      Currency: trackingInfo.Currency,
      CustomerId: trackingInfo.CustomerId,
      Event: 'Initiate checkout',
      Fbc: this.cookieService.read('_fbc'),
      Fbp: this.cookieService.read('_fbp'),
      Label: trackingInfo.Label,
      SessionId: trackingInfo.ContentId,
      URL: this.window && this.window.location.href,
      Value: trackingInfo.FinalPrice
    };

    this.trackingPixelDataService.trackFacebookConversion(facebookConversionInfo)
      .subscribe(() => {});

    // For optimized FB tracking, let's send a standard event
    facebookConversionInfo.Event = 'Add to cart';

    this.trackingPixelDataService.trackFacebookConversion(facebookConversionInfo)
      .subscribe(() => {});

    // Clean up GA4 ecommerce
    this.pushGoogleTagManagerEvent({ ecommerce: null });

    const gtmPayload = {
      event: 'add_to_cart',
      label: trackingInfo.Label,
      classType: trackingInfo.ClassType,
      ecommerce: {
        currency: trackingInfo.Currency,
        value: trackingInfo.FinalPrice,
        items: [{
          item_id: trackingInfo.ContentId,
          item_name: trackingInfo.Label,
          item_brand: 'Coding with Kids',
          item_category: trackingInfo.ClassType
        }]
      }
    };

    return this.pushGoogleTagManagerEvent(gtmPayload);
  }

  // Student is being selected during checkout
  checkoutStudent(trackingInfo: TrackingInfo) {

    const facebookConversionInfo: FacebookConversionInfo = {
      BillingId: null,
      ClassType: trackingInfo.ClassType,
      Currency: trackingInfo.Currency,
      CustomerId: trackingInfo.CustomerId,
      Event: 'SelectStudent',
      Fbc: this.cookieService.read('_fbc'),
      Fbp: this.cookieService.read('_fbp'),
      Label: trackingInfo.Label,
      SessionId: trackingInfo.ContentId,
      URL: this.window && this.window.location.href,
      Value: trackingInfo.FinalPrice
    };

    this.trackingPixelDataService.trackFacebookConversion(facebookConversionInfo)
      .subscribe(() => {});

    // Clean up GA4 ecommerce
    this.pushGoogleTagManagerEvent({ ecommerce: null });

    const gtmPayload = {
      event: 'begin_checkout',
      label: trackingInfo.Label,
      classType: trackingInfo.ClassType,
      ecommerce: {
        currency: trackingInfo.Currency,
        value: trackingInfo.FinalPrice,
        items: [{
          item_id: trackingInfo.ContentId,
          item_name: trackingInfo.Label,
          item_brand: 'Coding with Kids',
          item_category: trackingInfo.ClassType
        }]
      }
    };

    return this.pushGoogleTagManagerEvent(gtmPayload);

    // HubSpot deal tracking
    /*const hubSpotDealInfo: HubSpotDealInfo = {
      BillingId: null,
      ClassType: trackingInfo.ClassType,
      Currency: trackingInfo.Currency,
      CustomerId: trackingInfo.CustomerId,
      DealId: trackingInfo.HubSpotDealId,
      Event: 'Select Student',
      IsService: trackingInfo.IsService ? 1 : 0,
      Label: trackingInfo.Label,
      SessionId: trackingInfo.SessionId,
      StudentId: trackingInfo.StudentId,
      UTMCampaign: this.utmParamsService.getParam('utm_campaign'),
      UTMContent: this.utmParamsService.getParam('utm_content'),
      UTMMedium: this.utmParamsService.getParam('utm_medium'),
      UTMSource: this.utmParamsService.getParam('utm_source'),
      UTMTerm: this.utmParamsService.getParam('utm_term'),
      Value: trackingInfo.FinalPrice
    };

    return this.trackingPixelDataService.trackDeal(hubSpotDealInfo);*/
  }

  // Payment info is being provided during checkout
  checkoutPayment(trackingInfo: TrackingInfo) {

    const facebookConversionInfo: FacebookConversionInfo = {
      BillingId: null,
      ClassType: trackingInfo.ClassType,
      Currency: trackingInfo.Currency,
      CustomerId: trackingInfo.CustomerId,
      Event: 'Pay',
      Fbc: this.cookieService.read('_fbc'),
      Fbp: this.cookieService.read('_fbp'),
      Label: trackingInfo.Label,
      SessionId: trackingInfo.ContentId,
      URL: this.window && this.window.location.href,
      Value: trackingInfo.FinalPrice
    };

    this.trackingPixelDataService.trackFacebookConversion(facebookConversionInfo)
      .subscribe(() => {});

    // Clean up GA4 ecommerce
    this.pushGoogleTagManagerEvent({ ecommerce: null });

    const gtmPayload1 = {
      event: 'add_shipping_info',
      label: trackingInfo.Label,
      classType: trackingInfo.ClassType,
      ecommerce: {
        currency: trackingInfo.Currency,
        value: trackingInfo.FinalPrice,
        items: [{
          item_id: trackingInfo.ContentId,
          item_name: trackingInfo.Label,
          item_brand: 'Coding with Kids',
          item_category: trackingInfo.ClassType
        }]
      }
    };

    this.pushGoogleTagManagerEvent(gtmPayload1);

    const gtmPayload2 = {
      event: 'add_payment_info',
      label: trackingInfo.Label,
      classType: trackingInfo.ClassType,
      ecommerce: {
        currency: trackingInfo.Currency,
        value: trackingInfo.FinalPrice,
        items: [{
          item_id: trackingInfo.ContentId,
          item_name: trackingInfo.Label,
          item_brand: 'Coding with Kids',
          item_category: trackingInfo.ClassType
        }]
      }
    };

    return this.pushGoogleTagManagerEvent(gtmPayload2);

    // HubSpot deal tracking
    /*const hubSpotDealInfo: HubSpotDealInfo = {
      BillingId: null,
      ClassType: trackingInfo.ClassType,
      Currency: trackingInfo.Currency,
      CustomerId: trackingInfo.CustomerId,
      DealId: trackingInfo.HubSpotDealId,
      Event: 'Pay',
      IsService: trackingInfo.IsService ? 1 : 0,
      Label: trackingInfo.Label,
      SessionId: trackingInfo.SessionId,
      StudentId: trackingInfo.StudentId,
      UTMCampaign: this.utmParamsService.getParam('utm_campaign'),
      UTMContent: this.utmParamsService.getParam('utm_content'),
      UTMMedium: this.utmParamsService.getParam('utm_medium'),
      UTMSource: this.utmParamsService.getParam('utm_source'),
      UTMTerm: this.utmParamsService.getParam('utm_term'),
      Value: trackingInfo.FinalPrice
    };

    return this.trackingPixelDataService.trackDeal(hubSpotDealInfo);*/
  }

  // Purchase has been finished during checkout
  // FB: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
  checkoutDone(trackingInfo: TrackingInfo) {

    const facebookConversionInfo: FacebookConversionInfo = {
      BillingId: trackingInfo.BillingId,
      ClassType: trackingInfo.ClassType,
      Currency: trackingInfo.Currency,
      CustomerId: trackingInfo.CustomerId,
      Event: 'Purchase',
      Fbc: this.cookieService.read('_fbc'),
      Fbp: this.cookieService.read('_fbp'),
      Label: trackingInfo.Label,
      SessionId: trackingInfo.ContentId,
      URL: this.window && this.window.location.href,
      Value: trackingInfo.FinalPrice
    };

    this.trackingPixelDataService.trackFacebookConversion(facebookConversionInfo)
      .subscribe(() => {});

    // Clean up GA4 ecommerce
    this.pushGoogleTagManagerEvent({ ecommerce: null });

    const gtmPayload = {
      event: 'purchase',
      label: trackingInfo.Label,
      classType: trackingInfo.ClassType,
      ecommerce: {
        currency: trackingInfo.Currency,
        value: trackingInfo.FinalPrice,
        transaction_id: trackingInfo.BillingId,
        items: [{
          item_id: trackingInfo.ContentId,
          item_name: trackingInfo.Label,
          item_brand: 'Coding with Kids',
          item_category: trackingInfo.ClassType
        }]
      }
    };

    return this.pushGoogleTagManagerEvent(gtmPayload);

    // HubSpot deal tracking
    /*const hubSpotDealInfo: HubSpotDealInfo = {
      BillingId: trackingInfo.BillingId,
      ClassType: trackingInfo.ClassType,
      Currency: trackingInfo.Currency,
      CustomerId: trackingInfo.CustomerId,
      DealId: trackingInfo.HubSpotDealId,
      Event: 'Purchase',
      IsService: trackingInfo.IsService ? 1 : 0,
      Label: trackingInfo.Label,
      SessionId: trackingInfo.SessionId,
      StudentId: trackingInfo.StudentId,
      UTMCampaign: this.utmParamsService.getParam('utm_campaign'),
      UTMContent: this.utmParamsService.getParam('utm_content'),
      UTMMedium: this.utmParamsService.getParam('utm_medium'),
      UTMSource: this.utmParamsService.getParam('utm_source'),
      UTMTerm: this.utmParamsService.getParam('utm_term'),
      Value: trackingInfo.FinalPrice
    };

    return this.trackingPixelDataService.trackDeal(hubSpotDealInfo);*/
  }

  // Consent cookies update event
  consentUpdate() {
    const gtmPayload = {
      event: 'CookieConsent',
      ad_personalization: this.cookieConsentManagerService.hasConsented('AdPersonalization') ? 'granted' : 'denied',
      ad_storage: this.cookieConsentManagerService.hasConsented('AdStorage') ? 'granted' : 'denied',
      ad_user_data: this.cookieConsentManagerService.hasConsented('AdUserData') ? 'granted' : 'denied',
      analytics_storage: this.cookieConsentManagerService.hasConsented('AnalyticsStorage') ? 'granted' : 'denied',
      functionality_storage: this.cookieConsentManagerService.hasConsented('FunctionalityStorage') ? 'granted' : 'denied',
      personalization_storage: this.cookieConsentManagerService.hasConsented('PersonalizationStorage') ? 'granted' : 'denied',
      security_storage: this.cookieConsentManagerService.hasConsented('SecurityStorage') ? 'granted' : 'denied'
    };
    this.pushGoogleTagManagerEvent(gtmPayload);

    // Try to send page view (if it has been already sent, it won't be sent again)
    // The tracking prefix is not used any more?
    this.pageView('');

    // Try to initialize
    this.initialize();
  }

  pageView(trackingPrefix: string) {

    const facebookConversionInfo: FacebookConversionInfo = {
      BillingId: null,
      ClassType: null,
      Currency: null,
      CustomerId: this.authService.getUserId() || null,
      Event: 'PageView',
      Fbc: this.cookieService.read('_fbc'),
      Fbp: this.cookieService.read('_fbp'),
      Label: null,
      SessionId: null,
      URL: this.window && this.window.location.href,
      Value: null
    };

    this.trackingPixelDataService.trackFacebookConversion(facebookConversionInfo)
      .subscribe(() => {});

    // Prepare path so that it includes environment name if not on production
    const url = ((environment.shortName === 'prod') ? '' : '/' + environment.shortName.toUpperCase()) +
      trackingPrefix + this.router.url;

    // Get the page as path without params and trailing slash
    let page = url.split('?', 1)[0];
    if (page.length > 1) {
      if (page.endsWith('/')) {
        page = page.slice(0, -1);
      }
    }

    // Check if the page is the last event and return if it is
    if (page === this.sessionStorageService.getItem(this.storagePageKey)) {
      return;
    }

    // Update the last visited page
    this.sessionStorageService.setItem(this.storagePageKey, page);

    // Send custom page view event
    const gtmPayloadPageview: any = {
      event: 'Pageview',
      path: url,
      title: this.title.getTitle(),
      visitorType: this.authService.getVisitorType()
    };

    // In order to reset the scroll depth trigger, send container load event
    const gtmPayloadLoad = {
      'event': 'gtm.load'
    };

    this.pushGoogleTagManagerEvent(gtmPayloadPageview);
    this.pushGoogleTagManagerEvent(gtmPayloadLoad);
  }

  // User opened a form to create a new customer account
  registerActivated() {

    const facebookConversionInfo: FacebookConversionInfo = {
      BillingId: null,
      ClassType: null,
      Currency: null,
      CustomerId: null,
      Event: 'CreateAccount',
      Fbc: this.cookieService.read('_fbc'),
      Fbp: this.cookieService.read('_fbp'),
      Label: null,
      SessionId: null,
      URL: this.window && this.window.location.href,
      Value: null
    };

    this.trackingPixelDataService.trackFacebookConversion(facebookConversionInfo)
      .subscribe(() => {});

    const gtmPayload = {
      event: 'CreateAccount'
    };

    this.pushGoogleTagManagerEvent(gtmPayload);
  }

  // User completed their new account creation
  registerNewCustomer() {

    const facebookConversionInfo: FacebookConversionInfo = {
      BillingId: null,
      ClassType: null,
      Currency: null,
      CustomerId: this.authService.getUserId() || null,
      Event: 'NewCustomer',
      Fbc: this.cookieService.read('_fbc'),
      Fbp: this.cookieService.read('_fbp'),
      Label: null,
      SessionId: null,
      URL: this.window && this.window.location.href,
      Value: null
    };

    this.trackingPixelDataService.trackFacebookConversion(facebookConversionInfo)
      .subscribe(() => {});

    const gtmPayload: any = {
      event: 'sign_up'
    };

    this.pushGoogleTagManagerEvent(gtmPayload);
  }

  // A user subscribed to our mailing list
  subscriber(subscriberInfo: SubscriberInfo) {

    const facebookConversionInfo: FacebookConversionInfo = {
      BillingId: null,
      ClassType: null,
      Currency: null,
      CustomerId: this.authService.getUserId() || null,
      Email: subscriberInfo.Email,
      Event: 'Subscriber',
      Fbc: this.cookieService.read('_fbc'),
      Fbp: this.cookieService.read('_fbp'),
      FirstName: subscriberInfo.FirstName,
      Label: null,
      LastName: subscriberInfo.LastName,
      SessionId: null,
      URL: this.window && this.window.location.href,
      Value: null
    };

    this.trackingPixelDataService.trackFacebookConversion(facebookConversionInfo)
      .subscribe(() => {});

    // For optimized FB tracking, let's send a standard event
    facebookConversionInfo.Event = 'Complete registration';

    this.trackingPixelDataService.trackFacebookConversion(facebookConversionInfo)
      .subscribe(() => {});

    const gtmPayload = {
      event: 'generate_lead',
      country: subscriberInfo.Country,
      source: subscriberInfo.Source
    };

    return this.pushGoogleTagManagerEvent(gtmPayload);

    // HubSpot subscriber
    /*const hubSpotSubscriberInfo: HubSpotSubscriberInfo = {
      Country: subscriberInfo.Country,
      Email: subscriberInfo.Email,
      FirstName: subscriberInfo.FirstName,
      LastName: subscriberInfo.LastName,
      Source: subscriberInfo.Source
    };

    return this.trackingPixelDataService.createSubscriber(hubSpotSubscriberInfo);*/
  }

  private initialize() {

    // Watch the cookie consents changes
    if (!this.cookieConsentSubscription) {
      this.cookieConsentSubscription = this.cookieConsentManagerService.getUpdateCookiesObservable()
        .subscribe(() => this.consentUpdate());
    }

    // Get current geolocation info if the geolocation subscription doesn't exist yet
    if (!this.geolocationSubscription) {
      this.geolocationSubscription = this.geolocationService.getObservable()
        .subscribe(
          (value) => this.onGeolocation(value)
        );
    }
  }

  // The geolocation has been completed or user switched the currency
  private onGeolocation(value: any) {

    const gtmPayload = {
      event: 'Geolocation',
      pageCurrency: value.currency
    };

    this.pushGoogleTagManagerEvent(gtmPayload);
  }

  private pushGoogleTagManagerEvent(payload: any) {

    // Only if user id is known, set it for GA
    if (this.authService.getUserId()) {
      payload.userId = this.authService.getUserId();
    }

    // For debugging purposes, send the dataLayer changes in the development environment
    if (this.window && (environment.shortName === 'prod')) {
      this.window['dataLayer'] = this.window['dataLayer'] || [];
      this.window['dataLayer'].push(payload);
    } else {
      console.log('GA EVENT: ' + JSON.stringify(payload));
    }
  }
}
