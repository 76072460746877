import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SubscriptionDataService {

  constructor(
    private http: HttpClient
  ) { }

  private urlSubscription = environment.urlWebServices + '/api/subscription';

  read(code: string, currency: string, weekDaysCount: number, isPrivate: any): Observable<any> {

    isPrivate = isPrivate ? '1' : '0';

    return this.http.get<any>(`${this.urlSubscription}/${code}/${currency}/${weekDaysCount}/${isPrivate}`)
      .pipe(
        tap(response => this.fixFromMySQL(response)),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  private fixFromMySQL(data) {

    if (data.IsParsed) {
      return;
    }

    // Fix booleans
    data.Expiration = !!data.Expiration;
    data.IsPrivate = !!data.IsPrivate;
    data.IsRecurring = !!data.IsRecurring;
    data.IsStudent = !!data.IsStudent;

    // Parse JSON
    data.Discounts = data.Discounts ? JSON.parse(data.Discounts) : [];
    data.SubscriptionParameters = data.SubscriptionParameters ? JSON.parse(data.SubscriptionParameters) : [];

    data.IsParsed = true;
  }
}
