<div class="modal-header">
  <h1>{{header}}</h1>
</div>

<div class="modal-body">

  <div class="form-group">
    <label class="form-label" for="textArea"><span [innerHTML]="label"></span></label>
    <textarea class="form-control" [(ngModel)]="text" [maxlength]="maxLength" rows="10"></textarea>
  </div>
</div>

<div class="modal-footer">

  <!-- Error -->
  <div class="cwk-red" *ngIf="errorProcessing">
    {{errorProcessing}}
  </div>

  <!-- Buttons - TODO break to the new line when error is shown -->
  <button type="button" class="btn btn-warning btn-large mr-auto" (click)="cancel()" i18n>{{buttonCancel}}</button>
  <button type="button" class="btn btn-warning btn-large" (click)="process()" [disabled]="isProcessing || (isRequired && !text)">
    <span *ngIf="isProcessing; else submit"><fa-icon [icon]="faSpinner" animation="spin"></fa-icon>&nbsp;{{buttonProcessing}}</span>
    <ng-template #submit>{{buttonSubmit}}</ng-template>
  </button>
</div>

