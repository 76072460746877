<h1 class="text-center mb-3">CODER'S PATHWAYS<span class="cwk-registration-icon">&reg;</span></h1>
<div class="fix-row mb-5">
  <div class="col-md-12 col-lg-6 mb-3 mb-lg-0">
    <div class="d-flex flex-column h-100 justify-content-between">
      <div class="mb-3 mb-lg-0">
        <h3>Progressive Curriculum</h3>
        <div>Coder's Pathways<span class="cwk-registration-icon">&reg;</span> are designed for programs shorter than our year-round Coder’s Ladder<span class="cwk-registration-icon">&reg;</span> classes, such as enrichment classes or camps. Most of these programs are limited to 8-15 hours of instruction time, therefore each pathway is sectioned into multiple courses, from <b>Beginner to Advanced Levels.</b></div>
      </div>
      <div class="mt-3 mb-3">
        <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathway-skill-arrows.png" alt="Pathway skills" loading="lazy" height="114" width="639" />
      </div>
      <div>
        <h3>Specialty Focus</h3>
        <div>Coder's Pathways<span class="cwk-registration-icon">&reg;</span> curriculum introduces students to advanced programming in core languages, such as <b>Scratch, Python, or Java</b>. Coder’s Pathways<span class="cwk-registration-icon">&reg;</span> offer a wider range of specialty options, from <b>Minecraft Modding</b> and <b>Roblox</b> to <b>App Development</b> and <b>Robotics</b>. Pathway-based programs are great as stand-alone programs for students with specific interests or as supplemental enrichment options that add to the versatility of any young coder, including students enrolled in our academic Coder's Ladder<span class="cwk-registration-icon">&reg;</span> program.</div>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-lg-6 text-center pt-3">
    <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/coders-pathways-2024.png" alt="Coder's pathways chart" width="360" height="482" loading="lazy" />
  </div>
</div>
<div class="d-flex justify-content-center">
  <a id="explore-coders-pathways" tm-destination="/coders-pathways" class="cwk-link" [routerLink]="['/coders-pathways']" queryParamsHandling="preserve">
    Explore Coder's Pathways<span class="cwk-registration-icon">&reg;</span>
  </a>
</div>

