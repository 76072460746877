import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewChecked, Component, ElementRef, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { animate, group, state, style, trigger, transition, useAnimation } from '@angular/animations';

import { ClassInfo } from './interfaces/class-info';
import { DialogClassDetailsComponent } from '../dialog-class-details/dialog-class-details.component';
import { DialogConfirmComponent } from '@shared/components/dialog-confirm/dialog-confirm.component';
import { DialogFutureSessionsComponent } from '../dialog-future-sessions/dialog-future-sessions.component';
import { DialogJoinWaitlistComponent } from '@shared/components/dialog-join-waitlist/dialog-join-waitlist.component';
import { DialogPathwayDetailsComponent } from '../dialog-pathway-details/dialog-pathway-details.component';
import { GeolocationService } from '@shared/services/geolocation.service';
import { GradeService } from '@shared/services/grade.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnlineEnrichmentRegistrationsListDataService } from './services/online-enrichment-registrations-list-data.service';
import { PathwayAges } from '../../classes/pathway-ages';
import { PathwayFilterModel } from '../../interfaces/pathway-filters';
import { TelemetryService } from '@shared/services/telemetry.service';
import { WINDOW } from '@shared/services/window.service';

import { environment } from 'environments/environment';

// Animations
import { slideInOut } from '@shared/animations/slide-in-out';

// Icons
import { faCalendarPlus, faChevronLeft, faChevronRight, faDotCircle, faInfoCircle, faMinusCircle, faPlusCircle,
  faExternalLinkAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faCircleO } from '@fortawesome/free-regular-svg-icons';

@Component({
  animations: [slideInOut],
  selector: 'home-online-enrichment-registrations-list',
  templateUrl: './online-enrichment-registrations-list.component.html',
  styleUrls: [
    './online-enrichment-registrations-list.component.scss',
    '../../styles/pathway.scss',
    '../../styles/registration-lists.scss'
  ],
  providers: [PathwayAges, OnlineEnrichmentRegistrationsListDataService],
  standalone: false
})
export class OnlineEnrichmentRegistrationsListComponent implements AfterViewChecked, OnChanges, OnInit {

  @Input() discountCode: string;
  @Input() isAdultClasses = false;
  @Input() limitLocality: any = null;
  @Input() partnerId: number;
  @Input() status: string;
  @ViewChild('top', {static: true}) topElement: ElementRef;

  readonly defaultAgeFilter = 2; // Ages 8-10

  classes = [];
  classes2Show: ClassInfo[] = [];
  errorReading = '';
  errorReadingPathways = '';
  geolocation = null;
  isFiltersHidden = false;
  isLaunchMessageShown = false;
  isOpenClassesOnly = true; // When true, class rows only appear if there is a class for the current or any future week
  isReading = false;
  isReadingPathways = false;
  isTelemetrySet = false;
  launchDate: Date = null;
  pathwayAges: any[] = null;
  pathwayFilterModel: PathwayFilterModel = {} as PathwayFilterModel;
  pathways: any = [];
  schoolYear: string = null;
  selectedWeek = 0;
  timezoneAbbr = new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];
  weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  weeks: any = [];

  faCalendarPlus = faCalendarPlus;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faCircleO = faCircleO;
  faDotCircle = faDotCircle;
  faExternalLinkAlt = faExternalLinkAlt;
  faInfoCircle = faInfoCircle;
  faMinusCircle = faMinusCircle;
  faPlusCircle = faPlusCircle;
  faSpinner = faSpinner;

  constructor(
    private activatedRoute: ActivatedRoute,
    private geolocationService: GeolocationService,
    private gradeService: GradeService,
    private modalService: NgbModal,
    private onlineEnrichmentRegistrationsListDataService: OnlineEnrichmentRegistrationsListDataService,
    private pathwayAgesClass: PathwayAges,
    private router: Router,
    private telemetryService: TelemetryService,
    @Inject(WINDOW) private window: Window | null
  ) {

    // Define the pathway ages (shared with explore our pathways)
    this.pathwayAges = this.pathwayAgesClass.PATHWAY_AGES;
  }

  ngAfterViewChecked() {

    // Set the telemetry for the page
    if (!this.isTelemetrySet) {
      this.setTelemetry();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['limitLocality'] && !changes['limitLocality'].firstChange) {
      this.readRegistrations();
    }
  }

  ngOnInit() {

    // Get current geolocation info
    this.geolocationService.getObservable()
      .subscribe((value) => {
        this.geolocation = value;

        // Read the registrations
        this.readRegistrations();

        // Read the pathways
        this.readPathways();
    });

    // Set the filter model by pulling age and pathway from query params. Default to youngest age if not set.
    this.pathwayFilterModel.ageUid = parseInt(this.activatedRoute.snapshot.queryParamMap.get('age'), 10) || this.defaultAgeFilter;
    this.pathwayFilterModel.pathwayId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('pathway'), 10);

    // Hide filtering for single locations or adult classes
    this.isFiltersHidden = !!this.limitLocality || this.isAdultClasses;
  }

  classClicked(_class: any) {
    _class.isExpanded = !_class.isExpanded;
  }

  clearClassBorderHighlights() {
    this.classes2Show.forEach(_class => _class.BorderHighlight = false);
  }

  goSignup(session: any) {
    if (session.HasEnded || !this.window) {
      return;
    }

    if (session.Occupied) {
      const modalRef = this.modalService.open(DialogJoinWaitlistComponent, { size: 'lg', centered: true });
      modalRef.componentInstance.sessionId = session.LessonId;
      return;
    }

    if (session.IsExternalRegistration) {

      const modalRef = this.modalService.open(DialogConfirmComponent, { centered: true });
      if (!session.ExternalURL) {

        // Session is marked for external registrations but no external URL exists yet
        modalRef.componentInstance.buttonCancel = 'Close';
        modalRef.componentInstance.buttonSubmit = null;
        modalRef.componentInstance.header = 'Error!';
        modalRef.componentInstance.text = 'The registration page failed to open. Please contact us so we can fix it for you.';
      } else {
        modalRef.componentInstance.buttonSubmit = 'Continue';
        modalRef.componentInstance.header = 'Register at your school';
        // tslint:disable-next-line
        modalRef.componentInstance.text = 'Registration for this class is handled through the school. Continuing will take you to their registration page.';

        // Process backend response once the dialog is closed with success
        modalRef.result
          .then(() => this.window.open(session.ExternalURL))
          .catch(() => {});
      }
    } else {
      /*const queryParams = {
        s: session.LessonId,
        c: this.geolocation.currency,
        d: this.discountCode
      };
      this.router.navigate(['checkout'], { queryParams: queryParams, queryParamsHandling: 'merge' });*/
      this.window.location.href = `/checkout?s=${session.LessonId}&c=${this.geolocation.currency}` + (this.discountCode ? `&d=${this.discountCode}` : '') + (this.partnerId ? `&p=${this.partnerId}` : '');
    }
  }

  moveWeek(direction: string) {
    this.selectedWeek += direction === 'FORWARD' ? 1 : -1;

    // Update query params so that the newly selected week is applied
    this.updateQueryParams();

    // Apply filter
    this.applyFilter();
  }

  openClassDetails(_class: any) {
    const modalRef = this.modalService.open(DialogClassDetailsComponent, { size: 'lg', centered: true });
    modalRef.componentInstance._class = _class;
    modalRef.componentInstance.showGrades = !!this.limitLocality;
  }

  openPathwayDetails() {

    // Open pathway details dialog
    const pathway = this.pathways.find(p => p.PathwayId === this.pathwayFilterModel.pathwayId);
    if (pathway) {
      const modalRef = this.modalService.open(DialogPathwayDetailsComponent, { size: 'lg', centered: true });
      modalRef.componentInstance.pathway = Object.assign({}, pathway);
      modalRef.componentInstance.selectedAgeUid = this.pathwayFilterModel.ageUid;
      modalRef.componentInstance.showGrades = !!this.limitLocality;
    }
  }

  openFutureSessionsDialog(_class: any, futureSessions: any[]) {

    // Open the future sessions dialog
    const modalRef = this.modalService.open(DialogFutureSessionsComponent, { size: 'lg', centered: true });
    modalRef.componentInstance._class = _class;
    modalRef.componentInstance.discountCode = this.discountCode;
    modalRef.componentInstance.geolocation = this.geolocation;
    modalRef.componentInstance.sessions = futureSessions;
  }

  setOpenClassesOnly(newVal: boolean) {
    this.isOpenClassesOnly = newVal;
    this.applyFilter();
  }

  // Age filter was switched
  switchAgeFilter(pathwayAge: any) {

    // Update the filter model. If the age was already selected, deselect it
    this.pathwayFilterModel.ageUid = pathwayAge.uid === this.pathwayFilterModel.ageUid ? null : pathwayAge.uid;
    this.pathwayFilterModel.pathwayId = null; // Remove any selected pathway

    this.updateQueryParams();
    this.applyFilter();
    this.scrollToTop();
  }

  switchPathwayFilter(pathway: any) {

    if (pathway.IsDisabled) {

      // Pathway was disabled because it doesn't contain any classes for the given filter, exit!
      return;
    }

    // Update the filter model. If the pathway was already selected, deselect it
    this.pathwayFilterModel.pathwayId = pathway.PathwayId === this.pathwayFilterModel.pathwayId ? null : pathway.PathwayId;

    this.updateQueryParams();
    this.applyFilter();
    this.scrollToTop();
  }

  weekForwardActive() {
    return this.selectedWeek < this.weeks.length - 1;
  }

  weekReverseActive() {
    return this.selectedWeek > 0;
  }

  private applyFilter() {

    if (!this.classes.length) {

      // There are no classes, no need to filter
      return;
    }

    // Start with all classes
    let _classes2Show = this.classes;

    if (!this.isFiltersHidden) {

      // Check to see if any pathways should be disabled
      this.pathways.forEach(pathway => {
        pathway.IsDisabled = !pathway.IsOfferingEnrichment ||
          (this.pathwayFilterModel.ageUid && this.pathwayAgesClass.filterClasses4PathwayAge(
          this.classes.filter(_class => _class.Pathways.findIndex(_pathway => _pathway.PathwayId === pathway.PathwayId) !== -1),
          this.pathwayFilterModel.ageUid
        ).length === 0);

        // Was a pathway selected and is no longer enabled due to filtering?
        if (pathway.IsDisabled && pathway.PathwayId === this.pathwayFilterModel.pathwayId) {
          this.pathwayFilterModel.pathwayId = null;
        }
      });

      // Filter classes by the selected pathway
      _classes2Show = !this.pathwayFilterModel.pathwayId ? _classes2Show :
        _classes2Show.filter(_class => _class.Pathways.find(_pathway => _pathway.PathwayId === this.pathwayFilterModel.pathwayId));

      // Filter classes by the selected pathway age
      _classes2Show = !this.pathwayFilterModel.ageUid ? _classes2Show :
        this.pathwayAgesClass.filterClasses4PathwayAge(_classes2Show, this.pathwayFilterModel.ageUid);

      // Set display text so the user knows what is being filtered
      const pathwayAge = this.pathwayAgesClass.pathwayAge4Uid(this.pathwayFilterModel.ageUid);
      const agePartial = this.limitLocality ?
        (pathwayAge ? 'Grades ' + pathwayAge.gradeMin + (pathwayAge.gradeMax ? ('-' + pathwayAge.gradeMax) : '+') : 'All grades') :
        (pathwayAge ? 'Ages ' + pathwayAge.ageMin + (pathwayAge.ageMax ? ('-' + pathwayAge.ageMax) : '+') : 'All ages');
      const pathwayPartial = this.pathwayFilterModel.pathwayId && this.pathways.length ?
        this.pathways.find(pathway => pathway.PathwayId === this.pathwayFilterModel.pathwayId).Name : 'All pathways';
      this.pathwayFilterModel.displayText = `${agePartial} / ${pathwayPartial}`;
    }

    // If isOpenClassesOnly, only show the class if there are sessions for the current week or if there exists some future sessions
    if (this.isOpenClassesOnly) {
      _classes2Show = _classes2Show.filter(_class =>
        (_class.sessionsByWeek[this.selectedWeek].find(weekday => weekday.length) ||
        _class.sessionsByWeekFuture[this.selectedWeek].find(weekday => weekday.length)));
    }

    // Set the classes to be shown
    this.classes2Show = _classes2Show;

    // Reset telemetry
    this.isTelemetrySet = false;
  }

  private getUniqueWeeks(allSessions: any[]) {

    // Reset for debugging purposes (manually switching of statuses)
    this.weeks = [];

    // IMPORTANT: sessions must be sorted so that the weeks array populates in the correct order
    const weeksProcessed = [];
    allSessions.sort((a, b) => a.SessionStartUTC > b.SessionStartUTC ? 1 : -1).forEach(session => {
      if (!weeksProcessed.includes(session.Week)) {
        const curr = new Date(session.SessionStartUTC.getTime());
        const mon = curr.getDate() - curr.getDay() + 1;
        this.weeks.push({
          monday: new Date(curr.setDate(mon)), // Used for calendar display
          weekIndex: session.Week
        });
        weeksProcessed.push(session.Week);
      }
    });
  }

  private processSession(session: any) {

    // If there are any exclusive discounts choose just the exclusive discounts
    // If there are more than one exclusive, choose the one with the largest Discount (we don't consider units here)
    const exclusiveDiscounts = session.Discounts.filter(d => d.IsExclusive);
    session.Discounts = !exclusiveDiscounts.length ? session.Discounts :
      [session.Discounts.find(d => d.Discount === Math.max.apply(Math, session.Discounts.map(d2 => d2.Discount)))];

    // Adjust the final price by immediately applicable discounts
    session.FinalPrice = session.Price - session.Discounts
      .map(discount => discount.Unit === 'CASH' ? discount.Discount : Math.floor(session.Price * discount.Discount / 100))
      .reduce((a, b) => a + b, 0);

    // Are we occupied?
    session.Occupied = session.SpotsLeft === 0;

    // Prepare starting time by cloning the StartUTC timestamp and removing the date part
    session.StartAt = new Date(session.StartUTC.getTime());
    session.StartAt.setFullYear(1970, 0, 1);
  }

  private readPathways() {

    // Show spinner
    this.isReadingPathways = true;

    // Reset error status
    this.errorReadingPathways = '';

    this.onlineEnrichmentRegistrationsListDataService.readPathways(this.geolocation.currency)
      .subscribe(
        (value) => {
          this.pathways = value;

          // Set the disabled and selected image paths
          this.pathways.forEach(pathway => {
            pathway.IconFileDisabled = pathway.IconFile.substring(0, pathway.IconFile.length - 4) + '_disabled.png';
            pathway.IconFileSelected = pathway.IconFile.substring(0, pathway.IconFile.length - 4) + '_selected.png';
          });

          // Apply the filters
          this.applyFilter();

          // Done reading
          this.isReadingPathways = false;
        },
        (error) => {
          this.errorReadingPathways = error;
          this.isReadingPathways = false;
        }
      );
  }

  private readRegistrations() {

    // Show spinner
    this.isReading = true;

    // Reset error status
    this.errorReading = '';

    // Read the locations data
    this.onlineEnrichmentRegistrationsListDataService
      .readRegistrations(
        this.partnerId,
        this.status,
        this.geolocation.currency,
        this.discountCode,
        this.isAdultClasses,
        this.limitLocality ? this.limitLocality.LocalityId : null)
      .subscribe(
        (value) => {

          // Process any configuration
          if (value.Configuration && value.Configuration.find(c => c.KeyName === 'ONLINE-ENRICHMENT-LAUNCH-TIMESTAMP')) {
            const now = new Date();
            this.launchDate = value.Configuration.find(c => c.KeyName === 'ONLINE-ENRICHMENT-LAUNCH-TIMESTAMP').Value;
            this.isLaunchMessageShown = this.launchDate > now && !this.limitLocality;
          }
          if (value.Configuration && value.Configuration.find(c => c.KeyName === 'ONLINE-ENRICHMENT-SCHOOL-YEAR')) {
            this.schoolYear = value.Configuration.find(c => c.KeyName === 'ONLINE-ENRICHMENT-SCHOOL-YEAR').Value;
          }

          const asClasses = value.Classes;

          // Prepare classes
          asClasses.forEach(_class => {
            _class.GradeRange = this.gradeService.toRange(_class.GradeMin, _class.GradeMax);
          });

          // Get all of the sessions from the classes
          let allAsSessions = [];
          asClasses.forEach(_class => {
            _class.Sessions.forEach(this.processSession);
            allAsSessions = allAsSessions.concat(_class.Sessions);
          });

          // Get the unique weeks from the sessions
          this.getUniqueWeeks(allAsSessions);

          // Group AS sessions by weekday
          asClasses.forEach(_class => {

            // Sessions by week array must equal the length of our weeks. Inner array is weekdays.length
            _class.sessionsByWeek = [];
            _class.sessionsByWeekFuture = [];
            this.weeks.forEach(week => {
              _class.sessionsByWeek.push([[], [], [], [], [], [], []]);
              _class.sessionsByWeekFuture.push([[], [], [], [], [], [], []]);
            });

            // Set the session into the appropriate week / day position
            _class.Sessions.forEach(session => {
              for (let i = 0; i < this.weeks.length; i++) {
                if (session.Week === this.weeks[i].weekIndex) {
                  const weekdayIndex = session.StartUTC.getDay() === 0 ? 6 : session.StartUTC.getDay() - 1;
                  _class.sessionsByWeek[i][weekdayIndex].push(session);
                }
              }
            });

            // "Stack" the sessionsByWeek
            for (let i = 0; i < _class.sessionsByWeek.length; i++) {
              for (let j = 0; j < _class.sessionsByWeek[i].length; j++) {
                _class.sessionsByWeek[i][j] = this.stack(_class.sessionsByWeek[i][j]);
              }
            }

            // Check if the session has any FUTURE session for the day
            for (let i = 0; i < _class.sessionsByWeek.length; i++) {
              const weekdays = _class.sessionsByWeek[i];

              for (let j = 0; j < weekdays.length; j++) {
                const weekday = weekdays[j];

                // This weekday doesn't have any sessions for the week, gather all of the future sessions for this day
                if (!weekday.length) {
                  for (let ii = i + 1; ii < _class.sessionsByWeek.length; ii++) {
                    const futureSameWeekday = _class.sessionsByWeek[ii][j];
                    if (futureSameWeekday.length) {
                      _class.sessionsByWeekFuture[i][j] = _class.sessionsByWeekFuture[i][j].concat(futureSameWeekday);
                    }
                  }
                }
              }
            }

            // Create the SessionsByDay (ONLY USED FOR MOBILE)
            _class.SessionsByDay = [];
            const now = new Date();
            now.setDate(now.getDate() - 6); // Show sessions up to 6 days after they start
            [0, 1, 2, 3, 4, 5, 6].forEach(weekday => {

              // Get the sessions for this weekday. Use StartOn to make sure we get any shifts from timezone conversions!
              let sessions4Weekday = _class.Sessions.filter(session =>
                (session.StartUTC.getDay() === 0 ? 6 : session.StartUTC.getDay() - 1) === weekday &&
                session.SessionStartUTC > now
              );

              // On mobile, we only show sessions that are future or started within 6 days. Update HasAnySessionsMobile we have ANY sessions
              // to show. This flag is used to display "MORE COMING SOON" for mobile devices.
              if (!_class.HasAnySessionsMobile && sessions4Weekday.length) {
                _class.HasAnySessionsMobile = true;
              }

              // "Stack" sessions so that we only have one of each stating time. Since this is mobile sort by starting date
              sessions4Weekday = this.stack(sessions4Weekday.map(a => ({...a}))).sort((a, b) => a.StartUTC > b.StartUTC ? 1 : -1 );

              // Add the sessions to the weekday!
              _class.SessionsByDay.push(sessions4Weekday);
            });
          });

          // Set the processed classes
          this.classes = asClasses;
          this.classes2Show = asClasses;

          // Set the selected week for the calendar
          this.setSelectedWeek();

          // Apply the filters
          this.applyFilter();

          // Done reading & processing
          this.isReading = false;
        },
        (error) => this.errorReading = error,
        () => this.isReading = false
      );
  }

  private scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  private setSelectedWeek() {

    // Check if the query params have a week
    const savedWeekIndex = this.weeks
      .findIndex(week => week.weekIndex === parseInt(this.activatedRoute.snapshot.queryParamMap.get('w'), 10));
    if (savedWeekIndex !== -1) {
      this.selectedWeek = savedWeekIndex;
      return;
    }

    // By default, start with the first week
    this.selectedWeek = 0;

    // Get now, and convert it to UTC
    const now = new Date();
    // now = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

    // Go through all of our weeks in the calendar
    this.weeks.forEach(week => {

      // Move to Saturday, 2am UTC (7pm PDT)
      const threshold = new Date(week.monday.getTime());
      threshold.setDate(threshold.getDate() + 5);
      threshold.setHours(2, 0, 0);

      // If we're passed the threshold, move the selected week forward by 1
      if (now > threshold && this.selectedWeek <= (this.weeks.length - 2)) {
        this.selectedWeek++;
      }
    });

    // Update query params so that the newly selected week is applied
    // this.updateQueryParams();

    // Hot fix to prevent page from loading on week of 4/27
    // If the selected week is 4/27 and we have ANY workshop after 4/27, move forward by one week
    // if (this.weeks[this.selectedWeek].weekIndex === 18 && this.selectedWeek <= (this.weeks.length - 2)) {
    //   this.selectedWeek++;
    // }

    // Force week to 10/11 if we're past the launch date. (Online only!)
    /*const octElevenWeekIndex = 41;
    const thisWeekIndex = this.weeks[this.selectedWeek].weekIndex;
    if (!this.limitLocality && this.launchDate && now > this.launchDate && thisWeekIndex < octElevenWeekIndex && now < new Date('2023')) {
      const newIndex = this.weeks.findIndex(w => w.weekIndex === octElevenWeekIndex);
      if (newIndex !== -1) {
        this.selectedWeek = newIndex;
      }
    }*/
  }

  private setTelemetry() {
    this.telemetryService.inject('CLICK', 'age-switched-', null, true);
    this.telemetryService.inject('CLICK', 'calendar-forward', null, true);
    this.telemetryService.inject('CLICK', 'calendar-reverse', null, true);
    this.telemetryService.inject('CLICK', 'next-classes-', null, true);
    this.telemetryService.inject('CLICK', 'open-class-details-', null, true);
    this.telemetryService.inject('CLICK', 'pathway-details', null, true);
    this.telemetryService.inject('CLICK', 'pathway-switched-', null, true);
    this.telemetryService.inject('CLICK', 'session-', null, true);
    this.isTelemetrySet = true;
  }

  private stack(sessions4Weekday: any[]): any[] {
    if (!sessions4Weekday.length) {
      return sessions4Weekday;
    }

    // Sort by time within the weekday
    sessions4Weekday.sort((a, b) => {
      /*const aTime = new Date(a.LocalStartOn.getTime());
      aTime.setFullYear(1970, 0, 1);
      const bTime = new Date(b.LocalStartOn.getTime());
      bTime.setFullYear(1970, 0, 1);*/
      return (a.StartAt > b.StartAt) ? 1 : -1;
    });

    // Group sessions by StartAt
    sessions4Weekday = sessions4Weekday.reduce(function (r, a) {
      r[a.StartAt] = r[a.StartAt] || [];
      r[a.StartAt].push(a);
      return r;
    }, Object.create(null));

    // Convert associative array to normal so we can easily iterate
    sessions4Weekday = Object.keys(sessions4Weekday).map(k => sessions4Weekday[k]);

    // Init the final array of sessions we're going to return (only 1 of each StartAt)
    const finalSessions4Weekday = [];
    sessions4Weekday.forEach(groupedStart => {

      // Sort by occupied and last spots
      groupedStart.sort((a, b) => {
        if (a.Occupied === b.Occupied) {
          return a.SpotsLeft - b.SpotsLeft;
        }
        return a.Occupied > b.Occupied ? 1 : -1;
      });

      // We only show the top of the stack!
      const item2Display = groupedStart[0];

      // SpotsLeft should be a sum of all of the sessions in the stack (as to not mislead customers)
      item2Display.SpotsLeft = groupedStart.reduce((prev, next) => prev + next.SpotsLeft, 0);

      // Set length of the stack (currently only used as a flag to show stack image)
      item2Display.NumberStacked = groupedStart.length - 1;

      // Set the number of occupied sessions
      item2Display.NumberOccupied = groupedStart.filter(session => session.Occupied).length + (item2Display.Occupied ? -1 : 0);
      finalSessions4Weekday.push(item2Display);
    });

    return finalSessions4Weekday;
  }

  private updateQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        age: (this.pathwayFilterModel.ageUid || null),
        pathway: (this.pathwayFilterModel.pathwayId || null),
        w: this.weeks[this.selectedWeek].weekIndex
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }
}
