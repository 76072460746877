import { Component, Inject, Input, OnInit } from '@angular/core';

import { WINDOW } from '@shared/services/window.service';

// Icons
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cwk-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss'],
  standalone: false
})
export class ErrorAlertComponent implements OnInit {

  @Input() error: any;
  @Input() isHomeApp = true;

  // Icons
  faExclamationTriangle = faExclamationTriangle;

  // Model
  isHtml = false;

  constructor(
    @Inject(WINDOW) private window: Window | null
  ) { }

  ngOnInit() {

    // Check whether the error message is HTML formatted
    this.isHtml = this.error.startsWith('<html>');
  }

  refresh() {
    if (this.window) {
      this.window.location.reload();
    }
  }
}
