import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ClassInterest } from '../interfaces/class-interest';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClassInterestFormDataService {

  constructor(
    private http: HttpClient
  ) { }

  private urlClassInterest = environment.urlWebServices + '/api/classInterest';

  // Create
  create(classInterest: ClassInterest): Observable<any> {
    const postURL = `${this.urlClassInterest}`;
    return this.http.post<any>(postURL, classInterest)
      .pipe(
        catchError(error => observableThrowError(error.error || error.message || error))
      );
  }
}
