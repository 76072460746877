import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, SimpleChanges, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

import { ClassInfo } from './interfaces/class-info';
import { Constants } from '../../classes/constants';
import { DialogClassDetailsComponent } from '../dialog-class-details/dialog-class-details.component';
import { DialogConfirmComponent } from '@shared/components/dialog-confirm/dialog-confirm.component';
import { DialogFutureSessionsComponent } from '../dialog-future-sessions/dialog-future-sessions.component';
import { DialogJoinWaitlistComponent } from '@shared/components/dialog-join-waitlist/dialog-join-waitlist.component';
import { GeolocationService } from '@shared/services/geolocation.service';
import { InPersonRegistrationsSharingService } from '../../services/in-person-registrations-sharing.service';
import { Location } from '../../interfaces/location';
import { OnlineCampsDataService } from './services/online-camps-data.service';
import { PathwayAges } from '../../../shared/classes/pathway-ages';
import { PathwayFilterModel } from '../../../shared/interfaces/pathway-filters';
import { Season } from '../../../shared/interfaces/season';
import { SeasonButtonColors } from '../../../shared/enums/season-button-colors';
import { TelemetryService } from '@shared/services/telemetry.service';
import { WINDOW } from '@shared/services/window.service';

import { environment } from 'environments/environment';

// Animations
import { slideInOut } from '@shared/animations/slide-in-out';

// Icons
import { faCaretDown, faChevronDown, faChevronLeft, faChevronRight, faCircleInfo, faDotCircle, faExternalLinkAlt, faMinusCircle, faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faCircleO } from '@fortawesome/free-regular-svg-icons';

export enum KEY_CODE {
  LEFT_ARROW = 37,
  RIGHT_ARROW = 39
}

@Component({
  animations: [slideInOut],
  selector: 'home-online-camps-registration-list',
  templateUrl: './online-camps-registration-list.component.html',
  styleUrls: [
    './online-camps-registration-list.component.scss',
    '../../../shared/styles/pathway.scss',
    '../../../shared/styles/registration-lists.scss'
  ],
  providers: [DatePipe, PathwayAges],
  standalone: false
})
export class OnlineCampsRegistrationListComponent implements AfterViewChecked, OnDestroy, OnInit {

  @Input() discountCode: string;
  // @Input() forceHybridOnly: boolean = null;
  @Input() isFullDay: boolean; // Show full-day camps?
  @Input() isHalfDay: boolean; // Show half-day camps?
  @Input() isOnline: boolean;
  @Input() hideFilters: boolean;
  @Input() partnerId: number;
  @Input() selectedSeason: Season = null;
  @Input() status: string;

  @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
      if (event.keyCode === KEY_CODE.LEFT_ARROW) {
        this.moveWeek('REVERSE');
      }
      if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
        this.moveWeek('FORWARD');
      }
    }

  //@Output() seasonsChange = new EventEmitter<Season[]>();
  @Output() selectedSeasonChange = new EventEmitter<Season>();

  @ViewChild('datePicker') set content(content: ElementRef) {
    if (content) {
      this.datePicker = content;
    }
  }
  @ViewChild('header', {static: true}) headerElement: ElementRef;
  @ViewChild('registrationsList', {static: true}) registrationsList: ElementRef;

  readonly autoScrollOffset = 5; // The extra distance to auto-scroll when moving between weeks
  readonly defaultAgeFilter = 2; // Ages 8-10

  // TODO: thresholds should be moved to DB configuration
  readonly weekThresholdHoursUTC = 2; // 2am UTC
  readonly weekThresholdMinutesUTC = 0; // 0 minutes
  readonly weekThresholdDayUTC = 2; // Tuesday

  classes = [];
  classes2Show: ClassInfo[] = [];
  classes2ShowMobile: ClassInfo[] = [];
  errorReading = '';
  errorReadingPathways = '';
  geolocation = null;
  // isHybridOnly: boolean = null;
  isOpenClassesOnly = true; // When true, class rows only appear if there is a class for the current week
  isReading = false;
  isReadingPathways = false;
  isTelemetrySet = false;
  locations: Location[] = [];
  ngbDPData = {
    maxDate: null, // Max date to show in the datepicker
    minDate: null, // Min date tot show in the datepicker
    model: null // The currently selected day in the datepicker
  };
  pathwayAges: any[] = null;
  pathwayFilterModel: PathwayFilterModel = {} as PathwayFilterModel;
  pathways: any = [];
  seasons: Season[] = [];
  //selectedSeason: Season = null;
  selectedWeek: number = null;
  timeWindows: Date[] = [];
  timezoneAbbr = new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];
  weeks: any = [];

  private datePicker: ElementRef;
  private scroll2ClassId: any = null;

  // Icons
  faCaretDown = faCaretDown;
  faChevronDown = faChevronDown;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faCircleInfo = faCircleInfo;
  faCircleO = faCircleO;
  faDotCircle = faDotCircle;
  faExternalLinkAlt = faExternalLinkAlt;
  faMinusCircle = faMinusCircle;
  faPlusCircle = faPlusCircle;
  faSpinner = faSpinner;

  // Subscriptions
  private getLocationsSelectedSubscription: Subscription = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    public constants: Constants,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document,
    private geolocationService: GeolocationService,
    private inPersonRegistrationsSharingService: InPersonRegistrationsSharingService,
    private modalService: NgbModal,
    private onlineCampsDataService: OnlineCampsDataService,
    private pathwayAgesClass: PathwayAges,
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    private telemetryService: TelemetryService,
    @Inject(WINDOW) private window: Window | null
  ) {

    // Define the pathway ages (shared with explore our pathways)
    this.pathwayAges = this.pathwayAgesClass.PATHWAY_AGES;
  }

  ngAfterViewChecked() {

    // Set the telemetry for the page
    if (!this.isTelemetrySet) {
      this.setTelemetry();
    }

    // Check if we need to scroll the table
    if (this.scroll2ClassId && isPlatformBrowser(this.platformId) && this.window) {

      // Get reference to the sticky header and the class element
      const headerElementSticky = this.document.querySelector('#header');
      const classElement = this.document.querySelector(`#classId-${this.scroll2ClassId}`);

      // Scroll so that the element is just underneath the bottom of the sticky header
      if (classElement) {
        this.window.scrollTo({
          top: classElement.getBoundingClientRect().top + this.window.scrollY - headerElementSticky.clientHeight - this.autoScrollOffset + 1
        });
      }

      // Nullify
      this.scroll2ClassId = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isFullDay'] || changes['isHalfDay']) {
      this.applyFilter();
    }
    if (changes['selectedSeason'] && changes['selectedSeason'].currentValue) {
      this.setSeason(changes['selectedSeason'].currentValue);
    }
  }

  ngOnDestroy() {
    if (this.getLocationsSelectedSubscription) {
      this.getLocationsSelectedSubscription.unsubscribe();
    }
  }

  ngOnInit() {

    // Set time windows for the registration table. For single locations limit to AM/PM (e.g. 9:00am and 1:00pm)
    this.timeWindows = this.isOnline ?
      ([
        new Date('1970/01/01 09:00:00'),
        new Date('1970/01/01 11:00:00'),
        new Date('1970/01/01 13:00:00'),
        new Date('1970/01/01 15:00:00'),
        new Date('1970/01/01 23:59:00')
      ]) :
      ([
        new Date('1970/01/01 13:00:00'),
        new Date('1970/01/01 23:59:00')
      ]);

    // Set isHybridOnly flag if we are forcing hybrid
    /*if (this.forceHybridOnly) {
      this.isHybridOnly = true;
    }*/

    // Get current geolocation info
    this.geolocationService.getObservable()
      .subscribe((value) => {
        this.geolocation = value;

        // Read the registrations
        this.readRegistrations();

        // Read the pathways
        this.readPathways();
    });

    // Watch query params - whenever they update check if we can update our selected week based on season
    this.activatedRoute.queryParamMap
      .pipe(skip(1)) // Skip initialization - this is handled after response from backend is returned
      .subscribe((params: ParamMap) => {
        if (params.get('season')) {
          this.selectedSeason = this.seasons.find((s: Season) => s.Code === params.get('season'));
          this.selectedSeasonChange.emit(this.selectedSeason);
          this.go2SelectedSeason();
        }
      });

    // Set the open classes switch
    if (this.activatedRoute.snapshot.queryParamMap.get('open')) {
      this.isOpenClassesOnly = (this.activatedRoute.snapshot.queryParamMap.get('open') === 'true');
    }

    // Set the filter model by pulling age and pathway from query params. Default to youngest age if not set.
    this.pathwayFilterModel.ageUid = parseInt(this.activatedRoute.snapshot.queryParamMap.get('age'), 10) || (this.isOnline ? this.defaultAgeFilter : null);
    this.pathwayFilterModel.pathwayId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('pathway'), 10);

    // Watch the selected locations (skip the first empty)
    this.getLocationsSelectedSubscription = this.inPersonRegistrationsSharingService.getLocationsSelected()
      .subscribe((locations: Location[]) => {
        this.locations = locations;
        if (this.locations.length) {
          this.readRegistrations();
        }
      });
  }

  classClicked(_class: any) {
    _class.isExpanded = !_class.isExpanded;
  }

  goSignup(session: any) {
    if (session.HasEnded || !this.window) {
      return;
    }

    if (session.Occupied) {
      const modalRef = this.modalService.open(DialogJoinWaitlistComponent, { size: 'lg', centered: true });
      modalRef.componentInstance.sessionId = session.LessonId;
      return;
    }

    if (session.IsExternalRegistration) {

      const modalRef = this.modalService.open(DialogConfirmComponent, { centered: true });
      if (!session.ExternalURL) {

        // Session is marked for external registrations but no external URL exists yet
        modalRef.componentInstance.buttonCancel = 'Close';
        modalRef.componentInstance.buttonSubmit = null;
        modalRef.componentInstance.header = 'Error!';
        modalRef.componentInstance.text = 'The registration page failed to open. Please contact us so we can fix it for you.';
      } else {
        modalRef.componentInstance.buttonSubmit = 'Continue';
        modalRef.componentInstance.header = 'Camp registration handled externally';
        // tslint:disable-next-line
        modalRef.componentInstance.text = 'Registration for this camp is handled through the location\'s website. Continuing will take you to their registration page.';

        // Process backend response once the dialog is closed with success
        modalRef.result
          .then(() => this.window.open(session.ExternalURL))
          .catch(() => {});
      }
    } else {

      /*const queryParams = {
        s: session.LessonId,
        c: this.geolocation.currency,
        d: this.discountCode
      };
      this.router.navigate(['checkout'], { queryParams: queryParams, queryParamsHandling: 'merge' });*/
      this.window.location.href = `/checkout?s=${session.LessonId}&c=${this.geolocation.currency}` + (this.discountCode ? `&d=${this.discountCode}` : '') + (this.partnerId ? `&p=${this.partnerId}` : '');
    }
  }

  /*isOnlineFilterChanged(newVal: boolean) {
    this.isHybridOnly = newVal;
    this.readRegistrations();
  }*/

  moveWeek(direction: string) {
    if ((direction === 'FORWARD' && this.weekForwardActive()) || (direction === 'REVERSE' && this.weekReverseActive())) {
      this.prepareAutoScroll();

      // While we haven't landed on a week
      this.selectedWeek += direction === 'FORWARD' ? 1 : -1;

      // Update query params so that the newly selected week is applied
      this.updateQueryParams();
      this.ngbUpdateModel();

      // Apply filter
      this.applyFilter();
    }
  }

  // Date was chosen in the ngb-datepicker. Find which week the day belongs to and set it as the selected week
  ngbDateSelected(dateStruct: NgbDateStruct) {
    const selectedDate = new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day);
    const newSelectedWeek = this.weeks.findIndex(week => (selectedDate >= week.monday) && (selectedDate <= week.friday));

    this.prepareAutoScroll();
    this.selectedWeek = newSelectedWeek;

    // Update query params so that the newly selected week is applied
    this.updateQueryParams();

    // Apply filter
    this.applyFilter();

    // Click the datepicker so it closes
    this.datePicker.nativeElement.click();
  }

  // We'll disable the date when it doesn't fall into any of our weeks that has camps
  ngbIsDisabled = (dateStruct: NgbDateStruct, current: {month: number}) => {
    const calendarDate = new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day);
    return this.weeks.findIndex(week => (calendarDate >= week.monday.setHours(0, 0, 0, 0)) && (calendarDate <= week.friday.setHours(0, 0, 0, 0))) === -1;
  }

  // Highlight the day in the ngb-datepicker when it falls within the currently selected week
  ngbShouldHighlightDay = (dateStruct: NgbDateStruct) => {
    const calendarDate = new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day);
    return this.selectedWeek && (calendarDate >= this.weeks[this.selectedWeek].monday.setHours(0, 0, 0, 0)) && (calendarDate <= this.weeks[this.selectedWeek].friday.setHours(0, 0, 0, 0));
  }

  openClassDetails(_class: any) {
    const modalRef = this.modalService.open(DialogClassDetailsComponent, { size: 'lg', centered: true });
    modalRef.componentInstance._class = _class;
  }

  openFutureSessionsDialog(_class: any, futureSessions: any[]) {

    // Open the future sessions dialog
    const modalRef = this.modalService.open(DialogFutureSessionsComponent, { size: 'lg', centered: true });
    modalRef.componentInstance._class = _class;
    modalRef.componentInstance.discountCode = this.discountCode;
    modalRef.componentInstance.geolocation = this.geolocation;
    modalRef.componentInstance.isOnline = this.isOnline;
    modalRef.componentInstance.sessions = futureSessions;
    modalRef.componentInstance.showProgramFormat = !this.isOnline;
  }

  scroll2StepOne() {
    if (isPlatformBrowser(this.platformId)) {
      this.document.getElementById('step-one').scrollIntoView({ behavior: 'smooth' });
    }
  }

  // Age filter was switched
  switchAgeFilter(pathwayAge: any) {

    // Update the filter model. If the age was already selected, deselect it
    this.pathwayFilterModel.ageUid = pathwayAge.uid === this.pathwayFilterModel.ageUid ? null : pathwayAge.uid;
    this.pathwayFilterModel.pathwayId = null; // Remove any selected pathway

    this.updateQueryParams();
    this.applyFilter();
  }

  setOpenClassesOnly(newVal: boolean) {
    this.isOpenClassesOnly = newVal;
    this.updateQueryParams();
    this.applyFilter();
  }

  setSeason(season: Season) {
    this.selectedSeason = season;
    this.selectedSeasonChange.emit(this.selectedSeason);
    this.go2SelectedSeason();
  }

  switchPathwayFilter(pathway: any) {

    if (pathway.IsDisabled) {

      // Pathway was disabled because it doesn't contain any classes for the given filter, exit!
      return;
    }

    // Update the filter model. If the pathway was already selected, deselect it
    this.pathwayFilterModel.pathwayId = pathway.PathwayId === this.pathwayFilterModel.pathwayId ? null : pathway.PathwayId;

    this.updateQueryParams();
    this.applyFilter();
  }

  switchWeekOpen(week: any) {
    week.isOpen = !week.isOpen;
  }

  weekForwardActive(): boolean {
    return this.selectedWeek < this.weeks.length - 1;
  }

  weekReverseActive(): boolean {
     return this.selectedWeek > 0;
  }

  private applyFilter() {

    if (!this.classes.length) {

      // There are no classes, no need to filter
      return;
    }

    // Start with all classes
    let _classes2Show = this.classes;
    let _classes2ShowMobile = structuredClone(this.classes);

    // Update the selected season
    this.selectedSeason = this.getSeason4SelectedWeek();

    if (this.selectedSeason) {
      this.selectedSeasonChange.emit(this.selectedSeason);
    }

    // If isOpenClassesOnly, only show the class if there are sessions for the current week - no more if there exists some future sessions (in the same season)
    if (this.isOpenClassesOnly) {
      _classes2Show = _classes2Show.filter(_class =>
        (_class.sessionsByWeek[this.selectedWeek].find(weekday => weekday.find(session => !session.IsEmpty)))); // ||
        // (_class.sessionsByWeekFuture[this.selectedWeek].filter(weekday => weekday.length && (!this.selectedSeason || weekday[0].SeasonCode === this.selectedSeason.Code)).length)));
    }

    // Check to see if any pathways should be disabled
    this.pathways.forEach(pathway => {
      pathway.IsDisabled = (this.pathwayAgesClass
        .filterClasses4PathwayAge(
          _classes2Show.filter(_class => _class.Pathways.findIndex(_pathway => _pathway.PathwayId === pathway.PathwayId) !== -1),
          (this.pathwayFilterModel.ageUid || null)
        ).length === 0);
      pathway.IsDisabledMobile = (this.pathwayAgesClass
        .filterClasses4PathwayAge(
          _classes2ShowMobile.filter(_class => _class.Pathways.findIndex(_pathway => _pathway.PathwayId === pathway.PathwayId) !== -1),
          (this.pathwayFilterModel.ageUid || null)
        ).length === 0);

      // Was a pathway selected and is no longer enabled due to filtering?
      /*if (pathway.IsDisabled && pathway.PathwayId === this.pathwayFilterModel.pathwayId) {
        this.pathwayFilterModel.pathwayId = null;
      }*/

      // Set the pathway icon
      pathway.IconFileShow = pathway.IsDisabled ?
        (this.pathwayFilterModel.pathwayId === pathway.PathwayId ? pathway.IconFileSelectedDisabled : pathway.IconFileDisabled) :
        (this.pathwayFilterModel.pathwayId === pathway.PathwayId ? pathway.IconFileSelected : pathway.IconFile);
      pathway.IconFileShowMobile = pathway.IsDisabledMobile ?
        (this.pathwayFilterModel.pathwayId === pathway.PathwayId ? pathway.IconFileSelectedDisabled : pathway.IconFileDisabled) :
        (this.pathwayFilterModel.pathwayId === pathway.PathwayId ? pathway.IconFileSelected : pathway.IconFile);
    });

    // Filter classes by the selected pathway
    _classes2Show = !this.pathwayFilterModel.pathwayId ? _classes2Show :
      _classes2Show.filter(_class => _class.Pathways.find(_pathway => _pathway.PathwayId === this.pathwayFilterModel.pathwayId));
    _classes2ShowMobile = !this.pathwayFilterModel.pathwayId ? _classes2ShowMobile :
      _classes2ShowMobile.filter(_class => _class.Pathways.find(_pathway => _pathway.PathwayId === this.pathwayFilterModel.pathwayId));

    // Filter classes by the selected pathway age
    _classes2Show = !this.pathwayFilterModel.ageUid ? _classes2Show :
      this.pathwayAgesClass.filterClasses4PathwayAge(_classes2Show, this.pathwayFilterModel.ageUid);
    _classes2ShowMobile = !this.pathwayFilterModel.ageUid ? _classes2ShowMobile :
      this.pathwayAgesClass.filterClasses4PathwayAge(_classes2ShowMobile, this.pathwayFilterModel.ageUid);

    // Remove classes that have no more camps now or in future
    _classes2Show = _classes2Show.filter(_class => (_class.sessionsByWeek[this.selectedWeek].find(timeFrame => timeFrame.find(session => !session.IsEmpty)) || _class.sessionsByWeekFuture[this.selectedWeek].find(timeFrame => timeFrame.length)));

    // Remove unselected full- or half-day classes
    // HEADS UP - we assume that full-day camps have specific classes different from half-day camps!
    /*_classes2Show = (this.isFullDay && this.isHalfDay) ? _classes2Show :
      _classes2Show.filter(_class => (this.isFullDay ? _class.IsFullDay : false) || (this.isHalfDay ? !_class.IsFullDay : false));*/

    // Update the classes to show with the filtered classes
    this.classes2Show = _classes2Show;
    this.classes2ShowMobile = _classes2ShowMobile;

    // Set display text so the user knows what is being filtered
    const pathwayAge = this.pathwayAgesClass.pathwayAge4Uid(this.pathwayFilterModel.ageUid);
    const agePartial = pathwayAge ? 'Ages ' + pathwayAge.ageMin + (pathwayAge.ageMax ? ('-' + pathwayAge.ageMax) : '+') : 'All ages';
    const pathwayPartial = this.pathwayFilterModel.pathwayId && this.pathways.length ?
      this.pathways.find(pathway => pathway.PathwayId === this.pathwayFilterModel.pathwayId).Name : 'All pathways';
    this.pathwayFilterModel.displayText = `${agePartial} / ${pathwayPartial}`;
    this.pathwayFilterModel.timeText = `All times in ${this.timezoneAbbr} ${this.getVisibleWeekdayNames()}`;

    // Reset telemetry
    this.isTelemetrySet = false;
  }

  // Given a season code return the minimum Week number for the visible sessions
  private getMinimumWeek4Season(seasonCode: string): number {

    if (!seasonCode) {
      return null;
    }

    let minWeek = null;

    for (let i = 0; i < this.classes.length; i++) {
      const _class = this.classes[i];

      for (let j = 0; j < _class.sessionsByWeek.length; j++) {
        const week = _class.sessionsByWeek[j];

        for (let k = 0; k < week.length; k++) {
          const timeSlot = week[k];

          for (let l = 0; l < timeSlot.length; l++) {
            const session = timeSlot[l];

            if (session.SeasonCode === seasonCode && !session.HasStarted) {

              // This session matches the season and hasn't started yet. Check if it's the minimum week
              minWeek = minWeek ? (session.Week < minWeek ? session.Week : minWeek) : session.Week;
            }
          }
        }
      }
    }

    // Return the minimum week for which we have session for and haven't started yet
    return minWeek;
  }

  // Given a season code return the minimum Week number for the visible sessions
  private getSeason4SelectedWeek(): Season {
    for (let i = 0; i < this.classes.length; i++) {
      const week = this.classes[i].sessionsByWeek[this.selectedWeek];
      if (!week) {
        continue;
      }

      for (let j = 0; j < week.length; j++) {
        const timeSlot = week[j];

        for (let k = 0; k < timeSlot.length; k++) {
          if (!timeSlot[k].IsEmpty) {
            return this.seasons.find((s: Season) => s.Code === timeSlot[k].SeasonCode);
          }
        }
      }
    }

    return null;
  }

  private getUniqueSeasons(allSessions: any[]) {

    // Pull the unique seasons from all sessions that haven't started yet
    this.seasons = allSessions.filter(s => !s.HasStarted)
      .map(s => ({
          ButtonColor: /*s.SeasonCode.endsWith('SUMMER') ? SeasonButtonColors.ORANGE : */SeasonButtonColors.YELLOW,
          Code: s.SeasonCode,
          DisplayName: s.SeasonName.split(' ').slice(1).join(' '),
          IsEarlyBird: s.IsEarlyBird,
          Name: s.SeasonName
        }))
      .filter((item, pos, self) => self.findIndex(v => v.Code === item.Code) === pos);

    // Emit the seasons
    //this.seasonsChange.emit(this.seasons);
  }

  private getUniqueWeeks(allSessions: any[]) {

    // Reset weeks (necessary in case currency changes)
    this.weeks = [];

    // IMPORTANT: sessions must be sorted so that the weeks array populates in the correct order
    const weeksProcessed = [];
    allSessions.sort((a, b) => a.StartUTC > b.StartUTC ? 1 : -1).forEach(session => {

      // Prepare session starting and ending times (used for in-person camps split into time slots)
      const localEndTime = new Date(session.EndUTC.getTime());
      const localStartTime = new Date(session.StartUTC.getTime());
      localEndTime.setFullYear(1970, 0, 1);
      localStartTime.setFullYear(1970, 0, 1);

      // If local end time is smaller than start time, we need to add one day
      if (localEndTime < localStartTime) {
        localEndTime.setDate(localEndTime.getDate() + 1);
      }

      if (!weeksProcessed.includes(session.Week)) {
        const localStart = new Date(session.SessionStartUTC.getTime());
        const monday = localStart.getDate() - localStart.getDay() + 1;
        this.weeks.push({
          monday: new Date(localStart.setDate(monday)),
          friday: new Date(localStart.setDate(localStart.getDate() + 4)),
          isEnabled: true,
          maxEnd: localEndTime, // For in-person locations, the latest end of a camp
          minEnd: session.IsFullDay ? new Date(localStartTime.getTime() + 3 * 3600000) : localEndTime, // For in-person locations, the earliest end of a camp; for full-day, do the split and return the min end 3 hours after the start
          maxStart: session.IsFullDay ? new Date(localEndTime.getTime() - 3 * 3600000) : localStartTime, // For in-person locations, the latest start of a camp; for full-day, do the split and return the max start 3 hours before the end
          minStart: localStartTime, // For in-person locations, the earliest start of a camp
          minSessionStart: session.SessionStartUTC,
          weekIndex: session.Week
        });
        weeksProcessed.push(session.Week);
      } else {

        // For in-person locations, we need to know the limits to correctly split camps into two parts
        const existingWeek = this.weeks.find(week => week.weekIndex === session.Week);
        if (existingWeek) {
          existingWeek.maxEnd = existingWeek.maxEnd > localEndTime ? existingWeek.maxEnd : localEndTime;
          existingWeek.minEnd = existingWeek.minEnd < localEndTime ? existingWeek.minEnd : localEndTime;
          existingWeek.maxStart = existingWeek.maxStart > localStartTime ? existingWeek.maxStart : localStartTime;
          existingWeek.minStart = existingWeek.minStart < localStartTime ? existingWeek.minStart : localStartTime;
          existingWeek.minSessionStart = existingWeek.minSessionStart.getTime() < session.SessionStartUTC.getTime() ? existingWeek.minSessionStart : session.SessionStartUTC;
        }
      }
    });

    // For in-person locations, prepare weekly limits
    if (!this.isOnline) {
      this.weeks.forEach(week => {

        // If the week has just one half-day slot, create another one slot
        if (week.minEnd > week.maxStart) {

          // There is just one slot with camps
          const breakPM = new Date('1970/01/01 13:00:00');

          // Get the week starting time
          const tDate = new Date(week.minStart.getTime());
          tDate.setFullYear(1970, 0, 1);
          if (tDate < breakPM) {

            // Make next starting slot one hour after the max end
            week.maxStart = new Date(week.maxEnd);
            week.maxStart.setTime(week.maxStart.getTime() + (60 * 60 * 1000));
            week.maxEnd = new Date(week.maxStart);
            week.maxEnd.setTime(week.maxEnd.getTime() + (3 * 60 * 60 * 1000));
          } else {

            // Make previous ending slot one hour before the min start
            week.minEnd = new Date(week.minStart);
            week.minEnd.setTime(week.minEnd.getTime() - (60 * 60 * 1000));
            week.minStart = new Date(week.minEnd);
            week.minStart.setTime(week.minStart.getTime() - (3 * 60 * 60 * 1000));
          }
        }
      });
    }
  }

  // For the selected week, get the weekday of the min session start and the weekday of the max session end
  private getVisibleWeekdayNames(): string {
    const starts = [];
    const ends = [];
    this.classes2Show.forEach(_class => {
      _class.sessionsByWeek[this.selectedWeek].forEach(timeFrame => {
        timeFrame.forEach(session => {
          if (!session.IsEmpty) {
            starts.push(session.SessionStartUTC);
            ends.push(session.SessionEndUTC);
          }
        });
      });
    });

    if (!starts.length) {
      return '';
    }

    const min = starts.reduce(function (a, b) { return a < b ? a : b; });
    const max = ends.reduce(function (a, b) { return a > b ? a : b; });
    return `${this.datePipe.transform(min, 'EEEE')} - ${this.datePipe.transform(max, 'EEEE')}`;
  }

  private go2SelectedSeason(): boolean {

    if (!this.selectedSeason || (this.selectedSeason === this.getSeason4SelectedWeek())) {
      return false;
    }

    // Find the first week index for the season
    const go2WeekIndex = this.getMinimumWeek4Season(this.selectedSeason.Code);

    return this.go2SelectedWeek(go2WeekIndex);
  }

  private go2SelectedWeek(weekIndex: number): boolean {

    // Get the index of this week in the weeks array
    const theSelectedWeek = this.weeks.findIndex(week => week.weekIndex === weekIndex);

    // If we found the selected week index, update it!
    if (theSelectedWeek !== -1) {
      this.selectedWeek = theSelectedWeek;

      // Update query params so that the newly selected week is applied
      this.updateQueryParams();
      this.applyFilter();

      // We were able to update the selected week for the season
      return true;
    }

    // Failed to find a week to switch to
    return false;
  }

  private ngbUpdateModel() {

    // Set the selected day for the datepicker
    this.ngbDPData.model = {
      year: this.weeks[this.selectedWeek].monday.getFullYear(),
      month: this.weeks[this.selectedWeek].monday.getMonth() + 1,
      day: this.weeks[this.selectedWeek].monday.getDate()
    };
  }

  private prepareAutoScroll() {

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // Get reference to the sticky header (we need to know the height)
    const headerElementSticky = this.document.querySelector('#header');
    for (let i = this.classes2Show.length - 1; i >= 0; i--) {

      // Get reference to the Class (camp) element
      const classElement = this.document.querySelector(`#classId-${this.classes2Show[i].ClassId}`);

      // Are we inside of the sticky header at all?
      if (classElement.getBoundingClientRect().top < (headerElementSticky.clientHeight + this.autoScrollOffset)) {

        // Store the ClassId. After we update the table, we'll scroll so this class (with ngAfterViewChecked) is at the top of the table
        this.scroll2ClassId = this.classes2Show[i].ClassId;

        // We found where we need to scroll to, leave
        break;
      }
    }
  }

  private processSession(session: any) {

    // If there are any exclusive discounts choose just the exclusive discounts
    // If there are more than one exclusive, choose the one with the largest Discount (we don't consider units here)
    const exclusiveDiscounts = session.Discounts.filter(d => d.IsExclusive);
    session.Discounts = !exclusiveDiscounts.length ? session.Discounts :
      [session.Discounts.find(d => d.Discount === Math.max.apply(Math, session.Discounts.map(d2 => d2.Discount)))];

    // Adjust the final price by immediately applicable discounts
    session.FinalPrice = session.Price - session.Discounts
      .map(discount => discount.Unit === 'CASH' ? discount.Discount : Math.floor(session.Price * discount.Discount / 100))
      .reduce((a, b) => a + b, 0);

    // Remove early bird discounts without expiration from the list so that we don't show it in popover
    session.Discounts2Show = session.Discounts.filter(discount => (discount.Code !== 'EARLYBIRD') || discount.ExpiresOn);

    // Are we occupied?
    session.Occupied = session.SpotsLeft === 0;

    // Prepare starting time by cloning the StartUTC timestamp and removing the date part
    session.StartAt = new Date(session.StartUTC.getTime());
    session.StartAt.setFullYear(1970, 0, 1);

    // Is this an AM or PM start?
    session.IsAM = session.SessionStartUTC.getHours() < 12;

    // Full- or half-day camp?
    session.IsFullDay = session.PriceCode === 'CAMP_FULL';
    session.IsHalfDay = session.PriceCode === 'CAMP_HALF';

    // Capitalize the first letter of the session info
    session.SessionInfo = session.SessionInfo && (session.SessionInfo.charAt(0).toUpperCase() + session.SessionInfo.substr(1));
  }

  private readPathways() {

    // Show spinner
    this.isReadingPathways = true;

    // Reset error status
    this.errorReadingPathways = '';

    this.onlineCampsDataService.readPathways(this.geolocation.currency)
      .subscribe(
        (value) => {
          this.pathways = value;

          // Set the disabled and selected image paths
          this.pathways.forEach(pathway => {
            pathway.IconFileDisabled = pathway.IconFile.substring(0, pathway.IconFile.length - 4) + '_disabled.png';
            pathway.IconFileSelected = pathway.IconFile.substring(0, pathway.IconFile.length - 4) + '_selected.png';
            pathway.IconFileSelectedDisabled = pathway.IconFile.substring(0, pathway.IconFile.length - 4) + '_selected_disabled.png';

            // Initially, show pathways as disabled
            pathway.IconFileShow = pathway.IconFileDisabled;
          });

          // Apply the filters
          this.applyFilter();

          // Done reading
          this.isReadingPathways = false;
        },
        (error) => {
          this.errorReadingPathways = error;
          this.isReadingPathways = false;
        }
      );
  }

  private readRegistrations() {

    if (this.isReading || !this.geolocation || (!this.isOnline && !this.locations.length)) {
      return;
    }

    // Show spinner
    this.isReading = true;

    // Reset error status
    this.errorReading = '';

    // Read the locations data
    this.onlineCampsDataService.readRegistrations(
        this.partnerId,
        this.status,
        this.geolocation.currency,
        this.discountCode,
        false, //this.isHybridOnly,
        this.locations ? this.locations.map(location => location.LocalityId).join(',') : null
      )
      .subscribe(
        (value) => {
          const campClasses = value;

          // Get all of the sessions from the classes
          let allCampSessions = [];
          campClasses.forEach(_class => {
            _class.Sessions.forEach(this.processSession);
            allCampSessions = allCampSessions.concat(_class.Sessions);
          });

          // Get the unique weeks from the sessions
          this.getUniqueWeeks(allCampSessions);

          // Get the unique seasons from the sessions
          this.getUniqueSeasons(allCampSessions);

          // For each class, create the structure of the sessions
          // Two dimensional array... First is WEEK next is TIME
          campClasses.forEach(_class => {

            // By default, the class is not full day
            _class.IsFullDay = false;

            // Sessions by week array must equal the length of our weeks. Inner array is the number of time windows
            _class.sessionsByWeek = [];
            _class.sessionsByWeekFuture = [];
            this.weeks.forEach(week => {
              _class.sessionsByWeek.push(Array.from(Array(this.timeWindows.length), _ => []));
              _class.sessionsByWeekFuture.push(Array.from(Array(this.timeWindows.length), _ => []));
            });

            // Get the week position
            _class.Sessions.forEach(session => {

              // Check if it is a full-day class
              if (session.IsFullDay) {
                _class.IsFullDay = true;
              }

              // Get week of the session
              let weekIndex;
              for (let i = 0; i < this.weeks.length; i++) {
                if (session.SessionStartUTC <= this.weeks[i].friday) {
                  weekIndex = i;
                  break;
                }
              }

              const timeEarliest = new Date('1970/01/01 04:00:00');
              const timeLatest = new Date('1970/01/01 23:59:00');

              // Get the session starting time
              const tDate = new Date(session.StartUTC.getTime());
              tDate.setFullYear(1970, 0, 1);

              // Check the limits
              if (tDate >= timeEarliest && tDate <= timeLatest) {

                // Find the correct time column and set the session
                if (this.isOnline) {
                  for (let i = 0; i < this.timeWindows.length; i++) {
                    if (tDate < this.timeWindows[i]) {
                      _class.sessionsByWeek[weekIndex][i].push(session);
                      break;
                    }
                  }
                } else {

                  // For physical locations, put full-day camps into the first timeframe
                  if (session.IsFullDay) {
                    _class.sessionsByWeek[weekIndex][0].push(session);
                  } else {
                    const localStart = new Date(session.SessionStartUTC.getTime());
                    localStart.setFullYear(1970, 0, 1);
                    const weekMaxStart = new Date(this.weeks[weekIndex].maxStart);
                    const i = (localStart < weekMaxStart) ? 0 : 1;
                    _class.sessionsByWeek[weekIndex][i].push(session);
                  }
                }
              }
            });

            // "Stack" the sessions for online camps
            if (this.isOnline) {
              for (let i = 0; i < _class.sessionsByWeek.length; i++) {
                for (let j = 0; j < _class.sessionsByWeek[i].length; j++) {
                  _class.sessionsByWeek[i][j] = this.stack(_class.sessionsByWeek[i][j]);
                }
              }
            }

            // Check if the session has any FUTURE session for the day
            for (let i = 0; i < _class.sessionsByWeek.length; i++) {
              const weekdays = _class.sessionsByWeek[i];

              for (let j = 0; j < weekdays.length; j++) {
                const weekday = weekdays[j];

                // This weekday doesn't have any sessions for the week, gather all of the future sessions for this day
                if (!weekday.length) {
                  for (let ii = i + 1; ii < _class.sessionsByWeek.length; ii++) {
                    const futureSameWeekday = _class.sessionsByWeek[ii][j];
                    if (futureSameWeekday.length) {
                      _class.sessionsByWeekFuture[i][j] = _class.sessionsByWeekFuture[i][j].concat(futureSameWeekday.filter(s => !s.HasStarted));
                    }
                  }
                }
              }
            }

            // Append empty sessions that should show up as empty slots (always show empty slots even if there are no sessions in timeframe except when we have full-day camps)
            _class.sessionsByWeek.forEach(week => {
              const maxFullDaySessionsInTimeFrame = Math.max(...week.map(timeFrame => timeFrame.filter(session => session.IsFullDay).length));
              const maxSessionsInTimeFrame = Math.max(maxFullDaySessionsInTimeFrame > 0 ? 0 : 1, Math.max(...week.map(timeFrame => timeFrame.filter(session => !session.IsFullDay).length)));
              week.forEach(timeFrame => {
                while (timeFrame.length < maxSessionsInTimeFrame) {
                  timeFrame.push({ IsEmpty: true });
                }
              });
            });

            // Prepare sessions by week for for mobile
            const now = new Date();
            _class.sessionsByWeekMobile = [];
            _class.Sessions
              .filter(session => session.SessionStartUTC > now)
              .sort((a, b) => ((a.Sessions === b.Sessions) ? (a.SessionStartUTC > b.SessionStartUTC) : (a.Sessions < b.Sessions)) ? 1 : -1)
              .forEach(session => {
                if (!_class.sessionsByWeekMobile[session.Week]) {
                  _class.sessionsByWeekMobile[session.Week] = {
                    displayDate: session.SessionStartUTC,
                    isOpen: false,
                    offersAM: false,
                    offersPM: false,
                    sessions: []
                  };
                }

                // Add the session to the week index it belongs to
                _class.sessionsByWeekMobile[session.Week].sessions.push(session);
              });

            // For physical locations prepare flags specifying whether week offers AM/PM sessions
            if (!this.isOnline) {
              _class.sessionsByWeekMobile.forEach(week => {
                week.offersAM = !!week.sessions.find(session => session.IsAM || session.IsFullDay);
                week.offersPM = !!week.sessions.find(session => !session.IsAM || session.IsFullDay);
              });
            }

            // Re-index arrays and remove unneeded data to keep everything clean
            _class.sessionsByWeekMobile = _class.sessionsByWeekMobile.filter(val => val);
            delete _class.Sessions;
          });

          // Set the processed classes
          this.classes = campClasses;

          // Set the selected week for the calendar
          if (this.weeks.length) {
            this.setSelectedWeek();

            // Set the min and max dates for the datepicker
            this.ngbDPData.minDate = {
              year: this.weeks[0].monday.getFullYear(),
              month: this.weeks[0].monday.getMonth() + 1,
              day: this.weeks[0].monday.getDate()
            };
            this.ngbDPData.maxDate = {
              year: this.weeks[this.weeks.length - 1].friday.getFullYear(),
              month: this.weeks[this.weeks.length - 1].friday.getMonth() + 1,
              day: this.weeks[this.weeks.length - 1].friday.getDate()
            };
          }

          this.applyFilter();

          // Done reading & processing
          this.isReading = false;
        },
        (error) => this.errorReading = error,
        () => this.isReading = false
      );
  }

  /*
   * Set the selected week in the calendar. This is called only after the response from backend is processed
   *
   * The week is set by the following rules, in this order:
   *   - Season is set in query params, try to go to the first upcoming week of that season
   *   - Week is set in query params, try to go to that week
   *   - Default to the most upcoming week (upcoming week moves on Tuesdays at 2am UTC. We leave a whole day for Monday registrations to come in)
   */
  private setSelectedWeek() {

    // Do we have a season in the query params?
    if (this.activatedRoute.snapshot.queryParamMap.get('season')) {

      // Set the season
      this.selectedSeason = this.seasons.find((s: Season) => s.Code === this.activatedRoute.snapshot.queryParamMap.get('season'));
      this.selectedSeasonChange.emit(this.selectedSeason);

      // try to go to the selected season. If successful, exit, otherwise continue
      if (this.go2SelectedSeason()) {
        return;
      }
    }

    // Do we have a week in the query params? If it's a week that exists move to it and exit. Otherwise continue.
    const savedWeekIndex = this.weeks
      .findIndex(week => week.weekIndex === parseInt(this.activatedRoute.snapshot.queryParamMap.get('w'), 10));
    if (savedWeekIndex !== -1) {
      this.selectedWeek = savedWeekIndex;
      return;
    }

    // By default, start with the first week
    this.selectedWeek = 0;

    // Get now
    const now = new Date();

    // Go through all of our weeks in the calendar
    this.weeks.forEach(week => {

      // If we're passed the last week, move the selected week forward by 1
      if ((now > week.friday) && (this.selectedWeek < (this.weeks.length - 2))) {
        this.selectedWeek++;
      }
    });

    // Create the threshold (date in which we'll move forward a week)
    // Currently this is set to starting day @ 7pm PT (next day after starting day 2am UTC)
    // Starting day could be later in the week if it is a special occasion (e.g. teachers strike).
    const threshold = new Date(this.weeks[this.selectedWeek].minSessionStart.getTime());
    threshold.setHours(this.weekThresholdHoursUTC, this.weekThresholdMinutesUTC, 0, 0);

    // Move the day to the next weekThresholdDayUTC (e.g. Tuesday)
    const desiredWeekday = this.weekThresholdDayUTC;
    const currentWeekday = threshold.getDay();
    const distance = ((desiredWeekday - currentWeekday) + 7) % 7;
    threshold.setDate(threshold.getDate() + distance);

    // Are we past our threshold of switching to the next week? If so and possible, move forward one week
    this.selectedWeek += ((now > threshold) && (this.weeks.length - 2) >= 0) ? 1 : 0;

    // Hot fix to prevent page from loading on week of 6/8
    // If the selected week is 6/8 and we have ANY camp after 4/27, move forward by one week
    /*if (this.weeks[this.selectedWeek].weekIndex === 24 && this.selectedWeek <= (this.weeks.length - 2)) {
      this.selectedWeek++;
    }*/

    // Update the datepicker model
    this.ngbUpdateModel();
  }

  private setTelemetry() {
    this.telemetryService.inject('CLICK', 'age-switched-', null, true);
    this.telemetryService.inject('CLICK', 'calendar-forward', null, true);
    this.telemetryService.inject('CLICK', 'calendar-reverse', null, true);
    this.telemetryService.inject('CLICK', 'next-classes-', null, true);
    this.telemetryService.inject('CLICK', 'open-class-details-', null, true);
    this.telemetryService.inject('CLICK', 'pathway-switched-', null, true);
    this.telemetryService.inject('CLICK', 'session-', null, true);
    this.isTelemetrySet = true;
  }

  /*
   * Prepare sessions for one class and a week.
   */
  private stack(sessions: any[]) {
    if (!sessions.length) {
      return sessions;
    }

    // Group sessions by period
    sessions = sessions.reduce(function (r, a) {
      const period = '' + (a.SessionStartUTC.getDay() + 1) + a.SessionStartUTC.getHours() + a.SessionStartUTC.getMinutes() + (a.SessionEndUTC.getDay() + 1) + a.SessionEndUTC.getHours() + a.SessionEndUTC.getMinutes();
      r[period] = r[period] || [];
      r[period].push(a);
      return r;
    }, Object.create(null));

    // Convert associative array to normal so we can easily iterate
    sessions = Object.keys(sessions).map(k => sessions[k]);

    // Sort groups by number of days and starting time (always take the first session in the group)
    sessions.sort((a, b) => ((a[0].Sessions === b[0].Sessions) ? (a[0].SessionStartUTC > b[0].SessionStartUTC) : (a[0].Sessions < b[0].Sessions)) ? 1 : -1);

    // Init the final array of sessions we're going to return (only 1 of each StartAt)
    const finalSessions = [];
    sessions.forEach(groupedStart => {

      // Sort by occupied and last spots
      groupedStart.sort((a, b) => {
        if (a.Occupied === b.Occupied) {
          return a.SpotsLeft - b.SpotsLeft;
        }
        return a.Occupied > b.Occupied ? 1 : -1;
      });

      // We only show the top of the stack!
      const item2Display = groupedStart[0];

      // SpotsLeft should be a sum of all of the sessions in the stack (as to not mislead customers)
      item2Display.SpotsLeft = groupedStart.reduce((prev, next) => prev + next.SpotsLeft, 0);

      // Set length of the stack (currently only used as a flag to show stack image)
      item2Display.NumberStacked = groupedStart.length - 1;

      // Set the number of occupied sessions
      item2Display.NumberOccupied = groupedStart.filter(session => session.Occupied).length + (item2Display.Occupied ? -1 : 0);
      finalSessions.push(item2Display);
    });

    return finalSessions;
  }

  private updateQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        age: (this.pathwayFilterModel.ageUid || null),
        open: this.isOpenClassesOnly,
        pathway: (this.pathwayFilterModel.pathwayId || null),
        season: null,
        w: this.weeks[this.selectedWeek].weekIndex
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }
}
