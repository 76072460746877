<!-- AGE BUTTONS -->
<div [hidden]="showAllClasses" class="step-label my-3 cwk-orange-bg cwk-white px-3 py-2"><b>Step 1:</b> Select Age</div>

<!-- Ages for non-private classes (age spans) -->
<div class="fix-row mb-3">
  <div *ngFor="let age of (isPrivateClasses ? codersLadderAges.AGES_PRIVATE : codersLadderAges.AGES)" class="col-3">
    <div id="{{ 'age-switched-' + age.ageMin + (age.ageMax ? ('-' + age.ageMax) : '+') }}" class="age-btn" [ngClass]="{'selected' : age.uid === selectedAge?.uid}" (click)="setSelectedAge(age)">
      {{ age.ageMin }}{{ age.ageMax ? ('-' + age.ageMax) : '+' }}
    </div>
  </div>
</div>

<!-- CODERS LADDER OR CODERS PATHWAY BUTTONS -->
<div [hidden]="showAllClasses" class="step-label my-3 cwk-orange-bg cwk-white px-3 py-2"><b>Step 2:</b> Select Coder’s Ladder<sup>&reg;</sup> <span *ngIf="isPrivateClasses">or Coder’s Pathways<sup>&reg;</sup></span> Class</div>

<!-- CL Classes -->
<div *ngIf="isPrivateClasses" class="my-3 border-bottom pb-2">CODER’S LADDER<span class="cwk-registration-icon">&reg;</span> CLASSES</div>
<div *ngIf="isReading" class="cwk-loading">
  <fa-icon class="me-2" [icon]="faSpinner" animation="spin"></fa-icon>Loading...
</div>
<div class="fix-row g-0">
  <div class="col-2 col-md-1 text-center cwk-pointer" *ngFor="let _class of filterClasses('CL')">
    <div class="pathway-icon">
      <div class="class-icon-1-1">
        <div id="{{ 'level-switched-' + _class.LevelCode }}" class="class-icon d-flex align-items-center justify-content-center" [ngClass]="{'disabled' : _class.IsDisabled, 'class-selected' : _class.ClassId === selectedClass?.ClassId}" (click)="!_class.IsDisabled && setSelectedClass(_class)">
          {{ _class.LevelCode }}
        </div>
      </div>
    </div>
    <div class="pathway-name">{{ _class.ClassName }}</div>
  </div>
</div>

<!-- "Pathway classes" for Private classes -->
<ng-container *ngIf="isPrivateClasses">
  <div class="my-3 border-bottom pb-2">CODER’S PATHWAYS<span class="cwk-registration-icon">&reg;</span> CLASSES</div>
  <div *ngIf="isReading" class="cwk-loading">
    <fa-icon class="me-2" [icon]="faSpinner" animation="spin"></fa-icon>Loading...
  </div>
  <div class="fix-row g-0">
    <div class="col-2 col-md-1 text-center cwk-pointer" *ngFor="let pathway of filterClasses('PATHWAY')" [ngClass]="{'not-allowed' : pathway.IsDisabled}">
      <div id="{{ 'pathway-switched-' + pathway.Name }}" class="pathway-icon" (click)="!pathway.IsDisabled && setSelectedClass(pathway)">
        <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathways/{{pathway.ImagePath | slice:0:pathway.ImagePath.length-4}}{{ pathway.IsDisabled ? '_disabled' : '' }}{{ pathway.ClassId === selectedClass?.ClassId ? '_selected' : '' }}.png" alt="{{ pathway.ClassName }}" loading="lazy">
      </div>
      <div class="pathway-name">{{ pathway.Name }}</div>
    </div>
  </div>
</ng-container>

<!-- MOBILE: Class description -->
<div *ngIf="showClassInfo && selectedClass" class="my-3 border p-2 d-block d-lg-none">
  <div [hidden]="showAllClasses" class="cwk-pointer">
    <div class="d-flex justify-content-center my-2" [ngClass]="{'border-bottom pb-3' : isExpanded}" (click)="switchIsExpanded()">
      <h5 class="my-0"><b>{{ selectedClass.ClassName }} Course Info</b></h5>
      <fa-icon class="ms-3" [icon]="isExpanded ? faMinusCircle : faPlusCircle" size="lg"></fa-icon>
    </div>
  </div>
  <div *ngIf="isExpanded || showAllClasses" class="mt-3" [@slideInOut]>
    <ng-container *ngTemplateOutlet="description; context: { size: 'MOBILE' }"></ng-container>
  </div>
</div>

<!-- DESKTOP: Class description -->
<div class="d-none d-lg-block my-5">
  <ng-container *ngTemplateOutlet="description; context: { size: 'DESKTOP' }"></ng-container>
</div>

<!-- Selected course description -->
<ng-template #description let-size="size">
  <div *ngIf="showClassInfo && selectedClass" class="mb-5" [@slideInOut]>

    <!-- Title and description -->
    <h4 *ngIf="selectedClass.Description" class="cwk-orange mb-3"><b>{{ selectedClass.ClassName }}</b> Course Info</h4>

    <!-- Description (for classes with videos) -->
    <div *ngIf="!selectedClass.VideoURL">
      <ng-container *ngTemplateOutlet="descriptionText; context: { size: size }"></ng-container>
    </div>

    <!-- Coder's Ladder class -->
    <div *ngIf="!selectedClass.PathwayId">

      <!-- Template for classes WITH videos -->
      <div *ngIf="selectedClass.VideoURL" class="fix-row">

        <!-- Left column: video and description -->
        <div class="col-lg-6 mb-4 mb-lg-0">
          <ng-container *ngTemplateOutlet="descriptionText; context: { size: size }"></ng-container>
          <home-embed-video [videoSrc]="selectedClass.VideoURL" [videoTitle]="selectedClass.VideoTitle"></home-embed-video>
        </div>

        <!-- Right column: Tools, languages, pre reqs, tech reqs, graduation criteria -->
        <div class="col-lg-6">
          <h5 *ngIf="selectedClass.Tools" class="cwk-orange mb-2">Tools/Languages</h5>
          <p *ngIf="selectedClass.Tools">{{ selectedClass.Tools }}</p>

          <h5 *ngIf="selectedClass.Prerequisity" class="cwk-orange mb-2">Prerequisites</h5>
          <p [innerHTML]="selectedClass.Prerequisity"></p>

          <!-- Tech Requirements (TODO - this should be read from DB in future) -->
          <h5 class="cwk-orange mt-2">Technical Requirements</h5>
          <ul>
            <li>Chromebook, Mac, or Windows PC (Windows only for Level 6 and Level H2)</li>
            <li>Internet</li>
            <li>Speakers, microphone and webcam</li>
          </ul>

          <h5 *ngIf="selectedClass.GraduationCriteria" class="cwk-orange mt-2">Graduation criteria</h5>
          <!-- FOR UNKNOWN REASONS, If p TAG IS USED, IT DOUBLES THE CONTENT ON REFRESH -->
          <div [innerHTML]="selectedClass.GraduationCriteria"></div>
        </div>
      </div>

      <!-- Template for classes WITHOUT videos -->
      <div *ngIf="!selectedClass.VideoURL" class="fix-row">

        <!-- Left column, Tools, pre-reqs, and tech requirements -->
        <div class="col-lg-4 mb-4 mb-lg-0">
          <h5 *ngIf="selectedClass.Tools" class="cwk-orange mb-2">Tools/Languages</h5>
          <p *ngIf="selectedClass.Tools">{{ selectedClass.Tools }}</p>

          <h5 *ngIf="selectedClass.Prerequisity" class="cwk-orange mb-2">Prerequisites</h5>
          <p [innerHTML]="selectedClass.Prerequisity"></p>

          <!-- Tech Requirements (TODO - this should be read from DB) -->
          <h5 class="cwk-orange">Technical Requirements</h5>
          <ul>
            <li>Chromebook, Mac, or Windows PC (Windows only for Level 6 and Level H2)</li>
            <li>Internet</li>
            <li>Speakers, microphone and webcam</li>
          </ul>
        </div>

        <!-- Center column, graduation criteria -->
        <div class="col-lg-4">
          <h5 *ngIf="selectedClass.GraduationCriteria" class="cwk-orange mb-2">Graduation criteria</h5>
          <!-- FOR UNKNOWN REASONS, If p TAG IS USED, IT DOUBLES THE CONTENT ON REFRESH -->
          <div [innerHTML]="selectedClass.GraduationCriteria"></div>
        </div>

        <!-- Right column, CL image -->
        <div class="col-lg-4">
          <img class="img-fluid" [src]="'https://d3t4xfu733v2tb.cloudfront.net/web/coders-ladder/highlighted-rung/' + selectedClass.LevelCode + '.png'" alt="{{ selectedClass.ClassName }}" width="540" height="460" loading="eager" />
        </div>
      </div>
    </div>

    <!-- Pathway class -->
    <div *ngIf="selectedClass.PathwayId">

      <!-- Tools -->
      <h5 *ngIf="selectedClass.Tools" class="cwk-orange mb-2">Tools/Languages</h5>
      <p *ngIf="selectedClass.Tools" class="text-justify">{{ selectedClass.Tools }}</p>

      <!-- Pre-reqs -->
      <h5 *ngIf="selectedClass.Prerequisity" class="cwk-orange mb-2">Prerequisites</h5>
      <p [innerHTML]="selectedClass.Prerequisity" class="text-justify"></p>

      <!-- Tech Requirements (TODO - this should be read from DB) -->
      <h5 class="cwk-orange">Technical Requirements</h5>
      <ul>
        <li>Mac or Windows PC (Windows only for Minecraft Modding, Roblox, and Core Programming Pathways)</li>
        <li>Internet</li>
        <li>Speakers, microphone and webcam</li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #descriptionText let-size="size">

  <!-- Description -->
  <div [id]="'description-' + size + '-' + selectedClass.ClassId" [innerHTML]="selectedClass.Description" class="description-clamp" [ngStyle]="{'-webkit-line-clamp' : selectedClass.IsDescriptionExpanded ? MAX_SAFE_INTEGER : DESCRIPTION_LINES}"></div>

  <!-- Show more/less -->
  <span *ngIf="selectedClass.IsDescriptionExpanded || isDescriptionClamped(size)" class="py-3 pe-3 d-inline-block cwk-link cwk-blue-link" (click)="switchClassDescription()">{{ selectedClass.IsDescriptionExpanded ? 'Show less' : 'Show more' }}...</span>

  <!-- Orange bar -->
  <div class="mx-auto mini-orange-bar mb-4" [ngClass]="{'mt-4' : (!selectedClass.IsDescriptionExpanded && !isDescriptionClamped(size))}"></div>
</ng-template>

<!-- Error reading -->
<cwk-error-alert *ngIf="errorReading" [error]="errorReading"></cwk-error-alert>
