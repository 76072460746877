import { Directive, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewContainerRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[sharedNoPrerender]',
  standalone: false
})
export class NoPrerenderDirective implements OnInit {
  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {

      // Create the embedded view if on the client
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {

      // Clear the content if on the server
      this.viewContainer.clear();
    }
  }
}
