import { AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnDestroy, ViewChild} from '@angular/core';
import { animate, group, state, style, trigger, transition, useAnimation } from '@angular/animations';
import { environment } from 'environments/environment';
import { DialogClassDetailsComponent } from '../dialog-class-details/dialog-class-details.component';
import { DialogExperiencedConfirmComponent } from './components/dialog-experienced-confirm/dialog-experienced-confirm.component';
import { DialogJoinWaitlistComponent } from '@shared/components/dialog-join-waitlist/dialog-join-waitlist.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnlineCodersLadderRegistrationListDataService } from './services/online-coders-ladder-registration-list-data.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TelemetryService } from '@shared/services/telemetry.service';
import { WINDOW } from '@shared/services/window.service';

// Animations
import { slideInOut } from '@shared/animations/slide-in-out';

// Icons
import { faCheckSquare, faChevronDown, faChevronLeft, faChevronRight, faFireAlt, faFrown, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faSquare as faSquareO } from '@fortawesome/free-regular-svg-icons';

@Component({
  animations: [slideInOut],
  selector: 'home-online-coders-ladder-registration-list',
  templateUrl: './online-coders-ladder-registration-list.component.html',
  styleUrls: ['./online-coders-ladder-registration-list.component.scss', '../../styles/registration-lists.scss'],
  standalone: false
})
export class OnlineCodersLadderRegistrationListComponent implements AfterViewChecked, AfterViewInit {

  @Input() discountCode: string;
  @Input() geolocation: any;
  @Input() isPrivateClasses = false;
  @ViewChild('header', {static: false}) headerElement: ElementRef;
  @ViewChild('top', {static: false}) topElement: ElementRef;

  classes = [];
  classes2Show = [];
  errorReading = '';
  errorReadingSpotsLeft = '';
  experiencedMessage: string = null;
  filters = {
    isNotExperiencedOnly: false,
    isStartingWithinFourWeeks: false,
    staffId: null,
  };
  headerOffsetTop: number;
  isHeaderSticky = false;
  isReading = false;
  isReadingSpotsLeft = false;
  leaders: any[] = [];
  selectedClass: any = null;
  timezoneAbbr = new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];
  unprocessedData = null;
  weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  // Subscriptions
  private readRegistrationsSubscription: Subscription;
  private readSpotsLeftSubscription: Subscription;

  readonly STICKY_PAGE_HEADING_HEIGHT = 41;

  // Icons
  faCheckSquare = faCheckSquare;
  faChevronDown = faChevronDown;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faFireAlt = faFireAlt;
  faFrown = faFrown;
  faInfoCircle = faInfoCircle;
  faSpinner = faSpinner;
  faSquareO = faSquareO;

  @HostListener('window:scroll', []) onWindowScroll() {
    this.isHeaderSticky = (this.window && this.window.pageYOffset > this.headerOffsetTop - this.STICKY_PAGE_HEADING_HEIGHT);
  }

  constructor(
    private modalService: NgbModal,
    private onlineCodersLadderRegistrationListDataService: OnlineCodersLadderRegistrationListDataService,
    private router: Router,
    private telemetryService: TelemetryService,
    @Inject(WINDOW) private window: Window | null
  ) { }

  ngAfterViewChecked() {

    // Get header offset from top
    if (this.headerElement && !this.isHeaderSticky) {
      this.headerOffsetTop = this.headerElement.nativeElement.offsetTop;
    }
  }

  ngAfterViewInit() {

    // Get header offset from top
    if (this.headerElement) {
      this.headerOffsetTop = this.headerElement.nativeElement.offsetTop;
    }
  }

  ngOnDestroy() {

    // Unsubscribe
    if (this.readRegistrationsSubscription) {
      this.readRegistrationsSubscription.unsubscribe();
    }
    if (this.readSpotsLeftSubscription) {
      this.readSpotsLeftSubscription.unsubscribe();
    }
  }

  classClicked(_class: any) {
    _class.isExpanded = !_class.isExpanded;
  }

  getSessionEnd(session: any, weekday: number) {

    if (!session.IsMultiday) {
      return session.EndUTC;
    } else {

      return session.WeekDayEndsUTC.find(timestamp => (timestamp.getDay() + 6) % 7 === weekday);
    }
  }

  getSessionStart(session: any, weekday: number) {

    if (!session.IsMultiday) {
      return session.StartUTC;
    } else {

      return session.WeekDayStartsUTC.find(timestamp => (timestamp.getDay() + 6) % 7 === weekday);
    }
  }

  goSignup(session: any, _class: any) {
    if (session.HasEnded || this.isReadingSpotsLeft) {
      return;
    }

    if (session.Occupied) {
      const modalRef = this.modalService.open(DialogJoinWaitlistComponent, { size: 'lg', centered: true });
      modalRef.componentInstance.sessionId = session.LessonId;
      return;
    }

    if (session.IsExperienced) {
      const modalRef = this.modalService.open(DialogExperiencedConfirmComponent, { centered: true });
      modalRef.componentInstance.message = _class.ExperiencedMessage;

      // Process backend response once the dialog is closed with success
      modalRef.result
        .then(() => this.goSignupFinish(session))
        .catch(() => {});
    } else {
      this.goSignupFinish(session);
    }
  }

  goSignupFinish(session: any) {

    if (!this.window) {
      return;
    }

    /*const queryParams: any = {
      s: session.SessionIds.join(),
      c: this.geolocation.currency
    };

    // If instructor was filtered add the StaffId into query params
    if (this.filters.staffId) {
      queryParams.i = this.filters.staffId;
    }
    this.router.navigate(['checkout'], { queryParams: queryParams, queryParamsHandling: 'merge' });*/
    this.window.location.href = `/checkout?s=${session.SessionIds.join()}&c=${this.geolocation.currency}` + (this.discountCode ? `&d=${this.discountCode}` : '') + (this.filters.staffId ? `&i=${this.filters.staffId}` : '');
  }

  highlightSessionDays (session: any, status: boolean) {
    session.isHover = status;
  }

  onClassChange(selectedClass) {
    this.filters.staffId = null; // Clear any filtered staff
    this.selectedClass = selectedClass;
    this.isReading = true;
    this.readRegistrations();
  }

  openClassDetails(_class: any) {
    const modalRef = this.modalService.open(DialogClassDetailsComponent, { size: 'lg', centered: true });
    modalRef.componentInstance._class = _class;
  }

  setFilterIsNotEperiencedOnly() {
    this.filters.isNotExperiencedOnly = !this.filters.isNotExperiencedOnly;
    this.processClasses();
  }

  setFilterIsStartingWithinFourWeeks() {
    this.filters.isStartingWithinFourWeeks = !this.filters.isStartingWithinFourWeeks;
    this.processClasses();
  }

  // Helper function to group by [IsExperienced,StartAt]
  private groupSessions4Weekday(arr) {
    const helper = {};
    return arr.reduce(function(r, o) {
      const key = o.IsExperienced + '-' + o.StartAt;
      if (!helper[key]) {
        helper[key] = [Object.assign({}, o)];
        r.push(helper[key]);
      } else {
        helper[key].push(o);
      }
      return r;
    }, []);
  }

  private mapSpotsLeft2Session(classesWSpotsLeftData) {

    // Go through all of the classes
    for (let i = 0; i < classesWSpotsLeftData.length; i++) {

      // Go through all of the sessions in the class
      if (this.unprocessedData[i] && this.unprocessedData[i].Sessions) {
        for (let j = 0; j < this.unprocessedData[i].Sessions.length; j++) {

          // Copy over the SpotsLeft data and set the Occupied flag
          const _session = classesWSpotsLeftData[i].Sessions
            .find(session => session.LessonId === this.unprocessedData[i].Sessions[j].LessonId);
          this.unprocessedData[i].Sessions[j].SpotsLeft = _session ? _session.SpotsLeft : this.unprocessedData[i].Sessions[j].HeadLimit;
          this.unprocessedData[i].Sessions[j].Occupied = this.unprocessedData[i].Sessions[j].SpotsLeft <= 0;
        }
      }
    }

    // Reprocess the classes
    this.processClasses();

    // Set telemetry after the next digest
    setTimeout(() => this.setTelemetry(), 0);
  }

  leaderFilterChanged() {
    this.processClasses();
  }

  private processClasses(isFirstRun = false) {

    // Create a fresh copy of the data to process
    const centerClassesCopy =  [...this.unprocessedData];

    // Get all of the sessions from each class
    let allSessions = [];
    centerClassesCopy.forEach(_class => _class.Sessions.forEach(session => allSessions.push(session)));

    // Process all sessions
    allSessions.forEach(session => this.processSession(session));

    // Filter starting within two weeks (NOTE: filtering is done before unification)
    if (this.filters.isStartingWithinFourWeeks) {
      allSessions = allSessions.filter(session => session.IsStartingWithinFourWeeks);
    }

    // Filter starting only experienced (NOTE: filtering is done before unification)
    if (this.filters.isNotExperiencedOnly) {
      allSessions = allSessions.filter(session => !session.IsExperienced);
    }

    // Unify all sessions (for all classes) at once (Session.Code could be shared across classes)
    allSessions = this.unify(allSessions);

    // If not set yet, set the unique leaders
    if (isFirstRun && this.isPrivateClasses) {
      this.setUniqueLeaders(allSessions);
    }

    // Filter any staff
    if (this.filters.staffId) {
      allSessions = allSessions.filter(session => session.Leaders.map(leader => leader.StaffId).includes(this.filters.staffId));
    }

    // Group center class sessions by weekday
    centerClassesCopy.forEach(_class => {

      // Set the processed & unified sessions for the class (since we had to unify across ALL sessions)
      _class.UnifiedSessions = allSessions.filter(session => session.ClassId === _class.ClassId);

      _class.SessionsByDay = [];
      _class.SessionsByMoreDays = [];
      [0, 1, 2, 3, 4, 5, 6].forEach(weekday => {

        // Get the sessions for this weekday. Use StartOn to make sure we get any shifts from timezone conversions!
        let sessions4Weekday = _class.UnifiedSessions.filter(session =>
          (session.LocalWeekDays.length === 1) &&
          (-1 !== session.LocalWeekDays.indexOf(weekday)));
        const sessions4MoreWeekdays = _class.UnifiedSessions.filter(session =>
          (session.LocalWeekDays.length > 1) &&
          (-1 !== session.LocalWeekDays.indexOf(weekday)));

        // Set a flag whether to show the sessions with more days at all
        _class.ShowMoreDaysSessions = _class.ShowMoreDaysSessions || sessions4MoreWeekdays.length;

        // "Stack" sessions that we only have one of each stating time
        sessions4Weekday = this.stack(sessions4Weekday);

        // We don't "stack" sessions taking place more times a week but we still need to sort!
        sessions4MoreWeekdays.sort((a, b) => b.StartUTC < a.StartUTC ? 1 : -1);

        // Add the sessions to the weekday!
        _class.SessionsByDay.push(sessions4Weekday);
        _class.SessionsByMoreDays.push(sessions4MoreWeekdays);

        // Set the experienced message
        this.experiencedMessage = _class.ExperiencedMessage;

        // Default to expanded for mobile
        _class.isExpanded = true;
      });
    });

    // Set the processed classes
    this.classes = centerClassesCopy;
    this.classes2Show = centerClassesCopy;
  }

  private processSession(session: any) {

    if (session.isProcessed) {

      // Already processed, just leave
      return;
    }

    // If there are any exclusive discounts choose just the exclusive discounts
    // If there are more than one exclusive, choose the one with the largest Discount (we don't consider units here)
    const exclusiveDiscounts = session.Discounts.filter(d => d.IsExclusive);
    session.Discounts = !exclusiveDiscounts.length ? session.Discounts :
      [session.Discounts.find(d => d.Discount === Math.max.apply(Math, session.Discounts.map(d2 => d2.Discount)))];

    session.FinalPrice = session.Price - session.Discounts
      .map(discount => discount.Discount)
      .reduce((a, b) => a + b, 0);

    // Prepare starting time by cloning the StartUTC timestamp and removing the date part
    session.StartAt = new Date(session.StartUTC.getTime());
    session.StartAt.setFullYear(1970, 0, 1);

    // Set weekdays to show the session correctly within the table
    session.LocalWeekDays = [];
    if (!session.IsMultiday) {

      // The single week day session
      session.LocalWeekDays = [session.StartUTC.getDay() === 0 ? 6 : session.StartUTC.getDay() - 1];
    } else {

      // For multiday sessions, go through all different day starts
      session.WeekDayStartsUTC.forEach(timestamp => session.LocalWeekDays.push((timestamp.getDay() + 6) % 7));
    }

    // Set flag to mark session as processed
    session.isProcessed = true;
  }

  private readSpotsLeft() {

    // Unsubscribe from existing subscription if one exists
    if (this.readSpotsLeftSubscription) {
      this.readSpotsLeftSubscription.unsubscribe();
    }

    // Show spinner
    this.isReadingSpotsLeft = true;

    // Reset error status
    this.errorReadingSpotsLeft = '';

    // Read the spots left data
    this.readSpotsLeftSubscription = this.onlineCodersLadderRegistrationListDataService
      .readSpotsLeft(this.selectedClass.ClassId, this.isPrivateClasses ? 1 : 0, this.geolocation.currency)
      .subscribe(
        (value) => {

            // Inject the reservations data into classes
            this.mapSpotsLeft2Session(value);

            // Done reading & processing
            this.isReadingSpotsLeft = false;
        },
        (error) => {
            this.errorReadingSpotsLeft = error;
            this.isReadingSpotsLeft = false;
        }
      );
  }

  private readRegistrations() {

    if (!this.selectedClass) {
      return;
    }

    // Unsubscribe from existing subscription if one exists
    if (this.readRegistrationsSubscription) {
      this.readRegistrationsSubscription.unsubscribe();
    }

    // Show spinner
    this.isReading = true;

    // Reset error status
    this.errorReading = '';

    // Read the locations data
    this.readRegistrationsSubscription = this.onlineCodersLadderRegistrationListDataService
      .readRegistrations(this.selectedClass.ClassId, this.isPrivateClasses ? 1 : 0, this.geolocation.currency, this.discountCode)
      .subscribe(
        (value) => {

            this.unprocessedData = value;

            // Process classes (process its sessions, unify, stack, group into time frames)
            this.processClasses(true);

            // Fire request to read the spots left number
            this.readSpotsLeft();

            // Hide spinner
            this.isReading = false;
        },
        (error) => {
            this.errorReading = error;
            this.isReading = false;
        }
      );
  }

  private scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  private setTelemetry() {
    this.telemetryService.inject('CLICK', 'all-classes-selected', null, true);
    this.telemetryService.inject('CLICK', 'classes-two-weeks-selected', null, true);
    this.telemetryService.inject('CLICK', 'advanced-only-selected', null, true);
    this.telemetryService.inject('CLICK', 'instructor-selected', null, true);
    this.telemetryService.inject('CLICK', 'session-', null, true);
  }

  // Provided an array of sessions, populate the leaders array with unique staff
  private setUniqueLeaders(sessions: any[]) {
    this.leaders = [];
    sessions.forEach(session => {
      session.Leaders.forEach(sessionLeader => {
        if (!this.leaders.map(leader => leader.StaffId).includes(sessionLeader.StaffId)) {
          this.leaders.push(sessionLeader);
        }
      });
    });

    // Sort by name
    this.leaders = this.leaders.sort((a, b) => b.FullName < a.FullName ? 1 : -1);
  }

  private stack(sessions4Weekday: any[]) {
    if (!sessions4Weekday.length) {
      return sessions4Weekday;
    }

    // Sort by time within the weekday and group by [IsExperienced,StartAt]
    sessions4Weekday.sort((a, b) => (a.StartAt > b.StartAt) ? 1 : -1);
    sessions4Weekday = this.groupSessions4Weekday(sessions4Weekday);

    // Init the final array of sessions we're going to return (only 1 of each StartAt)
    const finalSessions4Weekday = [];
    sessions4Weekday.forEach(groupedStart => {

      // Sort by occupied and last spots
      groupedStart.sort((a, b) => {
        if (a.Occupied === b.Occupied) {
          return a.SpotsLeft - b.SpotsLeft;
        }
        return a.Occupied > b.Occupied ? 1 : -1;
      });

      // We only show the top of the stack!
      const item2Display = {...groupedStart[0]};

      // SpotsLeft should be a sum of all of the sessions in the stack (as to not mislead customers)
      item2Display.SpotsLeft = groupedStart.reduce((prev, next) => prev + next.SpotsLeft, 0);

      // Set length of the stack (currently only used as a flag to show stack image)
      item2Display.NumberStacked = groupedStart.length - 1;

      // Set the number of occupied sessions
      item2Display.NumberOccupied = groupedStart.filter(session => session.Occupied).length + (item2Display.Occupied ? -1 : 0);

      // Get the instructor names within the stack
      item2Display.Instructors = [];
      groupedStart.forEach(session => item2Display.Instructors = item2Display.Instructors.concat(session.Occupied ? [] : session.Leaders));

      finalSessions4Weekday.push(item2Display);
    });

    return finalSessions4Weekday;
  }

  private unify(sessions: any[]) {

    const uniqueSessions = [];

    // Continue while there are still some sessions to unify
    while (sessions.length) {

      // Get the first session from our pool of sessions and assume we haven't found the last continuing session
      let lastContinuingSession = sessions[0];
      let isLastFound = false;

      // Set the SessionIds to this session's ID
      lastContinuingSession.SessionIds = [lastContinuingSession.LessonId];

      // Find all of the next sessions
      while (!isLastFound) {

        // Get the index of the next session
        const nextSessionIndex = sessions.findIndex(s => s.PreviousId === lastContinuingSession.LessonId);
        if (nextSessionIndex !== -1) {

          // Next session exists! Append to the SessionIds and repeat the process
          const nextSession = sessions[nextSessionIndex];
          nextSession.SessionIds = lastContinuingSession.SessionIds.concat([nextSession.LessonId]);

          // Preserve the experience flag
          nextSession.IsExperienced = nextSession.IsExperienced || lastContinuingSession.IsExperienced;

          // Preserve the closest start date
          nextSession.StartUTC = nextSession.StartUTC < lastContinuingSession.StartUTC ? nextSession.StartUTC : lastContinuingSession.StartUTC;

          // While unlikely, if there is still reservation for older session, add it
          nextSession.Reservations += lastContinuingSession.Reservations;
          lastContinuingSession = nextSession;
        } else {

          // There is no next session
          isLastFound = true;
        }
      }

      // Add the last session to the array
      uniqueSessions.push(lastContinuingSession);

      // Remove the continuing session train from our pool of sessions
      sessions = sessions.filter(s => !lastContinuingSession.SessionIds.includes(s.LessonId));
    }

    return uniqueSessions;
  }
}
