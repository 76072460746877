import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnlineCampsDataService {

  constructor(
    private http: HttpClient
  ) { }

  private urlOnlineCamps = environment.urlWebServices + '/cwk/onlineCamps';
  private urlPathway = environment.urlWebServices + '/api/pathway';

  readPathways(currency: string): Observable<any> {
    return this.http.get<any>(`${this.urlPathway}/readAll/${currency}`)
      .pipe(
        tap(response => response.forEach(this.fixPathwaysFromMySQL)),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  readRegistrations(
      partnerId: number,
      status: string,
      currency: string,
      discountCode: string,
      isHybridOnly: boolean,
      limitLocalityId: string
    ): Observable<any> {

    // Pass data in params
    const params = new HttpParams()
      .set('discountCode', discountCode)
      .set('isHybridOnly', (isHybridOnly === null ? 'null' : (isHybridOnly ? '1' : '0')))
      .set('limitLocalityId', limitLocalityId ? limitLocalityId : 'null');

    return this.http.get<any>(`${this.urlOnlineCamps}/readRegistrations/${partnerId}/${status}/${currency}`, { params: params })
      .pipe(
        map(response => this.fixRegistrationsFromMySQL(response)),
        catchError(error => observableThrowError(error.message || error))
      );
  }

  private fixPathwaysFromMySQL(data) {

    if (data.IsParsed) {
      return;
    }

    // Fix booleans
    data.HasCampRegistrations = !!data.HasCampRegistrations;

    // Parse classes
    data.Classes = data.Classes ? JSON.parse(data.Classes) : [];
    data.Classes.forEach(_class => {
      _class.IsComingNext = !_class.Description;
      _class.IsOfferingCamp = !! _class.IsOfferingCamp;
    });

    data.IsParsed = true;
  }

  private fixRegistrationsFromMySQL(response) {

    // We need to do the new clone so that we have new non-cached data
    const registrations = structuredClone(response);

    registrations.forEach(data => {

      // Parse pathways
      data.Pathways = data.Pathways ? JSON.parse(data.Pathways) : [];

      // Parse skills
      data.Pathways.forEach(pathway => pathway.Skills = pathway.Skills.split(','));

      // Fix Booleans
      data.IsNew = !!data.IsNew;

      // Parse sessions
      data.Sessions = data.Sessions ? JSON.parse(data.Sessions) : [];
      data.Sessions.forEach(session => {

        // Parse discounts
        session.Discounts = session.Discounts ? JSON.parse(session.Discounts) : [];
        session.Discounts.forEach(discount => {

          // Booleans
          discount.IsExclusive = !!discount.IsExclusive;

          // Dates
          discount.ExpiresOn = discount.ExpiresOn && new Date(discount.ExpiresOn.replace(/ /g, 'T') + 'Z');
        });

        // Fix booleans
        session.HasEnded = !!session.HasEnded;
        session.HasStarted = !!session.HasStarted;
        session.IsExternalRegistration = !!session.IsExternalRegistration;
        session.IsOnline = !!session.IsOnline;

        // Fix dates and times
        session.EndUTC = session.EndUTC && new Date(session.EndUTC.replace(/ /g, 'T') + 'Z');
        session.StartUTC = session.StartUTC && new Date(session.StartUTC.replace(/ /g, 'T') + 'Z');
        session.SessionEndUTC = session.SessionEndUTC && new Date(session.SessionEndUTC.replace(/ /g, 'T') + 'Z');
        session.SessionStartUTC = session.SessionStartUTC && new Date(session.SessionStartUTC.replace(/ /g, 'T') + 'Z');
      });
    });

    return registrations;
  }
}
