import { Injectable } from '@angular/core';

@Injectable()
export class CodersLadderAges {

  // Define the static coder's ladder ages
  readonly AGES = [
    { ageMin: 5, ageMax: 7, uid: 1 },
    { ageMin: 8, ageMax: 11, isDefault: true, uid: 2 },
    { ageMin: 11, ageMax: 14, uid: 3 },
    { ageMin: 14, ageMax: null, uid: 4 }
  ];

  // Define the static coder's ladder ages for private classes
  readonly AGES_PRIVATE = [
    { ageMin: 6, ageMax: 7, uid: 1 },
    { ageMin: 8, ageMax: 11, isDefault: true, uid: 2 },
    { ageMin: 11, ageMax: 14, uid: 3 },
    { ageMin: 14, ageMax: null, uid: 4 }
  ];

  // Get the pathway age object for the given ageUid and whether we're private or group
  getAge4Uid(ageUid: number, isPrivate: boolean) {
    return isPrivate ?
      this.AGES_PRIVATE.find(age => ageUid ? age.uid === ageUid : age.isDefault) :
      this.AGES.find(age => ageUid ? age.uid === ageUid : age.isDefault);
  }
}
