import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GradeService } from '@shared/services/grade.service';

// Icons
import { faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-dialog-class-details',
  templateUrl: './dialog-class-details.component.html',
  styleUrls: ['./dialog-class-details.component.scss'],
  standalone: false
})
export class DialogClassDetailsComponent implements OnInit {

  @Input() _class: any;
  @Input() showGrades = false;

  gradeRange: string | null = null;

  // Icons
  faInfoCircle = faInfoCircle;
  faTimesCircle = faTimesCircle;

  constructor(
    private activeModal: NgbActiveModal,
    private gradeService: GradeService
  ) { }

  ngOnInit() {

    // Set the grade range
    this.gradeRange = this.gradeService.toRange(this._class.GradeMin, this._class.GradeMax);
  }

  close() {
    this.activeModal.close();
  }
}
