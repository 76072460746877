import { Component, Input } from '@angular/core';

@Component({
  selector: 'home-program-important',
  templateUrl: './program-important.component.html',
  styleUrls: ['./program-important.component.scss'],
  standalone: false
})
export class ProgramImportantComponent {

  @Input() isCamp = false;
  @Input() isInPerson = false;
}
