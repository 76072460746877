import { Injectable } from '@angular/core';

@Injectable()
export class Constants {

  // Map Constants
  MAP_DEFAULT_LAT = 47.6038321; // Center USA lat
  MAP_DEFAULT_LNG = -122.330062; // Center USA lng
  ZOOM_LEVEL_INITIAL = 9; // Initial zoom of the map on init
  ZOOM_LEVEL_MAX = 16; // The highest level of zoom at which the map could initialize using fitBounds
  ZOOM_LEVEL_MAX_CLUSTER = 10; // The highest level at which pin clustering can occur

  // Pins
  CLUSTER_BLUE = 'https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/cluster/blue.png';
  CLUSTER_GREEN = 'https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/cluster/green.png';
  CLUSTER_RED = 'https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/cluster/red.png';
  PIN_BLUE = 'https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/marker/blue-alt.png';
  PIN_GREEN = 'https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/marker/green-alt.png';
  PIN_RED = 'https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/marker/red-alt.png';
  PIN_YELLOW = 'https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/marker/yellow-alt.png';
}
