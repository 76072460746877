<h1 class="text-center cwk-orange mb-4" [hidden]="hideTiles.length > 1">
  <span *ngIf="!isInPerson">EXPLORE OTHER LIVE ONLINE PROGRAMS:</span>
  <span *ngIf="isInPerson">EXPLORE LIVE ONLINE PROGRAMS:</span>
</h1>
<div class="fix-row top-3-container">
  <div [hidden]="hideTiles.includes('ONLINE-CAMPS')" class="mb-3 mb-lg-0 {{ !isRenderingOneTileOnly ? 'col-md-12 col-lg' : 'col-md-12 col-lg-6 offset-lg-3' }}">
    <a [routerLink]="['/online-camps']" queryParamsHandling="preserve" class="item cwk-link">
      <div id="program-links-camps" tm-destination="/online-camps">
        <div class="highlight">Weekly camps! Minecraft, Python &amp; more!</div>
        <div class="highlight">Offered during SCHOOL BREAKS</div>
        <div class="middle">
          <div class="live-online fw-bold">Live Online</div>
          <div class="program">CAMPS</div>
        </div>
        <div class="normal">One week, Mon-Fri, 1 to 3 hrs/day</div>
        <div class="normal">{{ staticPrice4ClassType4Currency('CAMP 2.5HR')[0] ? ('Starting at ' + (staticPrice4ClassType4Currency('CAMP 2.5HR')[0] | currency: geolocation?.currency : 'symbol') + ' for 2.5 hours/day camps') : '&nbsp;' }}</div>
        <div class="normal">{{ staticPrice4ClassType4Currency('CAMP HALF')[0] ? ('Starting at ' + (staticPrice4ClassType4Currency('CAMP HALF')[0] | currency: geolocation?.currency : 'symbol') + ' for 3 hours/day camps') : '&nbsp;' }}</div>
      </div>
    </a>
  </div>
  <div [hidden]="hideTiles.includes('ONLINE-CODERS-LADDER-CLASSES')" class="mb-3 mb-lg-0 {{ !isRenderingOneTileOnly ? 'col-md-12 col-lg' : 'col-md-12 col-lg-6 offset-lg-3' }}">
    <a [routerLink]="['/coders-ladder-group-classes']" queryParamsHandling="preserve" class="item cwk-link">
      <div id="program-links-coders-ladder" tm-destination="/coders-ladder-group-classes">
        <div class="highlight">Ready to join our flagship program?</div>
        <div class="highlight">Offered YEAR-ROUND</div>
        <div class="middle">
          <div class="live-online fw-bold">Live Online</div>
          <div class="program">CODER'S LADDER<span class="cwk-registration-icon">&reg;</span> CLASSES</div>
        </div>
        <div class="normal">Meets once a week for 1:15h</div>
        <div class="normal">{{ staticPrice4ClassType4Currency('CENTER CLASS')[0] ? ((staticPrice4ClassType4Currency('CENTER CLASS')[0] | currency: geolocation?.currency : 'symbol') + ' / 4 weeks') : '&nbsp;' }}</div>
        <!--div class="normal">{{ staticPrice4ClassType4Currency('CENTER CLASS 2X')[0] ? ('Twice a week: ' + (staticPrice4ClassType4Currency('CENTER CLASS 2X')[0] | currency: geolocation?.currency : 'symbol') + ' / 4 weeks') : '&nbsp;' }}</div-->
      </div>
    </a>
  </div>
  <div [hidden]="hideTiles.includes('ONLINE-ENRICHMENT-CLASSES')" class="mb-3 mb-lg-0 {{ !isRenderingOneTileOnly ? 'col-md-12 col-lg' : 'col-md-12 col-lg-6 offset-lg-3' }}">
    <a [routerLink]="['/online-enrichment-classes']" queryParamsHandling="preserve" class="item cwk-link">
      <div id="program-links-enrichment" tm-destination="/online-enrichment-classes">
        <div class="highlight">Your online afterschool coding club!</div>
        <div class="highlight">Offered during SCHOOL YEAR</div>
        <div class="middle">
          <div class="live-online fw-bold">LIVE Online</div>
          <div class="program">ENRICHMENT CLASSES</div>
        </div>
        <div class="normal">Meets once a week for 1h or 1:30h</div>
        <div class="normal">{{ staticPrice4ClassType4Currency('AFTER-SCHOOL 6WK')[0] ? ('Starting at ' + (staticPrice4ClassType4Currency('AFTER-SCHOOL 6WK')[0] | currency: geolocation?.currency : 'symbol') + ' for 6-week session') : '&nbsp;' }}</div>
        <div class="normal">{{ staticPrice4ClassType4Currency('AFTER-SCHOOL 10WK')[0] ? ('Starting at ' + (staticPrice4ClassType4Currency('AFTER-SCHOOL 10WK')[0] | currency: geolocation?.currency : 'symbol') + ' for 10-week session') : '&nbsp;' }}</div>
      </div>
    </a>
  </div>
</div>
