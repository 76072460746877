import { Component, Input, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfigurationService } from '@shared/services/configuration.service';
import { DialogJoinMailingListComponent } from '@shared/components/dialog-join-mailing-list/dialog-join-mailing-list.component';

@Component({
  selector: 'home-program-banner',
  templateUrl: './program-banner.component.html',
  styleUrls: ['./program-banner.component.scss'],
  standalone: false
})
export class ProgramBannerComponent implements OnInit {

  @Input() country: string;
  @Input() forceHybridOnly: boolean;
  @Input() location: any; // Optional location object
  @Input() program: any;
  @Input() source: string;

  bannerData: any = {};

  // Early-bird
  keyFileEarlyBird = 'EARLY-BIRD-FILE-';
  keyShowEarlyBird = 'EARLY-BIRD-HIDE-TIMESTAMP-';
  fileEarlyBird: string = null;
  showEarlyBird = false;

  constructor(
    private configurationService: ConfigurationService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {

    // Get early-bird configuration
    this.configurationService.getItem(this.keyFileEarlyBird + this.program)
      .subscribe(
        (value) => this.fileEarlyBird = value.Value,
        () => {}
      );

    this.configurationService.getItem(this.keyShowEarlyBird + this.program)
      .subscribe(
        (value) => this.showEarlyBird = value && (new Date(value.Value) > new Date()),
        () => {}
      );
  }

  openJoinMailingListDialog() {

    // Open the dialog
    const modalRef = this.modalService.open(DialogJoinMailingListComponent, { size: 'lg', centered: true });
    modalRef.componentInstance.countryCode = this.country;
    modalRef.componentInstance.source = this.source;
  }
}
