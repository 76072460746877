<div class="carousel-container">
  <div #carousel class="parent-overflow" (scroll)="updateArrows(carousel)" (mousedown)="mouseDown($event, carousel)" (mouseup)="mouseOff()"
  (mouseleave)="mouseOff()" (mousemove)="mouseMoved($event, carousel)">

    <!-- SCROLL LEFT BUTTON -->
    <div *ngIf="showLeftArrow" class="scroll-btn left" (click)="scrollCarousel('LEFT', carousel)">
      <fa-icon [icon]="faChevronLeft" size="2x"></fa-icon>
    </div>

    <!-- LOGOS -->
    <div class="carousel-item-container" *ngFor="let logo of logos; index as i">
      <div class="carousel-item p-3">
        <img class="img-fluid p-3" [src]="logo.ImageURL" [alt]="logo.Title" loading="lazy"/>
      </div>
      <div *ngIf="logo.Title" class="mb-2 py-1 text-center small fw-bold">{{ logo.Title }}</div>
    </div>

    <!-- AND MORE -->
    <h4 class="text-center text-muted align-self-center px-3 mb-0">And<br>More!</h4>

    <!-- SCROLL RIGHT BUTTON -->
    <div *ngIf="showRightArrow" class="scroll-btn right" (click)="scrollCarousel('RIGHT', carousel)">
      <fa-icon [icon]="faChevronRight" size="2x"></fa-icon>
    </div>
  </div>
</div>
