import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { Location } from '../interfaces/location';

@Injectable({
  providedIn: 'root'
})
export class InPersonRegistrationsSharingService {

  private subjectAddress = new BehaviorSubject<string>(null);
  private subjectCampDay = new BehaviorSubject<any>(null);
  private subjectLocations = new BehaviorSubject<Location[]>([]);
  private subjectLocationsSelected = new BehaviorSubject<Location[]>([]);
  private subjectLocationsVisible = new BehaviorSubject<Location[]>([]);

  constructor() { }

  getAddress() {
    return this.subjectAddress.asObservable();
  }

  getCampDay() {
    return this.subjectCampDay.asObservable();
  }

  getLocations() {
    return this.subjectLocations.asObservable();
  }

  getLocationsSelected() {
    return this.subjectLocationsSelected.asObservable();
  }

  getLocationsVisible() {
    return this.subjectLocationsVisible.asObservable();
  }

  updateAddress(address: string) {
    this.subjectAddress.next(address);
  }

  updateCampDay(isHalfDay: boolean, isFullDay: boolean) {
    this.subjectCampDay.next({ IsHalfDay: isHalfDay, IsFullDay: isFullDay });
  }

  updateLocations(locations: Location[]) {
    this.subjectLocations.next(locations);
  }

  updateLocationsSelected(locations: Location[]) {
    this.subjectLocationsSelected.next(locations);
  }

  updateLocationsVisible(locations: Location[]) {
    this.subjectLocationsVisible.next(locations);
  }
}
