// This is based on https://stackoverflow.com/questions/5639346/what-is-the-shortest-function-for-reading-a-cookie-by-name-in-javascript?rq=1
// If more functionality is needed, it should be replaced with some Angular library.
// As of now (2021-07-06), the existing most used libraries don't work with Angular 8 and 9 at the same time so we need to upgrade our applications first.
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  read(name: String) {
    const matches = isPlatformBrowser(this.platformId) ? this.document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)') : null;
    return (matches && matches.length) ? matches.pop() || null : null;
  }
}
