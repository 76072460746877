<div class="modal-header">
  <h1><fa-icon [icon]="faFireAlt" class="cwk-red me-3"></fa-icon>Experienced Class!</h1>
</div>

<div class="modal-body" [innerHtml]="message | safeHtml"></div>

<div class="modal-footer">

  <!-- Buttons -->
  <button type="button" class="btn btn-warning btn-large me-auto" (click)="cancel()">Go back</button>
  <button type="button" class="btn btn-warning btn-large" (click)="process()">Continue to checkout</button>
</div>

