<div class="banner-container">

  <!-- Banner image -->
  <!--link rel="preload" as="image" href="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/in-person-camps.jpg" /-->
  <!--link rel="preload" as="image" href="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/in-person-enrichment-classes.jpg" /-->
  <!--link rel="preload" as="image" href="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-camps.jpg" /-->
  <!--link rel="preload" as="image" href="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/coders-ladder-group-classes.jpg" /-->
  <!--link rel="preload" as="image" href="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-enrichment-classes.jpg" /-->
  <!--link rel="preload" as="image" href="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-parent-classes.jpg" /-->
  <!--link rel="preload" as="image" href="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-private-classes.jpg" /-->
  <div class="ratio" *ngIf="program === 'IN-PERSON-CAMPS'" style="--bs-aspect-ratio: 50%;">
    <picture>
      <source srcset="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/in-person-camps.webp" type="image/webp" />
      <img alt="Coding with Kids In-person Camps Banner" class="img-fluid" fetchpriority="high" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/in-person-camps.jpg" width="1300" height="650" />
    </picture>
  </div>
  <div class="ratio" *ngIf="program === 'IN-PERSON-ENRICHMENT-CLASSES'" style="--bs-aspect-ratio: 50%;">
    <img class="img-fluid" fetchpriority="high" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/in-person-enrichment-classes.jpg" alt="Coding with Kids - Banner image" width="1300" height="650" />
  </div>
  <div class="ratio" *ngIf="program === 'ONLINE-CAMPS'" style="--bs-aspect-ratio: 50%;">
    <picture>
      <source srcset="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-camps.webp" type="image/webp" />
      <img alt="Coding with Kids Online Camps Banner" class="img-fluid" fetchpriority="high" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-camps.jpg" width="1300" height="650" />
      <!--source srcset="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/winter-camps.webp" type="image/webp" />
      <img alt="Coding with Kids Online Camps Banner" class="img-fluid" fetchpriority="high" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/winter-camps.jpg" width="1300" height="650" /-->
    </picture>
  </div>
  <div *ngIf="program === 'ONLINE-CODERS-LADDER-CLASSES'" style="--bs-aspect-ratio: 50%;">
    <!--ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="false">
      <ng-template ngbSlide-->
        <img class="d-block img-fluid w-100" fetchpriority="high" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/coders-ladder-group-classes.jpg" alt="Coding with Kids - Banner image" width="1280" height="640" />
        <div class="absolute-element dark-overlay"></div>
        <div class="absolute-element bottom-section buttons">
          <div class="title">CODER'S LADDER<span class="cwk-registration-icon">&reg;</span> GROUP CLASSES</div>
          <ng-container *ngTemplateOutlet="privateGroupSwitch; context: { format: 'GROUP' }"></ng-container>
        </div>
      <!--/ng-template>
      <ng-template ngbSlide>
        <!--img class="d-block img-fluid w-100" loading="lazy" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/coders-ladder-group-classes-10-anniversary.gif" alt="Coding with Kids - 10th Anniversary" width="1280" height="640" /->
        <video #groupCLVideo class="d-block img-fluid w-100" (canplay)="groupCLVideo.play()" (loadedmetadata)="groupCLVideo.muted = true" autoplay loop muted playsinline poster="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/coders-ladder-group-classes-10-anniversary-poster.jpg" width="1280" height="640">
          <source src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/coders-ladder-group-classes-10-anniversary.webm" type="video/webm" />
          <source src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/coders-ladder-group-classes-10-anniversary.mp4" type="video/mp4" />
        </video>
      </ng-template>
    </ngb-carousel-->
  </div>
  <!--div class="ratio" *ngIf="program === 'ONLINE-ENRICHMENT-CLASSES'" style="--bs-aspect-ratio: 50%;">
    <img class="img-fluid" fetchpriority="high" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-enrichment-classes.jpg" alt="Coding with Kids - Banner image" width="1300" height="650" />
  </div-->
  <!--div class="ratio" *ngIf="program === 'ONLINE-ADULT-CLASSES'" style="--bs-aspect-ratio: 50%;">
    <img class="img-fluid" fetchpriority="high" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-parent-classes.jpg" alt="Coding with Kids - Banner image" />
  </div-->
  <div *ngIf="program === 'ONLINE-PRIVATE-CLASSES'" style="--bs-aspect-ratio: 50%;">
    <!--ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="false">
      <ng-template ngbSlide-->
        <img class="d-block img-fluid w-100" fetchpriority="high" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-private-classes.jpg" alt="Coding with Kids - Banner image" width="1300" height="650" />
        <div class="absolute-element dark-overlay"></div>
        <div class="absolute-element bottom-section buttons">
          <div class="title">PRIVATE 1:1 CLASSES</div>
          <ng-container *ngTemplateOutlet="privateGroupSwitch; context: { format: 'PRIVATE' }"></ng-container>
        </div>
      <!--/ng-template>
      <ng-template ngbSlide>
        <!--img class="d-block img-fluid w-100" loading="lazy" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-private-classes-10-anniversary.gif" alt="Coding with Kids - 10th Anniversary" width="1300" height="650" /->
        <video #privateCLVideo class="d-block img-fluid w-100" (canplay)="privateCLVideo.play()" (loadedmetadata)="privateCLVideo.muted = true" autoplay loop muted playsinline poster="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-private-classes-10-anniversary-poster.jpg" width="1300" height="650">
          <source src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-private-classes-10-anniversary.webm" type="video/webm" />
          <source src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-private-classes-10-anniversary.mp4" type="video/mp4" />
        </video>
      </ng-template>
    </ngb-carousel-->
  </div>

  <!-- Low opacity dark overlay to improve contrast -->
  <div class="absolute-element dark-overlay" *ngIf="!['ONLINE-CODERS-LADDER-CLASSES', 'ONLINE-PRIVATE-CLASSES'].includes(program)"></div>

  <!-- Bottom section -->
  <!--div *ngIf="this.program === 'CAMPS'" class="absolute-element bottom-section no-buttons">
    <div class="title">IN-PERSON CAMPS</div>
    <div class="small-title">{{ location.Name | uppercase }}, {{ location.City | uppercase }}</div>
  </div-->

  <div *ngIf="this.program === 'IN-PERSON-CAMPS'" class="absolute-element bottom-section no-buttons">
    <div class="title">IN-PERSON CAMPS</div>
    <!--ng-container *ngTemplateOutlet="formatSwitch; context: { data : { format: 'IN-PERSON', classType: 'CAMP' }}"></ng-container-->
    <img *ngIf="showEarlyBird" class="img-fluid early-bird-in-person" src="https://d3t4xfu733v2tb.cloudfront.net/logo/{{ fileEarlyBird }}" alt="Early bird image" loading="lazy" width="500" height="315" />
    <div class="small-title mb-3 mb-md-5">Ages 5-18 | Small Groups</div>
  </div>

  <div *ngIf="this.program === 'IN-PERSON-ENRICHMENT-CLASSES'" class="absolute-element bottom-section buttons">
    <div class="title">ENRICHMENT CLASSES</div>
    <!--ng-container *ngTemplateOutlet="formatSwitch; context: { data : { format: 'IN-PERSON', classType: 'ENRICHMENT' }}"></ng-container-->
  </div>

  <!--div *ngIf="this.program === 'ONLINE-ADULT-CLASSES'" class="absolute-element bottom-section no-buttons">
    <div class="title">CLASSES FOR ADULTS</div>
    <div class="small-title">Live Online</div>
  </div-->

  <!--div *ngIf="this.program === 'ONLINE-PRIVATE-CLASSES'" class="absolute-element bottom-section buttons">
    <div class="title">PRIVATE 1:1 CLASSES</div>
    <ng-container *ngTemplateOutlet="privateGroupSwitch; context: { format: 'PRIVATE' }"></ng-container>
  </div-->

  <!--div *ngIf="this.program === 'ONLINE-CODERS-LADDER-CLASSES'" class="absolute-element bottom-section buttons">
    <div class="title">CODER'S LADDER<span class="cwk-registration-icon">&reg;</span> GROUP CLASSES</div>
    <ng-container *ngTemplateOutlet="privateGroupSwitch; context: { format: 'GROUP' }"></ng-container>
  </div-->

  <div *ngIf="this.program === 'ONLINE-CAMPS'" class="absolute-element bottom-section no-buttons">
    <div class="title">
      ONLINE CAMPS
      <!--WINTER BREAK CAMPS-->
      <img *ngIf="showEarlyBird" class="img-fluid early-bird" src="https://d3t4xfu733v2tb.cloudfront.net/logo/{{ fileEarlyBird }}" alt="Early bird image" loading="lazy" width="500" height="315" />
    </div>
    <!--div class="sub-title mb-3">Live Online &amp; In-person</div-->
    <!--ng-container *ngTemplateOutlet="formatSwitch; context: { data : { format: 'ONLINE', classType: 'CAMP' }}"></ng-container-->
    <!--div class="mt-4">&nbsp;</div-->
    <div class="small-title mb-3 mb-md-5">Ages&nbsp;5-18 | Max.&nbsp;8&nbsp;Students</div>
    <!--div class="small-title mb-3 mb-md-5">Live&nbsp;Online  |  4&nbsp;days  |  Dec&nbsp;30 - Jan&nbsp;3 (no&nbsp;camp&nbsp;Jan&nbsp;1)</div-->
    <!--div class="small-title mb-3 mb-md-5">Live&nbsp;Online  |  2-day and 4-day camps  |  Offered Dec&nbsp;26 - Jan&nbsp;3</div-->
  </div>

  <!--div *ngIf="this.program === 'ONLINE-ENRICHMENT-CLASSES'" class="absolute-element bottom-section buttons">
    <div class="title">ENRICHMENT CLASSES</div>
    <ng-container *ngTemplateOutlet="formatSwitch; context: { data : { format: 'ONLINE', classType: 'ENRICHMENT' }}"></ng-container>
  </div-->
</div>

<div class="orange-bar d-flex justify-content-between align-items-center">
  <home-customer-feedback-rating mode="banner"></home-customer-feedback-rating>
  <div>
    <a href="https://www.facebook.com/CodingWithKids" target="_blank">
      <img class="img-fluid social-icon me-3" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/facebook.png" alt="Facebook logo" loading="lazy" style="aspect-ratio: auto 30 / 29" />
    </a>
    <!--a href="https://www.twitter.com/CodingWithKids" target="_blank">
      <img class="img-fluid social-icon" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/twitter.png" alt="Twitter logo" loading="lazy" style="aspect-ratio: auto 29 / 30" />
    </a-->
  </div>
</div>

<ng-template #formatSwitch let-data="data">
  <div class="d-none d-sm-flex justify-content-center">
    <a class="cwk-link" [routerLink]="['/online-' + (data.classType === 'CAMP' ? 'camps' : 'enrichment-classes')]" queryParamsHandling="merge">
      <div class="white-btn" [ngClass]="{'selected': data.format === 'ONLINE'}">
        <div class="state">{{ data.format === 'ONLINE' ? 'Selected' : 'Switch to' }}</div>
        Live Online
      </div>
    </a>
    <div style="width: 25px;"></div>
    <a class="cwk-link" [routerLink]="['/in-person-' + (data.classType === 'CAMP' ? 'camps' : 'enrichment-classes')]" queryParamsHandling="merge">
      <div class="white-btn" [ngClass]="{'selected': data.format === 'IN-PERSON'}">
        <div class="state">{{ data.format === 'IN-PERSON' ? 'Selected' : 'Switch to' }}</div>
        In-person
      </div>
    </a>
  </div>
</ng-template>

<ng-template #privateGroupSwitch let-format="format">
  <div class="d-none d-sm-flex justify-content-center">
    <a class="cwk-link" [routerLink]="['/coders-ladder-group-classes']" queryParamsHandling="merge">
      <div class="white-btn" [ngClass]="{'selected':format === 'GROUP'}">
        <div class="state">{{ format === 'GROUP' ? 'Selected' : 'Switch to' }}</div>
        Group Classes
      </div>
    </a>
    <div style="width: 25px;"></div>
    <a class="cwk-link" [routerLink]="['/private-classes']" queryParamsHandling="merge">
      <div class="white-btn" [ngClass]="{'selected':format === 'PRIVATE'}">
        <div class="state">{{ format === 'PRIVATE' ? 'Selected' : 'Switch to' }}</div>
        Private Classes
      </div>
    </a>
  </div>
</ng-template>
