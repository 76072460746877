<div *ngIf="!errorReading" class="d-flex align-items-center flex-wrap">

  <!-- Customer reviews text -->
  <div *ngIf="mode === 'banner'" class="title-banner me-2"><b>Customer Reviews</b></div>

  <!-- Loading spinner -->
  <span *ngIf="isReading"><fa-icon class="me-2" [icon]="faSpinner" animation="spin"></fa-icon>Loading...</span>
  <div *ngIf="!isReading" class="d-flex align-items-center cwk-pointer" ngbPopover="All {{ info.TotalCount }} reviews are from real Coding with Kids customers whose children took classes in the last {{ info.TotalMonths }} months" triggers="mouseenter:mouseleave">

    <!-- Star images and rating (5/5) -->
    <div class="rating d-inline">
      <img *ngIf="ratingStars > 0" class="me-2" [ngClass]="{'banner': mode === 'banner'}" src="https://d3t4xfu733v2tb.cloudfront.net/web/rating-stars/rating-stars-{{ ratingStars }}.png" alt="Customer Feedback Rating Stars" loading="lazy" width="368" height="64" />
      <span [hidden]="!average" class="rating-ratio" [ngClass]="{'banner': mode === 'banner'}">{{ average }}&nbsp;/&nbsp;5</span>
    </div>

    <!-- Number of reviews and info popover -->
    <div *ngIf="info && isCountShown" class="ms-3">{{ info.TotalCount }} reviews</div>
  </div>
</div>
