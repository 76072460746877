import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GradeService {

  constructor() { }

  number2String(grade: number | null): string {
    return (grade === null) ? '' : ((grade === -1) ? 'PK' : ((grade === 0) ? 'K' : '' + grade));
  }

  string2Number(grade: string | null): number | null {
    return grade ? ((grade.toUpperCase() === 'PK') ? -1 : ((grade.toUpperCase() === 'K') ? 0 : +grade)) : null;
  }

  toRange(gradeMin: number | null, gradeMax: number | null): string {
    const min = this.number2String(gradeMin);
    const max = this.number2String(gradeMax);
    return (min === max) ? min : ((min.length ? min : '') + (max.length ? '-' + max : '+'));
  }
}
