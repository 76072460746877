import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class Subscription {

  constructor(
    private currencyPipe: CurrencyPipe
  ) { }

  /* tslint:disable:max-line-length */
  // Format the SubscriptionParameters into a pretty string for the user
  getSubscriptionParameter4Display(subscriptionParameter: any): string {

    let displayStr = '';

    // Main parameter specifying class type limits
    if (subscriptionParameter.IsMain) {

      const privateCLStr = subscriptionParameter.IsPrivate ? 'Private' : 'Coder\'s Ladder®';
      switch (subscriptionParameter.TotalLimit) {
        case 7:
          displayStr = `<b>12 consecutive months of ${privateCLStr} Classes</b>`;
          break;
        case 0: // This is only for CODERS-LADDER subscription when doing upgrade
          displayStr = `<b>8 weeks of ${privateCLStr} Classes</b>`;
          break;
        default:
          displayStr = `<b>Registration to ${privateCLStr} Classes</b>`;
      }

      // Do we have data about number used?
      if (subscriptionParameter.TotalLimit > 1 && subscriptionParameter.hasOwnProperty('NumberUsedLevelCode')) {
        const remaining = subscriptionParameter.TotalLimit - subscriptionParameter.NumberUsedMain;
        const plural = remaining > 1 ? 's' : '';
        displayStr += ` (${remaining} billing cycle${plural} remaining)`;
      }
    } else if (subscriptionParameter.Credit) {

      // Credit parameter
      displayStr = `<b>${this.currencyPipe.transform(subscriptionParameter.Credit, subscriptionParameter.Currency, 'symbol', '1.0-0')} credit towards Camps</b>`;
    } else if (subscriptionParameter.LevelCode) {

      // Free class parameter
      const limit2Show = subscriptionParameter.FreeLimit || subscriptionParameter.FreeSeasonLimit;
      if (limit2Show > 1) {
        displayStr = `<b>${limit2Show} bonus ${subscriptionParameter.ClassName} session${limit2Show > 1 ? 's' : ''}</b>`;

        // Add extra message for free office hours
        if (subscriptionParameter.LevelCode === 'OH') {
          displayStr += '</br>with your instructor, 30 min each';
        }

        // Do we have data about number used?
        if (subscriptionParameter.hasOwnProperty('NumberUsedLevelCode')) {

          const totalRemaining = subscriptionParameter.FreeLimit ?
            subscriptionParameter.FreeLimit - subscriptionParameter.NumberUsedLevelCode :
            subscriptionParameter.FreeSeasonLimit - subscriptionParameter.NumberUsedLevelCode;
          displayStr += ` (${totalRemaining} remaining)`;
        }
      }
    }

    return displayStr;
  }
  /* tslint:enable:max-line-length */

  // (HARD-CODED) Set the "Lesson price"
  setLessonPrice(currency: string, isPrivate: boolean, subscription: any, weekDaysCount: number) {

    subscription.LessonPrice = Math.round(subscription.Price / ((subscription.Code === 'CODERS-LADDER') ? subscription.Lessons : (isPrivate ? 100 : 50)));
    /*switch (subscription.Code) {
      case 'CODERS-LADDER': // For upgrade this is session-by-session plan with the same regular price as basic plan
        switch (currency) {
          case 'CAD':
            switch (weekDaysCount) {
              case 1:
                subscription.LessonPrice = isPrivate ? 67 : 38;
                break;
              case 2:
                subscription.LessonPrice = isPrivate ? 67 : 35;
                break;
            }
            break;
          case 'GBP':
            switch (weekDaysCount) {
              case 1:
                subscription.LessonPrice = isPrivate ? 40 : 22;
                break;
              case 2:
                subscription.LessonPrice = isPrivate ? 40 : 21;
                break;
            }
            break;
          case 'USD':
            switch (weekDaysCount) {
              case 1:
                subscription.LessonPrice = isPrivate ? 50 : 31;
                break;
              case 2:
                subscription.LessonPrice = isPrivate ? 50 : 26;
                break;
            }
            break;
        }
        break;
      case 'ANNUAL-BASIC':
      case 'ANNUAL-PREMIUM':
        switch (currency) {
          case 'CAD':
            switch (weekDaysCount) {
              case 1:
                subscription.LessonPrice = isPrivate ? 63 : 27;
                break;
              case 2:
                subscription.LessonPrice = isPrivate ? 62 : 24;
                break;
            }
            break;
          case 'GBP':
            switch (weekDaysCount) {
              case 1:
                subscription.LessonPrice = isPrivate ? 37 : 16;
                break;
              case 2:
                subscription.LessonPrice = isPrivate ? 36 : 14;
                break;
            }
            break;
          case 'USD':
            switch (weekDaysCount) {
              case 1:
                subscription.LessonPrice = isPrivate ? 47 : 20;
                break;
              case 2:
                subscription.LessonPrice = isPrivate ? 46 : 18;
                break;
            }
            break;
        }
        break;
    }*/
  }
}
