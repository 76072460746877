import { AfterContentInit, Component, OnDestroy, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Subscription } from 'rxjs';

import { CookieConsentManagerService } from '@shared/components/cookie-consent-manager/services/cookie-consent-manager.service';

@Component({
  selector: 'home-embed-video',
  templateUrl: './embed-video.component.html',
  styleUrls: ['./embed-video.component.scss'],
  standalone: false
})
export class EmbedVideoComponent implements AfterContentInit {

  @Input() videoSrc: string;
  @Input() videoTitle: string;

  isViewContentLoaded = false;
  sanitizedSrc: SafeResourceUrl = null;

  // Subscriptions
  private updateCookiesSubscription: Subscription;

  constructor(
    private cookieConsentManagerService: CookieConsentManagerService,
    private sanitizer: DomSanitizer
  ) { }

  ngAfterContentInit() {
    this.isViewContentLoaded = true;
    this.sanitizedSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoSrc);
  }

  ngOnDestroy() {

    // Clean up the subscriptions
    if (this.updateCookiesSubscription) {
      this.updateCookiesSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.initialize();
  }

  private initialize() {

    // Observe cookie consent updates
    this.updateCookiesSubscription = this.cookieConsentManagerService.getUpdateCookiesObservable()
      .subscribe(() => this.sanitizedSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoSrc + (this.cookieConsentManagerService.hasConsented('AnalyticsStorage') ? '' : '&dnt=1')))
  }
}
