import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cwk-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
  standalone: false
})
export class DialogConfirmComponent implements OnInit {

  @Input() buttonCancel = 'Cancel';
  @Input() buttonSubmit = 'Confirm';
  @Input() header: string;
  @Input() text: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  process() {
    this.activeModal.close();
  }
}
