<div class="modal-header">
  <h1>{{header}}</h1>
</div>

<div class="modal-body" [innerHtml]="text | safeHtml"></div>

<div class="modal-footer">

  <!-- Buttons -->
  <button *ngIf="buttonCancel" type="button" class="btn btn-warning btn-large mr-auto" (click)="cancel()" i18n>{{buttonCancel}}</button>
  <button *ngIf="buttonSubmit" type="button" class="btn btn-warning btn-large" (click)="process()">{{buttonSubmit}}</button>
</div>

