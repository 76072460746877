import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CodersLadderAges } from '../../../shared/classes/coders-ladder-ages';
import { CodersLadderDataService } from './services/coders-ladder-data.service';
import { GeolocationService } from '@shared/services/geolocation.service';
import { TelemetryService } from '@shared/services/telemetry.service';

// Animations
import { slideInOut } from '@shared/animations/slide-in-out';

// Icons
import { faMinusCircle, faPlusCircle, faQuestionCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  animations: [slideInOut],
  selector: 'home-coders-ladder-level-selector',
  templateUrl: './coders-ladder-level-selector.component.html',
  providers: [CodersLadderAges],
  styleUrls: [
    './coders-ladder-level-selector.component.scss',
    '../../styles/pathway.scss',
    '../../../shared/styles/registration-lists.scss'
  ],
  standalone: false
})
export class CodersLadderLevelSelectorComponent implements AfterViewChecked, OnInit {

  @Input() isPrivateClasses = false; // Are we considering private classes (add pathway filters)
  @Input() selectedAge: any = null;
  @Input() selectedClass: any = null;
  @Input() showAllClasses = false; // Show all classes, or only those we're currently offering?
  @Input() showClassInfo = true; // Is class information shown for the selected level?

  @Output() selectedAgeChange: EventEmitter<any> = new EventEmitter();
  @Output() selectedClassChange: EventEmitter<any> = new EventEmitter();

  canSetTelemetry = false;
  classes: any[] = [];
  errorReading = '';
  isExpanded = false; // Description expansion for mobile
  isReading = false;

  // Icons
  faMinusCircle = faMinusCircle;
  faPlusCircle = faPlusCircle;
  faQuestionCircle = faQuestionCircle;
  faSpinner = faSpinner;

  // Temporary video link (should be moved to database in the future)
  readonly L1_VIDEO_URL = 'https://player.vimeo.com/video/548653417?portrait=0&portrait=0&byline=0&title=0';
  readonly MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER;
  readonly DESCRIPTION_LINES = 5; // Number of lines to render for condensed description

  constructor(
    public codersLadderAges: CodersLadderAges,
    private activatedRoute: ActivatedRoute,
    private codersLadderDataService: CodersLadderDataService,
    @Inject(DOCUMENT) private document: Document,
    private geolocationService: GeolocationService,
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    private telemetryService: TelemetryService
  ) { }

  ngAfterViewChecked() {

    // Set the telemetry for the page
    if (this.canSetTelemetry) {
      this.setTelemetry();
    }
  }

  ngOnInit() {
    this.initialize();
  }

  filterClasses(filterStyle: string) {
    const hasPathway = filterStyle === 'PATHWAY';
    return this.classes.filter(_class => !!_class.PathwayId === hasPathway);
  }

  isDescriptionClamped(size: string): boolean {
    const description = isPlatformBrowser(this.platformId) && this.document.getElementById(`description-${size}-${this.selectedClass.ClassId}`);
    return description ? description.scrollHeight > description.clientHeight : true;
  }

  setSelectedAge(age: any) {
    this.selectedAge = age;
    this.selectedAgeChange.emit(this.selectedAge);
    this.applyFilter();
  }

  setSelectedClass(_class: any) {
    this.selectedClass = _class;
    this.selectedClassChange.emit(this.selectedClass);
    this.updateQueryParams();
  }

  switchClassDescription() {
    this.selectedClass.IsDescriptionExpanded = !this.selectedClass.IsDescriptionExpanded;
  }

  switchIsExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  private initialize() {

    // Preselect age based on query params or default
    this.selectedAge = this.codersLadderAges
      .getAge4Uid(parseInt(this.activatedRoute.snapshot.queryParamMap.get('age'), 10), this.isPrivateClasses);

    this.geolocationService.getObservable()
      .subscribe((value) => {

        // Read the classes for the currency
        this.read(value.currency);
    });
  }

  private applyFilter() {

    // Check if the class should be disabled (determined by the selected age)
    this.classes.forEach(_class => {
      if (this.selectedAge) {
        const x1 = _class.AgeMin;
        const x2 = (_class.AgeMax || Number.MAX_SAFE_INTEGER);
        const y1 = this.selectedAge.ageMin;
        const y2 = (this.selectedAge.ageMax || Number.MAX_SAFE_INTEGER);
        _class.IsDisabled = ((Math.max(x1, y1) >= Math.min(x2, y2)) || (!_class.IsSignupAvailable && !this.showAllClasses));
      } else {
        _class.IsDisabled = !_class.IsSignupAvailable;
      }
    });

    // If there is a selected class, see if it is disabled now, if so, we'll select the first non-disabled class
    if (
      !this.selectedClass ||
      (this.selectedClass && this.classes.find(_class => _class.ClassId === this.selectedClass.ClassId).IsDisabled)
    ) {

      // Select the first (non-pathway) class that is not disabled
      this.selectedClass = this.classes.find(_class => !_class.IsDisabled && !_class.PathwayId);

      // If still no class found, select the first pathway class
      if (!this.selectedClass) {
        this.selectedClass = this.classes.find(_class => !_class.IsDisabled);
      }
    }

    this.selectedClassChange.emit(this.selectedClass);
    this.updateQueryParams();
  }

  private processClasses() {

    this.classes.forEach(_class => {

      // Currently only L1 is supported. When future videos are created the VideoURL should be moved to the database
      _class.VideoURL = _class.LevelCode === 'L1' ? this.L1_VIDEO_URL : null;
      _class.VideoTitle = _class.LevelCode === 'L1' ? 'Coding with Kids Coder\'s Ladder Level 1': null;
    });

    // If there is a ClassId set in query params, select it
    const queryParamClassId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('cid'), 10);
    if (queryParamClassId) {
      this.selectedClass = this.classes
        .filter(_class => this.isPrivateClasses || !_class.PathwayId)
        .find(_class => _class.ClassId === queryParamClassId);
    }

    // Apply the filter
    this.applyFilter();

    // Set telemetry
    this.canSetTelemetry = true;
  }

  private read(currency: string) {

    // Show spinner
    this.isReading = true;

    // Reset error status
    this.errorReading = '';

    this.codersLadderDataService.read(currency, this.isPrivateClasses ? 1 : 0)
      .subscribe(
        (value) => {

            // Set the classes
            this.classes = value;

            // Process classes
            this.processClasses();

            // Hide spinner
            this.isReading = false;
        },
        (error) => {
            this.errorReading = error;
            this.isReading = false;
        }
      );
  }

  private setTelemetry() {
    this.telemetryService.inject('CLICK', 'age-switched-', null, true);
    this.telemetryService.inject('CLICK', 'level-switched-', null, true);
    this.telemetryService.inject('CLICK', 'pathway-switched-', null, true);
    this.canSetTelemetry = false;
  }

  private updateQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        age: this.selectedAge.uid || null,
        cid: this.selectedClass ? this.selectedClass.ClassId : null
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }
}
