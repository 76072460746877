<h1 class="cwk-orange mb-3">IMPORTANT</h1>
<div class="important-block">

  <!-- ONLINE -->
  <ng-container *ngIf="!isInPerson else inPerson">

    <div class="mb-1 section-important">
      <h5>TECHNICAL REQUIREMENTS</h5>
      <home-program-tech-req id="tech-req"></home-program-tech-req>
    </div>

    <div class="mb-1 section-important">
      <h5>SOFTWARE INSTALLATION</h5>
      <div>Please note that certain {{ isCamp ? 'camps' : 'classes' }} will require <b>software installation</b> on student’s computer prior to the {{ isCamp ? 'camp' : 'class' }} start date. Instructions will be provided to registered {{ isCamp ? 'campers' : 'students' }} ahead of time.</div>
    </div>

    <div class="mb-1 section-important" *ngIf="isCamp">
      <h5>DISCOUNTS</h5>
      <div>
        <b>Build your own mix-and-match full-day camp - discount 15%</b> off both morning and afternoon camps. You can build your own full-day camp by combining morning and afternoon camps in the same week for the same student. The discount will be automatically applied during checkout of the second registration and will be calculated based on the value of both camps.<br/>
        <b>Sibling discount: 10%</b> The discount will be automatically applied during checkout of the qualifying registrations - second or additional siblings taking a camp in the same week.
      </div>
    </div>

    <div class="mb-1 section-important">
      <h5>CANCELLATION POLICY</h5>
      <div>For changes, cancellations, or refunds, refer to our <a class="cwk-orange fw-bold" [routerLink]="['/faqs']" fragment="cancellation" queryParamsHandling="preserve">CANCELLATION POLICY</a>.</div>
    </div>

  </ng-container>

  <!-- IN PERSON -->
  <ng-template #inPerson>

    <div class="mb-1 section-important" *ngIf="isCamp">
      <h5>DISCOUNTS</h5>
      <div>
        <b>Build your own mix-and-match full-day camp - discount 15%</b> off both morning and afternoon camps. You can build your own full-day camp by combining morning and afternoon camps in the same week for the same student. The discount will be automatically applied during checkout of the second registration and will be calculated based on the value of both camps. For in-person camps, we will take care of your child during their lunch break for no extra cost. Just remember to send them with their lunch!<br/>
        <b>Sibling discount: 10%</b> The discount will be automatically applied during checkout of the qualifying registrations - second or additional siblings taking a camp in the same week.
      </div>
    </div>

    <div class="mb-1 section-important" *ngIf="isCamp">
      <h5>CAMP LOGISTICS</h5>
      <!--home-camp-logistics id="camp-logistics"></home-camp-logistics-->
      <b>Arduino, Micro:bit, and Edison Robots:</b> Coding with Kids will provide robots for in-person camps where necessary. No need to purchase your own!<br/>
      <b>Breaks &amp; Food:</b> Half-day camps have one snack break. Please, send your child with water and snacks. Students staying for the full day will have a one-hour lunch break. Please send lunch, snacks, and water with your student. Weather permitting, we will also take the kids outside for breaks.<br/>
      <b>Computers for the camps:</b> We will have a computer ready for each student. For older students (12+), if they have a personal computer that they are comfortable using, they can bring it. If any software installation is required upfront, we will provide you with the full instructions before the camp.<br/>
      Should any camp not meet minimum enrollment requirements, we will have to cancel the camp, but we will make every attempt to move students to a similar camp or offer a full refund.
    </div>

    <!--div class="mb-1 section-important" *ngIf="isCamp">
      <h5>COVID-19 RELATED RULES</h5>
      Coding with Kids will follow local and state Covid-19 guidelines as well as any additional requirements dictated by the hosting facility or organization.<br/>
      We provide camps at locations that are spacious and enable comfortable social distancing opportunities for small groups.<br/>
      Should any location or health rules prevent Coding with Kids from conducting or finishing any specific in-person camp, we will provide the same level camps with the content and learning through the online format.
    </div-->

    <div class="mb-1 section-important">
      <h5>CANCELLATION POLICY</h5>
      <div>For changes, cancellations, or refunds, refer to our <a class="cwk-orange fw-bold" [routerLink]="['/faqs']" fragment="cancellation" queryParamsHandling="preserve">CANCELLATION POLICY</a>.</div>
    </div>

  </ng-template>
</div>
