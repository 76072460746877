import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DialogJoinWaitlistDataService {

  private urlWaitlist = environment.urlWebServices + '/cwk/waitlist';

  constructor(private http: HttpClient) { }

  joinWaitingList(sessionId: number, waitlistInfo: any): Observable<any> {

    // Call web service
    return this.http.post(`${this.urlWaitlist}/${sessionId}/create`, waitlistInfo)
      .pipe(catchError(error => observableThrowError(error.message || error)));
  }
}
