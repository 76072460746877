<form #signupForm="ngForm" (submit)="create()">

  <div *ngIf="!classInterest.ClassInterestId" class="fix-row" [@slideInOut]>
    <div class="col-12 col-md-6 col-lg-3 mb-3">

      <!-- First name -->
      <label for="firstName">First Name</label>
      <input type="text" class="form-control" name="firstName" [ngClass]="{'cwk-form-control-required': !classInterest.FirstName}" [(ngModel)]="classInterest.FirstName" placeholder="First name..." maxlength="128" required/>
    </div>

    <div class="col-12 col-md-6 col-lg-3 mb-3">

      <!-- Last name -->
      <label for="lastName">Last Name</label>
      <input type="text" class="form-control" name="lastName" [ngClass]="{'cwk-form-control-required': !classInterest.LastName}" [(ngModel)]="classInterest.LastName" placeholder="Last name..." maxlength="128" required/>
    </div>

    <div class="col-12 col-md-6 col-lg-3 mb-3">

      <!-- Email -->
      <label for="email">Email</label>
      <input type="email" class="form-control" name="email" [ngClass]="{'cwk-form-control-email': classInterest.Email && email.errors && (email.dirty || email.touched) && (email.errors['email']), 'cwk-form-control-required': !classInterest.Email}" [(ngModel)]="classInterest.Email" placeholder="Email..." #email="ngModel" email maxlength="128" required/>
    </div>

    <div class="col-12 col-md-6 col-lg-3 mb-3">

      <!-- School name -->
      <label for="schoolName">School Name</label>
      <input type="text" class="form-control" name="schoolName" [ngClass]="{'cwk-form-control-required': !classInterest.SchoolName}" [(ngModel)]="classInterest.SchoolName" placeholder="School name..." maxlength="128" required/>
    </div>
  </div>

  <div *ngIf="!classInterest.ClassInterestId" class="fix-row" [@slideInOut]>

    <div class="col-12 col-md-6 col-lg-4 mb-3">

      <!-- Country -->
      <label for="schoolName">Country</label>
      <input class="form-control" name="country" [inputFormatter]="formatCountry" [(ngModel)]="selectedCountry" [ngbTypeahead]="searchCountry" placeholder="Country..." [resultFormatter]="formatCountry" (selectItem)="setCountry($event)" type="text" autocomplete="custom-country-typeahead" />
    </div>

    <div class="col-12 col-md-6 col-lg-4 mb-3">

      <!-- City -->
      <label for="city">City</label>
      <input type="text" class="form-control" name="city" [(ngModel)]="classInterest.City" placeholder="City..." maxlength="128" />
    </div>

    <div class="col-12 col-md-6 col-lg-2 mb-3">

      <!-- State -->
      <label for="state">State</label>
      <select class="form-control form-select" name="state" [(ngModel)]="classInterest.State" [disabled]="classInterest.Country !== 'US'">
        <option [ngValue]="null" disabled>{{ classInterest.Country === 'US' ? 'Choose State' : 'N/A' }}</option>
        <option [disabled]="(state.Code === '-') && (classInterest.Country === 'US')" *ngFor="let state of states" [ngValue]="state.Code">{{ state.Name }}</option>
      </select>
    </div>

    <div class="col-12 col-md-6 col-lg-2 mb-3">

      <!-- ZIP / Postal code -->
      <!-- Notice that the cwk-form-control-error-zip is prefixed with !! to eliminate
        the ExpressionChangedAfterItHasBeenCheckedError exception. This could be removed once
        upgrade to a newer Angular is done and the exception disappears. -->
      <label for="ZIP">ZIP/Postal Code</label>
      <input class="form-control" name="ZIP" [ngClass]="{'cwk-form-control-error-zip': !!(classInterest.ZIP && ZIP.errors && (ZIP.dirty || ZIP.touched))}" [(ngModel)]="classInterest.ZIP" [placeholder]="classInterest.Country === 'US' ? 'ZIP' : 'Postal code'" [pattern]="classInterest.Country === 'US' ? '[0-9]{5,6}' : ''" type="text" #ZIP="ngModel" />
    </div>
  </div>

  <!-- Initial form submitted. Show success message and second form -->
  <div *ngIf="classInterest.ClassInterestId" class="my-3" [@slideInOut]>

    <!-- Success alert -->
    <div class="alert alert-success mb-3" role="alert">
      <h5 class="my-2"><fa-icon [icon]="faCheck" class="me-2" size="lg"></fa-icon>We've received your information, thank you! Please check your email for more information about our programs and to schedule a time to meet with us.</h5>
    </div>

    <div [hidden]="additonalInfoSubmitted">
      <h3>Additional Information (optional)</h3>
      <div class="fix-row mt-3">
        <div class="col-12 col-md-6 mb-3">

          <!-- Grades -->
          <label for="grades">What grades are you interested in?</label>
          <input type="text" class="form-control" name="grades" [(ngModel)]="classInterest.Grades" placeholder="Grades..."/>
        </div>

        <div class="col-12 col-md-6 mb-3">

          <!-- Interested In -->
          <label for="interestedIn">Interested Programs (check all that apply):</label>
          <div class="d-flex align-items-center">
            <div class="cwk-pointer me-3 px-1" *ngFor="let interestedSwitch of interestedInSwitches" (click)="interestedSwitch.isSelected = !interestedSwitch.isSelected">
              <fa-icon class="me-2" [icon]="interestedSwitch.isSelected ? faCheckSquare : faSquareO" size="lg"></fa-icon>
              <span>{{ interestedSwitch.display }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">

        <!-- Potential Start -->
        <label for="potentialStart">When would you like to start?</label>
        <input type="text" class="form-control" name="potentialStart" [(ngModel)]="classInterest.PotentialStart" placeholder="Timeframe..."/>
      </div>

      <div class="mb-3">

        <!-- Comments -->
        <label for="comment">Additional comments or questions</label>
        <textarea class="form-control" rows="3" name="comment" [(ngModel)]="classInterest.Comment" maxlength="2048" placeholder="Comments or questions..."></textarea>
      </div>
    </div>
  </div>

  <!-- Submit button -->
  <div [hidden]="additonalInfoSubmitted">
    <div class="mt-3 d-flex justify-content-center">
      <button type="submit" class="btn btn-secondary btn-large" (click)="create()" [disabled]="!signupForm.valid || isSaving">
        <span *ngIf="isSaving; else createButton">
          <fa-icon class="me-1" [icon]="faSpinner" animation="spin"></fa-icon>Submitting...
        </span>
        <ng-template #createButton>
          Submit<span [hidden]="!classInterest.ClassInterestId"> Additional Info</span>
        </ng-template>
      </button>
    </div>
  </div>
</form>

<!-- Additional info submitted alert -->
<div *ngIf="additonalInfoSubmitted" class="alert alert-success my-3" role="alert" [@slideInOut]>
  <h5 class="my-2"><fa-icon [icon]="faCheck" class="me-2" size="lg"></fa-icon>Thank you for submitting the additional information!</h5>
</div>

<!-- Error saving -->
<cwk-error-alert *ngIf="errorSaving" [error]="errorSaving"></cwk-error-alert>
