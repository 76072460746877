import { Injectable } from '@angular/core';

@Injectable()
export class PathwayAges {

  // Define the static pathway ages
  readonly PATHWAY_AGES = [
    { ageMin: 5, ageMax: 7, gradeMin: 'K', gradeMax: 2, uid: 1 },
    { ageMin: 8, ageMax: 10, gradeMin: 3, gradeMax: 5, uid: 2 },
    { ageMin: 10, ageMax: 12, gradeMin: 5, gradeMax: 7, uid: 3 },
    { ageMin: 12, ageMax: null, gradeMin: 7, gradeMax: null, uid: 4 }
  ];

  // Helper function to filter classes based on a pathwayAgeUid
  filterClasses4PathwayAge(classes: any[], pathwayAgeUid: number) {
    return this.filterSpecial(classes, pathwayAgeUid);
  }

  // Helper function to filters description based on a pathwayAgeUid
  filterDescription4PathwayAge(descriptions: any[], pathwayAgeUid: number, returnArray: boolean = false): string | any[] {
    descriptions = this.filterSpecial(descriptions, pathwayAgeUid);
    return returnArray ? descriptions : (descriptions.length ? descriptions[0].Description : null);
  }

  // Return the pathwayUid for the given age
  getPathwayUid4Age(age: number) {
    return this.PATHWAY_AGES.find(pathwayAge => age <= ((pathwayAge.ageMax || Number.MAX_SAFE_INTEGER) - 0.1)).uid;
  }

  // Get the pathway age object for the given ageUid
  pathwayAge4Uid(ageUid: number) {
    return ageUid ? this.PATHWAY_AGES.find(pathwayAge => pathwayAge.uid === ageUid) : null;
  }

  /*
   * Special filtering for pathways. Items with any overlap with the pathway age group will be included
   *
   * Note 1: Because some age groups are inclusive we can have overlap (e.g. 10 in 8-10 and 10-12) In this case reduce AgeMax by 0.1
   * Note 2: AgeMax can be NULL. In this case, there is no limit so default to Number.MAX_SAFE_INTEGER for safe comparison
   */
  private filterSpecial(items: any[], pathwayAgeUid: number) {

    let ageMin = Number.MIN_SAFE_INTEGER;
    let ageMax = Number.MAX_SAFE_INTEGER;

    // Limit to specific ages
    if (pathwayAgeUid) {
      ageMin = this.pathwayAge4Uid(pathwayAgeUid).ageMin;
      ageMax = this.pathwayAge4Uid(pathwayAgeUid).ageMax;
    }

    return items.filter(item => {
      const x1 = item.AgeMin;
      const x2 = (item.AgeMax || Number.MAX_SAFE_INTEGER) - 0.1;
      const y1 = ageMin;
      const y2 = (ageMax || Number.MAX_SAFE_INTEGER) - 0.1;
      return Math.max(x1, y1) <= Math.min(x2, y2);
    });
  }
}
