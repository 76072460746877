import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { GeolocationService } from '@shared/services/geolocation.service';
import { SubscriptionDataService } from './services/subscription-data.service';
import { Subscription as SubscriptionHelpers } from '../../classes/subscription';

// Animations
import { slideInOut } from '@shared/animations/slide-in-out';

// Icons
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  animations: [slideInOut],
  selector: 'home-subscription-purchase',
  templateUrl: './subscription-purchase.component.html',
  styleUrls: ['./subscription-purchase.component.scss'],
  providers: [SubscriptionHelpers],
  standalone: false
})
export class SubscriptionPurchaseComponent implements OnChanges, OnInit {

  @Input() code; // The code of the subscription we're interested in
  @Input() isPrivate = false; // Is this a private subscription?
  @Input() weekDaysCount; // WeekDaysCount for the subscription

  currency: string = null;
  errorReading = '';
  isReading = true;
  isRecommended: boolean = null;
  subscription: any = null;

  // Icons
  faSpinner = faSpinner;

  constructor(
    private geolocationService: GeolocationService,
    private subscriptionHelpers: SubscriptionHelpers,
    private subscriptionDataService: SubscriptionDataService
  ) { }


  ngOnChanges(changes: SimpleChanges) {
    this.read();
  }

  ngOnInit() {

    // Subscription to geolocation for currency changes
    this.geolocationService.getObservable()
      .subscribe((value) => {
        this.currency = value.currency;

        // Read the subscription data
        this.read();
    });
  }

  private processSubscription() {

    // (HARD-CODED) Is this subscription recommended?
    this.isRecommended = this.subscription.Code === 'ANNUAL-PREMIUM';

    // Init empty array to hold parameter display
    // this.subscription.SubscriptionParametersDisplay = [];

    // For each SubscriptionParameter, process a pretty display string
    this.subscription.SubscriptionParameters.forEach(subscriptionParameter => {
      subscriptionParameter.IsPrivate = this.subscription.IsPrivate;

      // Generate the display text
      subscriptionParameter.DisplayText = this.subscriptionHelpers.getSubscriptionParameter4Display(subscriptionParameter);
    });
  }

  private read() {

    if (!this.currency) {

      // In case geolocationService fails to fetch currency, exit
      return;
    }

    // Show spinner
    this.isReading = true;

    // Reset error status
    this.errorReading = '';

    // Read subscription
    this.subscriptionDataService.read(this.code, this.currency, this.weekDaysCount, this.isPrivate)
      .subscribe(
        (value) => {

            // Set the subscription
            this.subscription = value;

            // Process the subscription
            this.processSubscription();

            // Hide spinner
            this.isReading = false;
          },
        (error) => {
          this.errorReading = error;
          this.isReading = false;
        }
      );
  }
}
