import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Icons
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-dialog-video',
  templateUrl: './dialog-video.component.html',
  styleUrls: ['./dialog-video.component.scss'],
  standalone: false
})
export class DialogVideoComponent implements OnInit {

  @Input() videoLink: string;

  // Icons
  faTimes = faTimes;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  close() {
    this.activeModal.dismiss();
  }
}
