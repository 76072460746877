import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

/* tslint:disable */
import { AnniversaryOfferComponent } from './components/anniversary-offer/anniversary-offer.component';
import { CampLogisticsComponent } from './components/camp-logistics/camp-logistics.component';
import { ClassInterestFormComponent } from './components/class-interest-form/class-interest-form.component';
import { CodersLadderLevelSelectorComponent } from './components/coders-ladder-level-selector/coders-ladder-level-selector.component';
import { CodersLadderSectionComponent } from './components/coders-ladder-section/coders-ladder-section.component';
import { CodersPathwaySectionComponent } from './components/coders-pathway-section/coders-pathway-section.component';
import { CookieConsentManagerComponent } from '@shared/components/cookie-consent-manager/cookie-consent-manager.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { CreateAccountFormComponent } from '@shared/components/create-account-form/create-account-form.component';
import { CreateCrAccountFormComponent } from '@shared/components/create-cr-account-form/create-cr-account-form.component';
import { CreateStudentProfileFormComponent } from '@shared/components/create-student-profile-form/create-student-profile-form.component';
import { CustomerFeedbackRatingComponent } from './components/customer-feedback-rating/customer-feedback-rating.component';
import { CustomerTestimonialItemComponent } from './components/customer-testimonial-item/customer-testimonial-item.component';
import { DialogClassDetailsComponent } from './components/dialog-class-details/dialog-class-details.component';
import { DialogConfirmComponent } from '@shared/components/dialog-confirm/dialog-confirm.component';
import { DialogCookieConsentComponent } from '@shared/components/cookie-consent-manager/components/dialog-cookie-consent/dialog-cookie-consent.component';
import { DialogExperiencedConfirmComponent } from './components/online-coders-ladder-registration-list/components/dialog-experienced-confirm/dialog-experienced-confirm.component';
import { DialogFutureSessionsComponent } from './components/dialog-future-sessions/dialog-future-sessions.component';
import { DialogJoinMailingListComponent } from '@shared/components/dialog-join-mailing-list/dialog-join-mailing-list.component';
import { DialogJoinWaitlistComponent } from '@shared/components/dialog-join-waitlist/dialog-join-waitlist.component';
import { DialogPathwayDetailsComponent } from './components/dialog-pathway-details/dialog-pathway-details.component';
import { DialogTextAreaComponent } from '@shared/components/dialog-text-area/dialog-text-area.component';
import { DialogVideoComponent } from './components/dialog-video/dialog-video.component';
import { EmbedVideoComponent } from './components/embed-video/embed-video.component';
import { ErrorAlertComponent } from '@shared/components/error-alert/error-alert.component';
import { InfoGraphicsComponent } from './components/info-graphics/info-graphics.component';
import { JoinMailingListComponent } from '@shared/components/join-mailing-list/join-mailing-list.component';
import { LocationFilterComponent } from './components/location-filter/location-filter.component';
import { LocationsListComponent } from './components/locations-list/locations-list.component';
import { MapComponent } from './components/map/map.component';
import { NoPrerenderDirective } from '@shared/directives/no-prerender.directive';
import { OnlineCampsRegistrationListComponent } from './components/online-camps-registration-list/online-camps-registration-list.component';
import { OnlineCodersLadderRegistrationListComponent } from './components/online-coders-ladder-registration-list/online-coders-ladder-registration-list.component';
import { OnlineEnrichmentRegistrationsListComponent } from './components/online-enrichment-registrations-list/online-enrichment-registrations-list.component';
import { PrerenderOnlyDirective } from '@shared/directives/prerender-only.directive';
import { ProgramBannerComponent } from './components/program-banner/program-banner.component';
import { ProgramLinksComponent } from './components/program-links/program-links.component';
import { ProgramImportantComponent } from './components/program-important/program-important.component';
import { PromoDiscountSignupComponent } from './components/promo-discount-signup/promo-discount-signup.component';
import { ProgramTechReqComponent } from './components/program-tech-req/program-tech-req.component';
import { RegistrationListHeadingComponent } from './components/registration-list-heading/registration-list-heading.component';
import { SchoolLogosComponent } from './components/school-logos/school-logos.component';
import { SubscriptionPurchaseComponent } from './components/subscription-purchase/subscription-purchase.component';
/* tslint:enable */

import { GeocodeService } from './services/geocode.service';

import { Constants } from './classes/constants';
import { CurrencyPipe } from '@angular/common';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    GoogleMapsModule,
    RouterModule,
    NgbModule
  ],
  declarations: [
    AnniversaryOfferComponent,
    CampLogisticsComponent,
    ClassInterestFormComponent,
    CodersLadderLevelSelectorComponent,
    CodersLadderSectionComponent,
    CodersPathwaySectionComponent,
    CookieConsentManagerComponent,
    CountdownComponent,
    CreateAccountFormComponent,
    CreateCrAccountFormComponent,
    CreateStudentProfileFormComponent,
    CustomerFeedbackRatingComponent,
    CustomerTestimonialItemComponent,
    DialogClassDetailsComponent,
    DialogConfirmComponent,
    DialogCookieConsentComponent,
    DialogExperiencedConfirmComponent,
    DialogFutureSessionsComponent,
    DialogJoinMailingListComponent,
    DialogJoinWaitlistComponent,
    DialogPathwayDetailsComponent,
    DialogTextAreaComponent,
    DialogVideoComponent,
    EmbedVideoComponent,
    ErrorAlertComponent,
    InfoGraphicsComponent,
    JoinMailingListComponent,
    LocationFilterComponent,
    LocationsListComponent,
    MapComponent,
    NoPrerenderDirective,
    OnlineCampsRegistrationListComponent,
    OnlineCodersLadderRegistrationListComponent,
    OnlineEnrichmentRegistrationsListComponent,
    PrerenderOnlyDirective,
    ProgramBannerComponent,
    ProgramLinksComponent,
    ProgramImportantComponent,
    ProgramTechReqComponent,
    PromoDiscountSignupComponent,
    RegistrationListHeadingComponent,
    SafeHtmlPipe,
    SchoolLogosComponent,
    SubscriptionPurchaseComponent
  ],
  exports: [
    AnniversaryOfferComponent,
    CampLogisticsComponent,
    ClassInterestFormComponent,
    CodersLadderLevelSelectorComponent,
    CodersLadderSectionComponent,
    CodersPathwaySectionComponent,
    CookieConsentManagerComponent,
    CountdownComponent,
    CreateAccountFormComponent,
    CreateCrAccountFormComponent,
    CreateStudentProfileFormComponent,
    CustomerFeedbackRatingComponent,
    CustomerTestimonialItemComponent,
    DialogConfirmComponent,
    DialogCookieConsentComponent,
    DialogFutureSessionsComponent,
    DialogJoinMailingListComponent,
    DialogJoinWaitlistComponent,
    DialogTextAreaComponent,
    DialogVideoComponent,
    EmbedVideoComponent,
    ErrorAlertComponent,
    InfoGraphicsComponent,
    JoinMailingListComponent,
    LocationFilterComponent,
    LocationsListComponent,
    MapComponent,
    NoPrerenderDirective,
    OnlineCampsRegistrationListComponent,
    OnlineCodersLadderRegistrationListComponent,
    OnlineEnrichmentRegistrationsListComponent,
    PrerenderOnlyDirective,
    ProgramBannerComponent,
    ProgramImportantComponent,
    ProgramLinksComponent,
    ProgramTechReqComponent,
    PromoDiscountSignupComponent,
    RegistrationListHeadingComponent,
    SafeHtmlPipe,
    SchoolLogosComponent,
    SubscriptionPurchaseComponent
  ],
  providers: [
    Constants,
    CurrencyPipe,
    GeocodeService
  ]
})
export class SharedModule { }
