import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Icons
import { faFireAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-dialog-experienced-confirm',
  templateUrl: './dialog-experienced-confirm.component.html',
  styleUrls: ['./dialog-experienced-confirm.component.scss'],
  standalone: false
})
export class DialogExperiencedConfirmComponent {

  @Input() message: string;

  // Icons
  faFireAlt = faFireAlt;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  cancel() {
    this.activeModal.dismiss();
  }

  process() {
    this.activeModal.close();
  }
}

