import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PathwayAges } from '../../../shared/classes/pathway-ages';

// Icons
import { faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-dialog-pathway-details',
  templateUrl: './dialog-pathway-details.component.html',
  styleUrls: ['./dialog-pathway-details.component.scss'],
  providers: [PathwayAges],
  standalone: false
})
export class DialogPathwayDetailsComponent implements OnInit {

  @Input() pathway: any;
  @Input() selectedAgeUid: number;
  @Input() showGrades = false;

  faInfoCircle = faInfoCircle;
  faTimesCircle = faTimesCircle;

  constructor(
    private activeModal: NgbActiveModal,
    private pathwayAgesClass: PathwayAges
  ) { }

  ngOnInit() {

    if (this.selectedAgeUid) {

      // An age group was selected. Filter the descriptions
      this.pathway.Descriptions = this.pathwayAgesClass.filterDescription4PathwayAge(this.pathway.Descriptions, this.selectedAgeUid, true);
    }
  }

  close() {
    this.activeModal.close();
  }
}
