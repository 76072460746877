import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cwk-dialog-text-area',
  templateUrl: './dialog-text-area.component.html',
  styleUrls: ['./dialog-text-area.component.scss'],
  standalone: false
})
export class DialogTextAreaComponent implements OnInit {

  // Icons
  faSpinner = faSpinner;

  @Input() buttonCancel = 'Cancel';
  @Input() buttonProcessing = 'Processing';
  @Input() buttonSubmit = 'Submit';
  @Input() header: string;
  @Input() isRequired = false;
  @Input() label: string;
  @Input() maxLength: number | null = null;
  @Input() submitHandler: any;
  @Input() text: string;

  errorProcessing: string;
  isProcessing = false;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  process() {

    // Clear error
    this.errorProcessing = '';

    // Show spinner
    this.isProcessing = true;

    // Execute the handler
    this.submitHandler(this.text)
      .subscribe(
        () => this.activeModal.close(this.text),
        error => {
          this.errorProcessing = (error.text || error);
          this.isProcessing = false;
        },
        () => this.isProcessing = false
      );
  }
}
