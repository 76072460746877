export class Countries {

  static countries = {
    'US': { E164: '1', Name: 'United States of America' },
    'GB': { E164: '44', Name: 'United Kingdom' },
    'CA': { E164: '1', Name: 'Canada' },
    'AF': { E164: '93', Name: 'Afghanistan' },
    'AL': { E164: '355', Name: 'Albania' },
    'DZ': { E164: '213', Name: 'Algeria' },
    'AS': { E164: '1-684', Name: 'American Samoa' },
    'AD': { E164: '376', Name: 'Andorre' },
    'AO': { E164: '244', Name: 'Angola' },
    'AI': { E164: '1-264', Name: 'Anguilla' },
    'AQ': { E164: '672', Name: 'Antarctica' },
    'AG': { E164: '1-268', Name: 'Antigua and Barbuda' },
    'AR': { E164: '54', Name: 'Argentina' },
    'AM': { E164: '374', Name: 'Armenia' },
    'AW': { E164: '297', Name: 'Aruba' },
    'AU': { E164: '61', Name: 'Australia' },
    'AT': { E164: '43', Name: 'Austria' },
    'AZ': { E164: '994', Name: 'Azerbaijan' },
    'BS': { E164: '1-242', Name: 'Bahamas' },
    'BH': { E164: '973', Name: 'Bahrain' },
    'BD': { E164: '880', Name: 'Bangladesh' },
    'BB': { E164: '1-246', Name: 'Barbade' },
    'BY': { E164: '375', Name: 'Belarus' },
    'BE': { E164: '32', Name: 'Belgium' },
    'BZ': { E164: '501', Name: 'Belize' },
    'BJ': { E164: '229', Name: 'Benin' },
    'BM': { E164: '1-441', Name: 'Bermuda' },
    'BT': { E164: '975', Name: 'Bhutan' },
    'BO': { E164: '591', Name: 'Bolivia' },
    'BQ': { E164: '599', Name: 'Bonaire, Sint Eustatius and Saba' },
    'BA': { E164: '387', Name: 'Bosnia and Herzegovina' },
    'BW': { E164: '267', Name: 'Botswana' },
    'BV': { E164: '47', Name: 'Bouvet Island' },
    'BR': { E164: '55', Name: 'Brazil' },
    'IO': { E164: '246', Name: 'British Indian Ocean Territory' },
    'VG': { E164: '1-284', Name: 'British Virgin Islands' },
    'BN': { E164: '673', Name: 'Brunei' },
    'BG': { E164: '359', Name: 'Bulgaria' },
    'BF': { E164: '226', Name: 'Burkina Faso' },
    'BI': { E164: '257', Name: 'Burundi' },
    'KH': { E164: '855', Name: 'Cambodia' },
    'CM': { E164: '237', Name: 'Cameroon' },
    'CV': { E164: '238', Name: 'Cape Verde' },
    'KY': { E164: '1-345', Name: 'Cayman Islands' },
    'CF': { E164: '236', Name: 'Central African Republic' },
    'TD': { E164: '235', Name: 'Chad' },
    'CL': { E164: '56', Name: 'Chile' },
    'CN': { E164: '86', Name: 'China' },
    'CX': { E164: '61', Name: 'Christmas Island' },
    'CC': { E164: '61', Name: 'Cocos (Keeling) Islands' },
    'CO': { E164: '57', Name: 'Colombia' },
    'KM': { E164: '269', Name: 'Comoros' },
    'CG': { E164: '242', Name: 'Congo' },
    'CD': { E164: '243', Name: 'Congo (Dem. Rep.)' },
    'CK': { E164: '682', Name: 'Cook Islands' },
    'CR': { E164: '506', Name: 'Costa Rica' },
    'ME': { E164: '382', Name: 'Crna Gora' },
    'HR': { E164: '385', Name: 'Croatia' },
    'CU': { E164: '53', Name: 'Cuba' },
    'CW': { E164: '599', Name: 'Curaçao' },
    'CY': { E164: '357', Name: 'Cyprus' },
    'CZ': { E164: '420', Name: 'Czech Republic' },
    'CI': { E164: '225', Name: 'Côte D\'Ivoire' },
    'DK': { E164: '45', Name: 'Denmark' },
    'DJ': { E164: '253', Name: 'Djibouti' },
    'DM': { E164: '1-767', Name: 'Dominica' },
    'DO': { E164: '1-809', Name: 'Dominican Republic' },
    'TL': { E164: '670', Name: 'East Timor' },
    'EC': { E164: '593', Name: 'Ecuador' },
    'EG': { E164: '20', Name: 'Egypt' },
    'SV': { E164: '503', Name: 'El Salvador' },
    'GQ': { E164: '240', Name: 'Equatorial Guinea' },
    'ER': { E164: '291', Name: 'Eritrea' },
    'EE': { E164: '372', Name: 'Estonia' },
    'ET': { E164: '251', Name: 'Ethiopia' },
    'FK': { E164: '500', Name: 'Falkland Islands' },
    'FO': { E164: '298', Name: 'Faroe Islands' },
    'FJ': { E164: '679', Name: 'Fiji' },
    'FI': { E164: '358', Name: 'Finland' },
    'FR': { E164: '33', Name: 'France' },
    'GF': { E164: '594', Name: 'French Guiana' },
    'PF': { E164: '689', Name: 'French Polynesia' },
    'TF': { E164: '262', Name: 'French Southern Territories' },
    'GA': { E164: '241', Name: 'Gabon' },
    'GM': { E164: '220', Name: 'Gambia' },
    'GE': { E164: '995', Name: 'Georgia' },
    'DE': { E164: '49', Name: 'Germany' },
    'GH': { E164: '233', Name: 'Ghana' },
    'GI': { E164: '350', Name: 'Gibraltar' },
    'GR': { E164: '30', Name: 'Greece' },
    'GL': { E164: '299', Name: 'Greenland' },
    'GD': { E164: '1-473', Name: 'Grenada' },
    'GP': { E164: '590', Name: 'Guadeloupe' },
    'GU': { E164: '1-671', Name: 'Guam' },
    'GT': { E164: '502', Name: 'Guatemala' },
    'GG': { E164: '44-1481', Name: 'Guernsey and Alderney' },
    'GN': { E164: '224', Name: 'Guinea' },
    'GW': { E164: '245', Name: 'Guinea-Bissau' },
    'GY': { E164: '592', Name: 'Guyana' },
    'HT': { E164: '509', Name: 'Haiti' },
    'HM': { E164: '672', Name: 'Heard and McDonald Islands' },
    'HN': { E164: '504', Name: 'Honduras' },
    'HK': { E164: '852', Name: 'Hong Kong' },
    'HU': { E164: '36', Name: 'Hungary' },
    'IS': { E164: '354', Name: 'Iceland' },
    'IN': { E164: '91', Name: 'India' },
    'ID': { E164: '62', Name: 'Indonesia' },
    'IR': { E164: '98', Name: 'Iran' },
    'IQ': { E164: '964', Name: 'Iraq' },
    'IE': { E164: '353', Name: 'Ireland' },
    'IM': { E164: '44-1624', Name: 'Isle of Man' },
    'IL': { E164: '972', Name: 'Israel' },
    'IT': { E164: '39', Name: 'Italy' },
    'JM': { E164: '1-876', Name: 'Jamaica' },
    'JP': { E164: '81', Name: 'Japan' },
    'JE': { E164: '44-1534', Name: 'Jersey' },
    'JO': { E164: '962', Name: 'Jordan' },
    'KZ': { E164: '7', Name: 'Kazakhstan' },
    'KE': { E164: '254', Name: 'Kenya' },
    'KI': { E164: '686', Name: 'Kiribati' },
    'KP': { E164: '850', Name: 'Korea (North)' },
    'KR': { E164: '82', Name: 'Korea (South)' },
    'KW': { E164: '965', Name: 'Kuwait' },
    'KG': { E164: '996', Name: 'Kyrgyzstan' },
    'LA': { E164: '856', Name: 'Laos' },
    'LV': { E164: '371', Name: 'Latvia' },
    'LB': { E164: '961', Name: 'Lebanon' },
    'LS': { E164: '266', Name: 'Lesotho' },
    'LR': { E164: '231', Name: 'Liberia' },
    'LY': { E164: '218', Name: 'Libya' },
    'LI': { E164: '423', Name: 'Liechtenstein' },
    'LT': { E164: '370', Name: 'Lithuania' },
    'LU': { E164: '352', Name: 'Luxembourg' },
    'MO': { E164: '853', Name: 'Macao' },
    'MK': { E164: '389', Name: 'Macedonia' },
    'MG': { E164: '261', Name: 'Madagascar' },
    'MW': { E164: '265', Name: 'Malawi' },
    'MY': { E164: '60', Name: 'Malaysia' },
    'MV': { E164: '960', Name: 'Maldives' },
    'ML': { E164: '223', Name: 'Mali' },
    'MT': { E164: '356', Name: 'Malta' },
    'MH': { E164: '692', Name: 'Marshall Islands' },
    'MQ': { E164: '596', Name: 'Martinique' },
    'MR': { E164: '222', Name: 'Mauritania' },
    'MU': { E164: '230', Name: 'Mauritius' },
    'YT': { E164: '262', Name: 'Mayotte' },
    'MX': { E164: '52', Name: 'Mexico' },
    'FM': { E164: '691', Name: 'Micronesia' },
    'MD': { E164: '373', Name: 'Moldova' },
    'MC': { E164: '377', Name: 'Monaco' },
    'MN': { E164: '976', Name: 'Mongolia' },
    'MS': { E164: '1-664', Name: 'Montserrat' },
    'MA': { E164: '212', Name: 'Morocco' },
    'MZ': { E164: '258', Name: 'Mozambique' },
    'MM': { E164: '95', Name: 'Myanmar' },
    'NA': { E164: '264', Name: 'Namibia' },
    'NR': { E164: '674', Name: 'Nauru' },
    'NP': { E164: '977', Name: 'Nepal' },
    'NL': { E164: '31', Name: 'Netherlands' },
    'AN': { E164: 'undefined', Name: 'Netherlands Antilles' },
    'NC': { E164: '687', Name: 'New Caledonia' },
    'NZ': { E164: '64', Name: 'New Zealand' },
    'NI': { E164: '505', Name: 'Nicaragua' },
    'NE': { E164: '227', Name: 'Niger' },
    'NG': { E164: '234', Name: 'Nigeria' },
    'NU': { E164: '683', Name: 'Niue' },
    'NF': { E164: '672', Name: 'Norfolk Island' },
    'MP': { E164: '1-670', Name: 'Northern Mariana Islands' },
    'NO': { E164: '47', Name: 'Norway' },
    'OM': { E164: '968', Name: 'Oman' },
    'PK': { E164: '92', Name: 'Pakistan' },
    'PW': { E164: '680', Name: 'Palau' },
    'PS': { E164: '970', Name: 'Palestine' },
    'PA': { E164: '507', Name: 'Panama' },
    'PG': { E164: '675', Name: 'Papua New Guinea' },
    'PY': { E164: '595', Name: 'Paraguay' },
    'PE': { E164: '51', Name: 'Peru' },
    'PH': { E164: '63', Name: 'Philippines' },
    'PN': { E164: '870', Name: 'Pitcairn' },
    'PL': { E164: '48', Name: 'Poland' },
    'PT': { E164: '351', Name: 'Portugal' },
    'PR': { E164: '1-787', Name: 'Puerto Rico' },
    'QA': { E164: '974', Name: 'Qatar' },
    'RO': { E164: '40', Name: 'Romania' },
    'RU': { E164: '7', Name: 'Russia' },
    'RW': { E164: '250', Name: 'Rwanda' },
    'RE': { E164: '262', Name: 'Réunion' },
    'BL': { E164: '590', Name: 'Saint Barthélemy' },
    'SH': { E164: '290', Name: 'Saint Helena' },
    'KN': { E164: '1-869', Name: 'Saint Kitts and Nevis' },
    'LC': { E164: '1-758', Name: 'Saint Lucia' },
    'MF': { E164: '590', Name: 'Saint Martin' },
    'PM': { E164: '508', Name: 'Saint Pierre and Miquelon' },
    'VC': { E164: '1-784', Name: 'Saint Vincent and the Grenadines' },
    'WS': { E164: '685', Name: 'Samoa' },
    'SM': { E164: '378', Name: 'San Marino' },
    'SA': { E164: '966', Name: 'Saudi Arabia' },
    'SN': { E164: '221', Name: 'Senegal' },
    'RS': { E164: '381', Name: 'Serbia' },
    'SC': { E164: '248', Name: 'Seychelles' },
    'SL': { E164: '232', Name: 'Sierra Leone' },
    'SG': { E164: '65', Name: 'Singapore' },
    'SX': { E164: '599', Name: 'Sint Maarten' },
    'SK': { E164: '421', Name: 'Slovakia' },
    'SI': { E164: '386', Name: 'Slovenia' },
    'SB': { E164: '677', Name: 'Solomon Islands' },
    'SO': { E164: '252', Name: 'Somalia' },
    'ZA': { E164: '27', Name: 'South Africa' },
    'GS': { E164: '500', Name: 'South Georgia and the South Sandwich Islands' },
    'SS': { E164: '211', Name: 'South Sudan' },
    'ES': { E164: '34', Name: 'Spain' },
    'LK': { E164: '94', Name: 'Sri Lanka' },
    'SD': { E164: '249', Name: 'Sudan' },
    'SR': { E164: '597', Name: 'SuriName' },
    'SJ': { E164: '47', Name: 'Svalbard and Jan Mayen' },
    'SZ': { E164: '268', Name: 'Swaziland' },
    'SE': { E164: '46', Name: 'Sweden' },
    'CH': { E164: '41', Name: 'Switzerland' },
    'SY': { E164: '963', Name: 'Syria' },
    'ST': { E164: '239', Name: 'São Tomé and Príncipe' },
    'TW': { E164: '886', Name: 'Taiwan' },
    'TJ': { E164: '992', Name: 'Tajikistan' },
    'TZ': { E164: '255', Name: 'Tanzania' },
    'TH': { E164: '66', Name: 'Thailand' },
    'TG': { E164: '228', Name: 'Togo' },
    'TK': { E164: '690', Name: 'Tokelau' },
    'TO': { E164: '676', Name: 'Tonga' },
    'TT': { E164: '1-868', Name: 'Trinidad and Tobago' },
    'TN': { E164: '216', Name: 'Tunisia' },
    'TR': { E164: '90', Name: 'Turkey' },
    'TM': { E164: '993', Name: 'Turkmenistan' },
    'TC': { E164: '1-649', Name: 'Turks and Caicos Islands' },
    'TV': { E164: '688', Name: 'Tuvalu' },
    'UG': { E164: '256', Name: 'Uganda' },
    'UA': { E164: '380', Name: 'Ukraine' },
    'AE': { E164: '971', Name: 'United Arab Emirates' },
    'UM': { E164: '1', Name: 'United States Minor Outlying Islands' },
    'UY': { E164: '598', Name: 'Uruguay' },
    'UZ': { E164: '998', Name: 'Uzbekistan' },
    'VU': { E164: '678', Name: 'Vanuatu' },
    'VA': { E164: '379', Name: 'Vatican City' },
    'VE': { E164: '58', Name: 'Venezuela' },
    'VN': { E164: '84', Name: 'Vietnam' },
    'VI': { E164: '1-340', Name: 'Virgin Islands of the United States' },
    'WF': { E164: '681', Name: 'Wallis and Futuna' },
    'EH': { E164: '212', Name: 'Western Sahara' },
    'YE': { E164: '967', Name: 'Yemen' },
    'ZM': { E164: '260', Name: 'Zambia' },
    'ZW': { E164: '263', Name: 'Zimbabwe' },
    'AX': { E164: '358-18', Name: 'Åland Islands' }
  };

  static getCountryName(code: string): string | undefined {
    return this.countries[code]?.Name;
  }
}
